import React from 'react';
import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import { StyledButton } from '@bizapp-frontend/management/molecules/StyledButton';
import {
  CustomerInfoTable,
  Data,
} from '@bizapp-frontend/management/organisms/CustomerInfoTable';
import { BillingInfo } from '@bizapp-frontend/management/pages/CustomerDetailApplicationPage';
import { MAX_DATE_NS } from '@bizapp-frontend/management/templates/form/utils';

const NANO_SECONDS_FACTOR = 1000000;

const timestamp2Date = (timestamp: number) => {
  if (!timestamp) {
    return '';
  }
  return new Date(timestamp / NANO_SECONDS_FACTOR)
    .toLocaleString('ja-JP', {
      timeZone: 'Asia/Tokyo',
    })
    .split(' ')[0];
};

const TableHeaders = [
  {
    title: 'サービス名',
  },
  {
    title: 'プラン名',
  },
  {
    title: '請求種別',
  },
  {
    title: '請求単価（税抜）',
  },
  {
    title: '数量',
  },
  {
    title: '請求対象期間',
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingTop: theme.spacing(5),
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      maxWidth: theme.spacing(142),
      paddingLeft: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    title: {
      display: 'block',
      fontSize: 14,
      fontWeight: 'bold',
    },
    tipsIcon: {
      marginLeft: theme.spacing(0.5),
    },
    tips: {
      maxWidth: theme.spacing(200),
    },
    tipsTitle: {
      display: 'block',
      backgroundColor: '#F0F1F2',
      padding: theme.spacing(0.5, 2),
      color: '#333333',
      fontSize: 14,
    },
    tipsContent: {
      display: 'block',
      backgroundColor: '#FFFFFF',
      padding: theme.spacing(1.5, 2),
      color: '#333333',
      fontSize: 14,
      '& span': {
        display: 'block',
      },
    },
    applicationInfoTable: {
      maxWidth: theme.spacing(86),
      marginBottom: theme.spacing(5),
      '& div': {
        display: 'flex',
      },
      '& span': {
        display: 'block',
      },
    },
    link: {
      color: '#1565C0',
      fontSize: 14,
    },
    newBtn: {
      width: theme.spacing(11.5),
      padding: theme.spacing(0.75, 2, 0.625, 2),
      fontSize: '14px',
      fontWeight: 'bold',
    },
    tableHeaderWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    tableHeader: {
      display: 'block',
      color: '#A4AAB0',
      fontWeight: 'bold',
      fontSize: 14,
    },
    table: {
      maxWidth: theme.spacing(142),

      '& th': {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.25),
        paddingBottom: theme.spacing(1.25),
        color: '#A4AAB0',
        fontWeight: 'bold',
        fontSize: 14,
        borderTop: '1px solid #A4AAB0',
        borderBottom: '1px solid #A4AAB0',
      },
      '& th:first-child': {
        paddingLeft: theme.spacing(4),
      },
      '& td': {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.25),
        paddingBottom: theme.spacing(1.25),
        color: '#333333',
        fontSize: 14,
        cursor: 'pointer',
      },
      '& td:first-child': {
        paddingLeft: theme.spacing(4),
      },
      '& tr:last-child td': {
        borderBottom: '1px solid #A4AAB0',
      },
    },
  }),
);

const StyledPaper = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
}))(Paper);

export type Application = {
  contractId: string;
  serviceId: string;
  serviceName: string;
  planId: string;
  planKind: string;
  billKind: string;
  unitPrice: number;
  usage: string;
  startDate: number;
  endDate?: number;
  readOnlyContract: boolean;
  baseContractId?: string;
  updatedContractId?: string;
  originalContractStartDate?: number;
  isOriginalContract: boolean;
  hasOptionPlan: boolean;
};

export interface CustomerDetailApplicationTemplateProps {
  applications: Application[];
  onApplicationClick?: Function;
  onCreateClick?: Function;
  billingInfo: BillingInfo;
  createContractPermission: boolean;
}

export const CustomerDetailApplicationTemplate: React.FC<CustomerDetailApplicationTemplateProps> = ({
  applications,
  onApplicationClick,
  onCreateClick,
  billingInfo,
  createContractPermission,
}) => {
  const classes = useStyles();
  const [applicationInfo, setApplicationInfo] = React.useState<Data[]>([]);

  React.useEffect(() => {
    const data = [
      {
        label: '請求先',
        value: (
          <div>
            {billingInfo.paymentGatewayId === 'mfkessai' ? (
              billingInfo.mfkCustomerUrl ? (
                <>
                  <span>MF Kessai 請求書（</span>
                  <Link
                    className={classes.link}
                    underline="always"
                    href={billingInfo.mfkCustomerUrl}
                    target="_blank"
                  >
                    顧客情報
                  </Link>
                  <span>)</span>
                </>
              ) : (
                '---'
              )
            ) : (
              <span>
                {billingInfo.paymentGatewayId === 'stripe'
                  ? billingInfo.stripeCard
                  : '未設定'}
              </span>
            )}
          </div>
        ),
      },
      {
        label: '最新の取引',
        value:
          billingInfo.paymentGatewayId === 'mfkessai' ? (
            billingInfo.transaction ? (
              <Link
                className={classes.link}
                underline="always"
                href={billingInfo.transaction}
                target="_blank"
              >
                取引詳細
              </Link>
            ) : (
              '取引情報が登録されていません'
            )
          ) : (
            '---'
          ),
      },
      {
        label: '最新の請求',
        value:
          billingInfo.paymentGatewayId === 'mfkessai' ? (
            billingInfo.billing ? (
              <Link
                className={classes.link}
                underline="always"
                href={billingInfo.billing}
                target="_blank"
              >
                請求詳細
              </Link>
            ) : (
              '請求情報が登録されていません'
            )
          ) : (
            '---'
          ),
      },
      {
        label: '請求履歴',
        value:
          billingInfo.paymentGatewayId === 'mfkessai' &&
          billingInfo.applications ? (
            <Link
              className={classes.link}
              underline="always"
              href={billingInfo.applications}
              target="_blank"
            >
              請求一覧
            </Link>
          ) : (
            '---'
          ),
      },
    ];
    setApplicationInfo(data);
    return () => setApplicationInfo([]);
  }, [billingInfo, classes.link]);

  const handleClick = (application: Application) => {
    if (!onApplicationClick) {
      return;
    }
    onApplicationClick(application);
  };

  const handleCreateClick = () => {
    if (!onCreateClick) {
      return;
    }
    onCreateClick();
  };

  const formatPrice = (price: number) => Number(price).toLocaleString();

  return (
    <div className={classes.root}>
      <CustomerInfoTable
        className={classes.applicationInfoTable}
        title="請求情報"
        data={applicationInfo}
      />
      <div className={classes.header}>
        <Typography component="span" className={classes.title}>
          請求内容
        </Typography>
        {createContractPermission &&
          billingInfo.paymentGatewayId === 'mfkessai' && (
            <StyledButton
              className={classes.newBtn}
              variant="secondary"
              label="新規登録"
              onClick={handleCreateClick}
            />
          )}
      </div>
      <TableContainer component={StyledPaper} className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              {TableHeaders.map((header, i) => (
                <TableCell key={i}>
                  <div className={classes.tableHeaderWrapper}>
                    <Typography
                      component="span"
                      className={classes.tableHeader}
                    >
                      {header.title}
                    </Typography>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {applications.length !== 0 ? (
              applications.map((application, i) => (
                <TableRow
                  key={i}
                  hover
                  onClick={() => handleClick(application)}
                >
                  <TableCell width="11%">{application.serviceName}</TableCell>
                  <TableCell width="13%">{application.planKind}</TableCell>
                  <TableCell width="8%">{application.billKind}</TableCell>
                  <TableCell width="11%">
                    {formatPrice(application.unitPrice) + '円'}
                  </TableCell>
                  <TableCell width="9%">{application.usage}</TableCell>
                  <TableCell width="10%">
                    {application.startDate &&
                      timestamp2Date(Number(application.startDate)) + ' - '}
                    {application.endDate &&
                      application.endDate !== MAX_DATE_NS &&
                      timestamp2Date(Number(application.endDate))}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>該当するデータはありません</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
