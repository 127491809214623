import React from 'react';
import { List, ListItem, TextField, Typography } from '@material-ui/core';
import { Container, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import * as _ from 'lodash';

import { StyledButton } from '@bizapp-frontend/management/molecules/StyledButton';
import Meta from '@bizapp-frontend/management/organisms/Meta';
import { PageListTemplate } from '@bizapp-frontend/management/templates/PageListTemplate';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiListItem-root': {
        display: 'block',
        textAlign: 'center',
      },
    },
    container: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(2, 0),
    },
  }),
);

export interface RemovalToolProps {
  applicationControllerBaseUrl: string;
}

const RemovalTool: React.FC<RemovalToolProps> = ({
  applicationControllerBaseUrl,
}) => {
  const classes = useStyle();
  const { getAccessTokenSilently } = useAuth0();
  const [disableRemoveButton, setDisableRemoveButton] = React.useState<boolean>(
    false,
  );
  const [email, setEmail] = React.useState('');
  const [customerId, setCustomerId] = React.useState('');

  const handleClickRemoveByEmail = () => {
    if (_.isEmpty(email)) {
      alert('Emailアドレスを入力してください');
      return;
    }

    setDisableRemoveButton(true);
    const f = async () => {
      const accessToken = await getAccessTokenSilently();
      const resp = await fetch(
        `${applicationControllerBaseUrl}/test-tools/cleanup/userIds/${email}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (resp.ok) {
        alert('削除完了');
      } else {
        alert('削除失敗');
      }
    };
    f();
    setDisableRemoveButton(false);
  };

  const handleClickRemoveByCustomerId = () => {
    if (_.isEmpty(customerId)) {
      alert('顧客IDを入力してください');
      return;
    }

    setDisableRemoveButton(true);
    const f = async () => {
      const accessToken = await getAccessTokenSilently();
      const resp = await fetch(
        `${applicationControllerBaseUrl}/test-tools/cleanup/customerIds/${customerId}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      if (resp.ok) {
        alert('削除完了');
      } else {
        alert('削除失敗');
      }
    };
    f();
    setDisableRemoveButton(false);
  };

  return (
    <>
      <Meta>
        <title>マネジメントサイト 削除ツール</title>
      </Meta>
      <PageListTemplate
        heading1="削除ツール"
        heading2="Auth0アカウント、Project Boardテナント、顧客情報を削除するツールです"
      >
        <Container className={classes.root}>
          <Container className={classes.container}>
            <Typography variant="h3">emailアドレスから削除</Typography>
            <List>
              <ListItem>emailアドレス</ListItem>
              <ListItem>
                <TextField
                  type="text"
                  value={email}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      height: '32px',
                      border: '1px solid #C2CAD8',
                      borderRadius: '3px',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      fontSize: '14px',
                    },
                  }}
                  onChange={(e) => setEmail(e.target.value as string)}
                />
              </ListItem>
            </List>
            <StyledButton
              label="削除"
              onClick={handleClickRemoveByEmail}
              disabled={disableRemoveButton}
            />
          </Container>
          <Container className={classes.container}>
            <Typography variant="h3">顧客IDから削除</Typography>
            <List>
              <ListItem>顧客ID</ListItem>
              <ListItem>
                <TextField
                  type="text"
                  value={customerId}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      height: '32px',
                      border: '1px solid #C2CAD8',
                      borderRadius: '3px',
                      paddingLeft: '8px',
                      paddingRight: '8px',
                      fontSize: '14px',
                    },
                  }}
                  onChange={(e) => setCustomerId(e.target.value as string)}
                />
              </ListItem>
            </List>
            <StyledButton
              label="削除"
              onClick={handleClickRemoveByCustomerId}
              disabled={disableRemoveButton}
            />
          </Container>
        </Container>
      </PageListTemplate>
    </>
  );
};

export default RemovalTool;
