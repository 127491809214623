import React from 'react';
import { useLocation } from 'react-router-dom';

import { MenuLink } from '@bizapp-frontend/management/organisms/Menu';
import { ManagementDashboardTemplate } from '@bizapp-frontend/management/templates/ManagementDashboardTemplate';
import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/management/organisms/ProcessingDialog';
import {
  Customer,
  serviceInfo,
  serviceName,
  ServiceType,
} from '@bizapp-frontend/management/pages/utils';
import * as _ from 'lodash';

type ManagementDashboardDataState = {
  customers: Customer[];
  totalCustomerNumber: number;
  loadingStatus: {
    [key: string]: {
      // pathname
      [key: string]: ProcessingDialogState; // api request loading status
    };
  };
};

type ManagementDashboardDataAction =
  | { type: 'SET_CUSTOMERS'; customers: Customer[]; reset: boolean }
  | { type: 'SET_TOTAL_CUSTOMER_NUMBER'; totalCustomerNumber: number }
  | {
      type: 'SET_LOADING_STATUS';
      loadingStatus: { [key: string]: ProcessingDialogState };
      pathname: string;
    };

const initialState: ManagementDashboardDataState = {
  customers: [],
  totalCustomerNumber: 0,
  loadingStatus: {},
};

export const ManagementDashboardDataContext = React.createContext(
  {} as {
    managementDashboardDataState: ManagementDashboardDataState;
    dispatch: React.Dispatch<ManagementDashboardDataAction>;
  },
);

const reducer = (
  state: ManagementDashboardDataState,
  action: ManagementDashboardDataAction,
): ManagementDashboardDataState => {
  switch (action.type) {
    case 'SET_CUSTOMERS':
      if (action.reset) {
        return {
          ...state,
          customers: action.customers,
        };
      } else {
        return {
          ...state,
          customers: [...state.customers, ...action.customers],
        };
      }
    case 'SET_TOTAL_CUSTOMER_NUMBER':
      return {
        ...state,
        totalCustomerNumber: action.totalCustomerNumber,
      };
    case 'SET_LOADING_STATUS':
      const prevLoadingStatus = state.loadingStatus[action.pathname];
      const nextLoadingStatus = {
        ...prevLoadingStatus,
        ...action.loadingStatus,
      };
      return {
        ...state,
        loadingStatus: {
          [action.pathname]: nextLoadingStatus,
        },
      };
  }
};

export interface ManagementDashboardPageProps {}

export const ManagementDashboardPage: React.FC<ManagementDashboardPageProps> = () => {
  const { pathname } = useLocation();
  const [
    processingDialogState,
    setProcessingDialogState,
  ] = React.useState<ProcessingDialogState>('close');
  const [managementDashboardDataState, dispatch] = React.useReducer(
    reducer,
    initialState,
  );

  /**
   * @see https://docs.google.com/spreadsheets/d/1mvF_y4qjP4RiOn_s09pYqKx8ZNR44wtDZGbVuzUi-Wk/edit#gid=2086308394
   * only 顧客台帳 is available in 2022.02
   */
  const links: MenuLink[] = React.useMemo((): MenuLink[] => {
    const serviceLinks: MenuLink[] = _.keys(serviceInfo).map((service) => ({
      id: service,
      label: serviceName(service as ServiceType),
      href: `services/${service}/customers`,
    }));

    return [
      {
        id: 'customers',
        label: '顧客台帳',
        href: `/customers`,
      },
      {
        id: 'contract-info',
        label: '利用サービス',
        href: `#`,
        subLinks: serviceLinks,
        divider: true,
      },
      // {
      //   id: 'trial',
      //   label: 'トライアル',
      //   href: `#`,
      // },
      // {
      //   id: 'web-application',
      //   label: 'Web申込',
      //   href: `#`,
      //   divider: true,
      // },
      // {
      //   id: 'stripe',
      //   label: 'Stripe',
      //   href: `#`,
      //   externalLink: true,
      // },
      // {
      //   id: 'mfkessai',
      //   label: 'MF Kessai',
      //   href: `#`,
      //   externalLink: true,
      // },
      // {
      //   id: 'bizdev-tool',
      //   label: 'BizDev Tool',
      //   href: `#`,
      //   externalLink: true,
      // },
    ];
  }, []);

  // get all requests loading status on current page
  const requests = managementDashboardDataState.loadingStatus[pathname];
  // check whether exist request which is loading
  const isLoading = _.values(requests).some((status) => status === 'wait');
  // check whether exist request which is failed
  const hasError = _.values(requests).some((status) => status === 'error');
  const state = hasError ? 'error' : isLoading ? 'wait' : 'close';

  React.useEffect(() => {
    setProcessingDialogState(state);
  }, [state]);

  return (
    <ManagementDashboardDataContext.Provider
      value={{
        managementDashboardDataState: managementDashboardDataState,
        dispatch: dispatch,
      }}
    >
      <ManagementDashboardTemplate links={links} />
      <ProcessingDialog
        state={processingDialogState}
        onClickError={() => setProcessingDialogState('close')}
      />
    </ManagementDashboardDataContext.Provider>
  );
};
