import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as _ from 'lodash';

import Meta from '@bizapp-frontend/management/organisms/Meta';

import {
  Company,
  CustomerDetailTemplate,
  Tenant,
  User,
} from '@bizapp-frontend/management/templates/CustomerDetailTemplate';
import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/management/organisms/ProcessingDialog';
import {
  Contract,
  isStandardPlan,
  ServiceName,
} from '@bizapp-frontend/management/templates/form/utils';
import { filter, flow, map, uniq } from 'lodash/fp';
import { GlobalsContext } from '@bizapp-frontend/management/globals';
import { GatewayVariant } from './utils';

export interface CustomerDetailPageProps {
  customerBaseUrl: string;
  tenantManagementBaseUrl: string;
  contractBaseUrl: string;
  paymentGatewayBaseUrl: string;
}

const ServiceNameMap = new Map<string, string>([
  ['pjb', 'Project Board'],
  ['pjr', 'Project Room'],
  ['ess', 'ESS'],
  ['ebm', 'EBM'],
  ['chatbot', 'Chatbot'],
  ['workflow', 'Workflow'],
]);

type CustomerDetailDataState = {
  tenants: Tenant[];
  company: Company;
  gatewayVariant: GatewayVariant;
  user: User;
  processingDialogState: ProcessingDialogState;
  contracts: Contract[];
  reloadContracts: boolean;
  reloadTenants: boolean;
};

const reducer = (
  state: CustomerDetailDataState,
  action: CustomerDetailAction,
): CustomerDetailDataState => {
  switch (action.type) {
    case 'SET_TENANTS':
      return {
        ...state,
        tenants: action.tenants,
        reloadTenants: false,
      };
    case 'SET_COMPANY':
      return {
        ...state,
        company: action.company,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.user,
      };
    case 'SET_PROCESSING_DIALOG':
      return {
        ...state,
        processingDialogState: action.processingDialogState,
      };
    case 'SET_CONTRACTS':
      return {
        ...state,
        contracts: action.contracts,
        reloadContracts: false,
      };
    case 'RELOAD_CONTRACTS':
      return {
        ...state,
        reloadContracts: true,
      };
    case 'RELOAD_TENANTS':
      return {
        ...state,
        reloadTenants: true,
      };
    case 'SET_GATEWAY_VARIANT':
      return {
        ...state,
        gatewayVariant: action.gatewayVariant,
      };
  }
  return state;
};

type CustomerDetailAction =
  | { type: 'SET_TENANTS'; tenants: Tenant[] }
  | { type: 'SET_COMPANY'; company: Company }
  | { type: 'SET_USER'; user: User }
  | {
      type: 'SET_PROCESSING_DIALOG';
      processingDialogState: ProcessingDialogState;
    }
  | { type: 'SET_CONTRACTS'; contracts: Contract[] }
  | { type: 'RELOAD_CONTRACTS' }
  | { type: 'RELOAD_TENANTS' }
  | { type: 'SET_GATEWAY_VARIANT'; gatewayVariant: GatewayVariant };

const initialState: CustomerDetailDataState = {
  tenants: [],
  company: {} as Company,
  gatewayVariant: 'unknown',
  user: {} as User,
  processingDialogState: 'close',
  contracts: [],
  reloadContracts: true,
  reloadTenants: true,
};

export const CustomerDetailContext = React.createContext(
  {} as {
    serviceNameMap: Map<string, string>;
    customerDetailState: CustomerDetailDataState;
    dispatch: React.Dispatch<CustomerDetailAction>;
  },
);

const get = async (url: string, accessToken: string): Promise<any> => {
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  // probably, success status is 200, but it's commonized function, so it's unkown.
  if (resp.ok) {
    const json = await resp.json();
    return Promise.resolve(json);
  } else {
    return Promise.reject();
  }
};

export const CustomerDetailPage: React.FC<CustomerDetailPageProps> = ({
  customerBaseUrl,
  tenantManagementBaseUrl,
  contractBaseUrl,
  paymentGatewayBaseUrl,
}) => {
  const { state: globalState } = React.useContext(GlobalsContext);
  const now = React.useCallback(() => {
    let ms = Date.now();
    if (globalState.development && globalState.developmentMeta.currentTimeMs) {
      ms = globalState.developmentMeta.currentTimeMs;
    }
    return ms;
  }, [globalState.development, globalState.developmentMeta.currentTimeMs]);

  const [customerDetailDataState, dispatch] = React.useReducer(
    reducer,
    initialState,
  );
  const { customerId } = useParams();
  const location = useLocation();

  const [title, setTitle] = React.useState('');

  const { getAccessTokenSilently } = useAuth0();

  type StateDict = { [key: string]: boolean };
  const [loadingStates, setLoadingStates] = React.useState<StateDict>({});
  const registerLoading = (key: string, loading: boolean) => {
    setLoadingStates((prev) => {
      return {
        ...prev,
        [key]: loading,
      };
    });
  };
  const startLoading = React.useCallback(
    (key: string) => registerLoading(key, true),
    [],
  );
  const finishLoading = React.useCallback(
    (key: string) => registerLoading(key, false),
    [],
  );
  const [backUrl, setBackUrl] = React.useState('');

  React.useEffect(() => {
    const isLoading = Object.values(loadingStates).some((it) => it);
    dispatch({
      type: 'SET_PROCESSING_DIALOG',
      processingDialogState: isLoading ? 'wait' : 'close',
    });
  }, [loadingStates]);

  const fetchTenantInfo = React.useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const url = `${tenantManagementBaseUrl}/api/tenant-management/customers/${customerId}`;
    startLoading('fetchTenantInfo');
    const tenantJson = await get(url, accessToken);
    finishLoading('fetchTenantInfo');
    dispatch({
      type: 'SET_TENANTS',
      tenants: tenantJson,
    });
  }, [
    customerId,
    finishLoading,
    getAccessTokenSilently,
    startLoading,
    tenantManagementBaseUrl,
  ]);

  const fetchCustomerInfo = React.useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const url = `${customerBaseUrl}/api/customer/customers/${customerId}`;
    startLoading('fetchCompanyInfo');
    const customerJson = await get(url, accessToken);
    finishLoading('fetchCompanyInfo');
    dispatch({
      type: 'SET_COMPANY',
      company: customerJson.company,
    });
    dispatch({
      type: 'SET_USER',
      user: customerJson.users[0],
    });
  }, [
    customerBaseUrl,
    customerId,
    finishLoading,
    getAccessTokenSilently,
    startLoading,
  ]);

  const fetchGatewayVariant = React.useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const url = `${paymentGatewayBaseUrl}/api/payment-gateway/mfkessai/customers/${customerId}/variant`;
      startLoading('fetchGatewayVariant');
      const gatewayVariantJson = await get(url, accessToken);
      const variant = gatewayVariantJson.variant;
      const gatewayVariant =
        variant === 'wape' || variant === 'waps' ? variant : 'unknown';
      dispatch({
        type: 'SET_GATEWAY_VARIANT',
        gatewayVariant: gatewayVariant as GatewayVariant,
      });
    } catch (e) {
      dispatch({
        type: 'SET_GATEWAY_VARIANT',
        gatewayVariant: 'unknown',
      });
    } finally {
      finishLoading('fetchGatewayVariant');
    }
  }, [
    paymentGatewayBaseUrl,
    customerId,
    finishLoading,
    getAccessTokenSilently,
    startLoading,
  ]);

  const links = React.useMemo(() => {
    const contractInfoSubLinks = flow(
      filter((it: Contract) => isStandardPlan(it.planId)),
      map((it: Contract) => it.serviceId),
      uniq,
      map((serviceId) => {
        return {
          id: serviceId,
          label: ServiceName.get(serviceId),
          href: `/customers/${customerId}/services/${serviceId}`,
        };
      }),
    )(customerDetailDataState.contracts);

    return [
      {
        id: 'customer-info',
        label: '顧客情報',
        href: `/customers/${customerId}/info`,
        divider: true,
      },
      {
        id: 'contract-info',
        label: '利用サービス',
        href: `/customers/${customerId}/services`,
        subLinks: contractInfoSubLinks,
        divider: true,
      },
      {
        id: 'application-info',
        label: '請求情報',
        href: `/customers/${customerId}/billing-information`,
      },
    ];
  }, [customerDetailDataState.contracts, customerId]);

  React.useEffect(() => {
    if (customerDetailDataState.reloadTenants) {
      fetchTenantInfo();
    }
  }, [customerDetailDataState.reloadTenants, fetchTenantInfo]);

  React.useEffect(() => {
    fetchCustomerInfo();
    fetchGatewayVariant();
  }, [customerId, fetchCustomerInfo, fetchGatewayVariant]);

  const onClickDialogClose = () =>
    dispatch({
      type: 'SET_PROCESSING_DIALOG',
      processingDialogState: 'close',
    });

  const getContracts = React.useCallback(
    async (customerId: string, lastEvaluatedKey?: string) => {
      const accessToken = await getAccessTokenSilently();
      const method = 'GET';
      let url = `${contractBaseUrl}/api/contract/contracts?customerId=${customerId}&limit=100`;
      if (lastEvaluatedKey) {
        url += '&lastEvaluatedKey=' + lastEvaluatedKey;
      }
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      };
      const resp = await fetch(url, {
        method: method,
        headers: headers,
      });

      if (resp.status === 200) {
        return Promise.resolve(resp.json());
      } else {
        return Promise.resolve([]);
      }
    },
    [contractBaseUrl, getAccessTokenSilently],
  );

  React.useEffect(() => {
    if (customerId && customerDetailDataState.reloadContracts) {
      const f = async () => {
        startLoading('getContracts');
        let contracts: Contract[] = [];
        let lastEvaluatedKey = '';
        do {
          const resp = await getContracts(customerId, lastEvaluatedKey);
          contracts = contracts.concat(resp.contracts);
          lastEvaluatedKey = resp.lastEvaluatedKey;
        } while (lastEvaluatedKey !== '');
        finishLoading('getContracts');
        dispatch({
          type: 'SET_CONTRACTS',
          contracts: contracts,
        });
      };
      f();
    }
  }, [
    customerDetailDataState.reloadContracts,
    customerId,
    finishLoading,
    getContracts,
    startLoading,
  ]);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const backUrl = params.get('backUrl') ?? '';
    if (!_.isEmpty(backUrl)) {
      setBackUrl(atob(backUrl));
    }
  }, [location]);

  React.useEffect(() => {
    const titleParts = [];
    if (!_.isEmpty(customerDetailDataState.company.companyName)) {
      titleParts.push(customerDetailDataState.company.companyName);
    }
    titleParts.push('HUE Works Suite マネジメントサイト');
    setTitle(titleParts.join(' - '));
  }, [customerDetailDataState.company.companyName]);

  return (
    <>
      <Meta>
        <title>{title}</title>
        <meta property="og:title" content={title} />
      </Meta>
      <CustomerDetailContext.Provider
        value={{
          serviceNameMap: ServiceNameMap,
          customerDetailState: customerDetailDataState,
          dispatch: dispatch,
        }}
      >
        <CustomerDetailTemplate
          links={links}
          customerId={customerId}
          companyName={customerDetailDataState.company.companyName}
          gatewayVariant={customerDetailDataState.gatewayVariant}
          backUrl={backUrl}
          devNow={globalState.development ? now() : undefined}
        />
        <ProcessingDialog
          state={customerDetailDataState.processingDialogState}
          onClickError={onClickDialogClose}
        />
      </CustomerDetailContext.Provider>
    </>
  );
};
