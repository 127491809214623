import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { filter, isEqual, map } from 'lodash';

import {
  serviceName,
  Customer,
  timestamp2ZonedDate,
  endOfCurrentMonth,
  contractStatus,
  contractStatusName,
  paymentMethod,
} from '@bizapp-frontend/management/pages/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: `calc(100vh - ${theme.spacing(7)}px)`,
    },
    title: {
      display: 'block',
      boxSizing: 'border-box',
      height: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingTop: '5px',
      paddingBottom: '5px',
      fontSize: '20px',
      color: '#333333',
      fontWeight: 'bold',
      '& :first-child': {
        fontWeight: 'normal',
        fontSize: '20px',
        boxSizing: 'border-box',
        marginLeft: theme.spacing(3),
      },
      '& :last-child': {
        marginLeft: theme.spacing(1),
        fontSize: '14px',
      },
    },
    table: {},
    tableHead: {
      '& th': {
        borderTop: '1px solid #DCE0E7 !important',
        backgroundColor: '#FFFFFF !important',
      },
      '& span': {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#A4AAB0',
      },
    },
    tableBody: {
      '& span': {
        fontSize: '14px',
        color: '#333333',
        marginBottom: '6px',
      },
      '& span:last-child': {
        marginBottom: 0,
      },
      '& td': {
        cursor: 'pointer',
      },
    },
    tableCell: {
      minHeight: theme.spacing(4),
      verticalAlign: 'top',
      boxSizing: 'border-box',
      paddingTop: '6px !important',
      paddingBottom: '5px !important',
      '& span': {
        display: 'flex',
      },
    },
    logo: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      marginRight: theme.spacing(1),
    },
    customerNameColumn: {
      paddingLeft: theme.spacing(5),
      minWidth: theme.spacing(32),
      borderRight: '1px solid #DCE0E7',
    },
    serviceNameColumn: {
      paddingRight: theme.spacing(1),
      minWidth: theme.spacing(21.5),
    },
    contractStatusColumn: {
      paddingLeft: 0,
      paddingRight: theme.spacing(1),
      minWidth: theme.spacing(16),
    },
    endDateColumn: {
      paddingLeft: 0,
      minWidth: theme.spacing(16.5),
      '& span': {
        fontSize: '12px',
        color: '#A4AAB0',
      },
    },
    paymentKindColumn: {
      paddingLeft: 0,
      minWidth: theme.spacing(22),
    },
    contactColumn: {
      paddingLeft: 0,
      minWidth: theme.spacing(14.5),
    },
    registrationDateColumn: {
      paddingLeft: 0,
      width: '100%',
    },
    trialExpired: {
      opacity: 0.4,
    },
  }),
);

type ItemRowProps = {
  customer: Customer;
  onClick: Function;
};

const ItemRow: React.FC<ItemRowProps> = React.memo(
  ({ customer, onClick = () => {} }) => {
    const classes = useStyles();
    const contracts = map(
      filter(
        customer.contracts,
        (contract) => !contract.delete && !contract.hidden,
      ),
      (contract) => {
        const service = serviceName(contract.serviceId);
        const status = contractStatus(contract.usageKind, contract.endDate);
        const endDate =
          contract.usageKind === 'trial' || contract.usageKind === 'freemium'
            ? timestamp2ZonedDate(contract.endDate)
            : endOfCurrentMonth();
        return {
          // for 22.05.hotfix
          logo: `${
            process.env.PUBLIC_URL
          }/images/logo/${contract.serviceId
            .toString()
            .replace(/-internal$/g, '')}.png`,
          service: service,
          status: status,
          endDate: endDate,
        };
      },
    );
    const isAllTrialExpired =
      contracts.length !== 0 &&
      filter(contracts, (contract) => contract.status === 'trial_expired')
        .length === contracts.length;

    const handleOnClick = () => {
      // donot trigger click event when user selects some text
      if (window.getSelection()?.toString().length !== 0) {
        return;
      }
      onClick();
    };

    return (
      <TableRow key={customer.customerId} hover onClick={handleOnClick}>
        <TableCell
          className={`${classes.tableCell} ${classes.customerNameColumn}`}
        >
          <Typography
            component="span"
            className={`${isAllTrialExpired ? classes.trialExpired : ''}`}
          >
            {customer.companyName}
          </Typography>
        </TableCell>
        <TableCell
          className={`${classes.tableCell} ${classes.serviceNameColumn}`}
        >
          {contracts.map((contract, i) => (
            <Typography
              key={`${customer.customerId}-service-${i}`}
              component="span"
              className={`${
                contract.status === 'trial_expired' ? classes.trialExpired : ''
              }`}
            >
              <img
                className={classes.logo}
                src={contract.logo}
                alt="service-logo"
              />
              {contract.service}
            </Typography>
          ))}
        </TableCell>
        <TableCell
          className={`${classes.tableCell} ${classes.contractStatusColumn}`}
        >
          {contracts.map((contract, i) => (
            <Typography
              key={`${customer.customerId}-contract-status-${i}`}
              component="span"
              className={`${
                contract.status === 'trial_expired' ? classes.trialExpired : ''
              }`}
            >
              {contractStatusName(contract.status)}
            </Typography>
          ))}
        </TableCell>
        <TableCell className={`${classes.tableCell} ${classes.endDateColumn}`}>
          {contracts.map((contract, i) => (
            <Typography
              key={`${customer.customerId}-end-date-${i}`}
              component="span"
              className={`${
                contract.status === 'trial_expired' ? classes.trialExpired : ''
              }`}
            >
              {`~${contract.endDate}`}
            </Typography>
          ))}
        </TableCell>
        <TableCell
          className={`${classes.tableCell} ${classes.paymentKindColumn}`}
        >
          <Typography
            component="span"
            className={`${isAllTrialExpired ? classes.trialExpired : ''}`}
          >
            {paymentMethod(customer.paymentGatewayId, customer.paymentMethod)}
          </Typography>
        </TableCell>
        <TableCell
          className={`${classes.tableCell} ${classes.registrationDateColumn}`}
        >
          <Typography
            component="span"
            className={`${isAllTrialExpired ? classes.trialExpired : ''}`}
          >
            {timestamp2ZonedDate(customer.registeredDate)}
          </Typography>
        </TableCell>
      </TableRow>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);

export interface CustomerAccountListTemplateProps {
  customers: Customer[];
  totalCustomerNumber: number;
  onCustomerClick: Function;
  onScrollEnd?: Function;
}

export const CustomerAccountListTemplate: React.FC<CustomerAccountListTemplateProps> = ({
  customers,
  totalCustomerNumber,
  onCustomerClick,
  onScrollEnd,
}) => {
  const classes = useStyles();
  const tableRef = React.useRef<HTMLTableElement>(null);

  const handleOnRowClick = (index: number) => {
    onCustomerClick(index);
  };

  const handleScroll = (e: React.UIEvent) => {
    if (!tableRef.current) {
      return;
    }
    const { scrollTop, scrollHeight, clientHeight } = tableRef.current;
    if (scrollTop + clientHeight === scrollHeight) {
      onScrollEnd && onScrollEnd();
    }
  };

  return (
    <>
      <Typography component="span" className={classes.title}>
        顧客台帳
        <Typography component="span">{totalCustomerNumber}</Typography>
        <Typography component="span">件</Typography>
      </Typography>
      <TableContainer
        className={classes.container}
        onScroll={handleScroll}
        ref={tableRef}
      >
        <Table className={classes.table} stickyHeader={true}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell
                className={`${classes.tableCell} ${classes.customerNameColumn}`}
              >
                <Typography component="span">顧客名</Typography>
              </TableCell>
              <TableCell
                className={`${classes.tableCell} ${classes.serviceNameColumn}`}
              >
                <Typography component="span">サービス利用状況</Typography>
              </TableCell>
              <TableCell
                className={`${classes.tableCell} ${classes.contractStatusColumn}`}
              ></TableCell>
              <TableCell
                className={`${classes.tableCell} ${classes.endDateColumn}`}
              ></TableCell>
              <TableCell
                className={`${classes.tableCell} ${classes.paymentKindColumn}`}
              >
                <Typography component="span">支払方法</Typography>
              </TableCell>
              <TableCell
                className={`${classes.tableCell} ${classes.registrationDateColumn}`}
              >
                <Typography component="span">登録日</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {customers.map((customer, i) => {
              return (
                <ItemRow
                  key={i}
                  customer={customer}
                  onClick={() => handleOnRowClick(i)}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
