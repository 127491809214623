import React from 'react';
import {
  FormControl,
  FormControlLabel,
  makeStyles,
  createStyles,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@material-ui/core';

import {
  StyledInputLabel,
  StyledHelperText,
} from '@bizapp-frontend/management/molecules/form/FormComponent';
import { requiredFormInputValidator } from '@bizapp-frontend/management/molecules/form/FormInput';

export interface RadioType {
  id: string;
  label: string;
}

export interface FormRadioGroupProps extends RadioGroupProps {
  className?: string;
  id: string;
  label?: React.ReactNode;
  required?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  options?: Map<string, string>;
  setValue?: (value: string | ((prevVar: string) => string)) => void;
  setIsValid?: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  forceValidation?: boolean;
  setForceValidation?: (
    value: boolean | ((prevVar: boolean) => boolean),
  ) => void;
  postChange?: (value: string) => void;
  disabled?: boolean;
  updated?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'block',
    },
    ctrLabel: {
      '& .MuiSvgIcon-root': {
        width: 16,
        height: 16,
      },
      '& .MuiRadio-root': {
        padding: 0,
      },
      margin: theme.spacing(1.125, 0, 0, 0),
      '&:first-child': {
        marginTop: 0,
      },
      '& .MuiTypography-body1': {
        marginLeft: theme.spacing(1),
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: 0,
        color: '#333333',
      },
      '& .MuiRadio-colorSecondary.Mui-checked': {
        color: '#787F86',
      },
      '& .MuiRadio-colorSecondary.Mui-disabled': {
        color: '#D0D0D0',
      },
      '&.MuiCustom-updated .MuiFormControlLabel-label': {
        color: '#4285F4',
      },
    },
    helperText: {
      marginTop: theme.spacing(1),
    },
  }),
);

export const FormRadioGroup: React.FC<FormRadioGroupProps> = ({
  className = '',
  id,
  label,
  required = false,
  helperText,
  options,
  setValue,
  setIsValid,
  forceValidation,
  setForceValidation,
  onChange,
  postChange,
  disabled = false,
  error = false,
  updated,
  ...props
}) => {
  const [helper, setHelper] = React.useState(helperText ?? '');
  const classes = useStyles();

  const handleChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    onChange && onChange(ev, value);
    options && setValue && setValue(value);
    postChange && postChange(value);
  };

  React.useEffect(() => {
    if (forceValidation && required) {
      setForceValidation && setForceValidation(false);
    }
  }, [required, forceValidation, setIsValid, setForceValidation]);

  React.useEffect(() => {
    if (helperText) {
      setHelper(helperText);
    } else if (required) {
      setHelper(requiredFormInputValidator.helperText);
    }
  }, [helperText, required]);

  return (
    <FormControl
      component="fieldset"
      required={required}
      error={error}
      className={`${classes.root} ${className}`}
    >
      {label && <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>}
      <RadioGroup {...props} onChange={handleChange}>
        {options &&
          Array.from(options.entries()).map((kv, i) => {
            const [k, v] = kv;
            return (
              <FormControlLabel
                control={<Radio checked={props.value === k} />}
                label={v}
                value={k}
                className={`${classes.ctrLabel} ${
                  updated && props.value === k ? 'MuiCustom-updated' : ''
                }`}
                disabled={disabled}
                key={k}
              />
            );
          })}
      </RadioGroup>
      {helper && error && (
        <StyledHelperText children={helper} className={classes.helperText} />
      )}
    </FormControl>
  );
};
