import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Route, Routes, Navigate } from 'react-router-dom';
import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { isArray } from 'lodash';

import { theme } from '@bizapp-frontend/management/styles/theme';

import { CustomerDetailPage } from '@bizapp-frontend/management/pages/CustomerDetailPage';
import { CustomerDetailInfoPage } from '@bizapp-frontend/management/pages/CustomerDetailInfoPage';
import { CustomerDetailTenantPage } from '@bizapp-frontend/management/pages/CustomerDetailTenantPage';
import { CustomerDetailTenantListPage } from '@bizapp-frontend/management/pages/CustomerDetailTenantListPage';
import { CustomerDetailApplicationPage } from '@bizapp-frontend/management/pages/CustomerDetailApplicationPage';
import { ServiceCustomerList } from '@bizapp-frontend/management/pages/ServiceCustomerList';
import { ManagementDashboardPage } from '@bizapp-frontend/management/pages/ManagementDashPage';
import { CustomerAccountListPage } from '@bizapp-frontend/management/pages/CustomerAccountListPage';
import { CustomerListPage as OldCustomerListPage } from '@bizapp-frontend/management/pages/CustomerListPage';

import {
  GlobalsContext,
  reducer,
  initialState,
  Role,
} from '@bizapp-frontend/management/globals';
import PageList from '@bizapp-frontend/management/pages/internal/PageList';
import RemovalTool from '@bizapp-frontend/management/pages/internal/RemovalTool';

const App: React.FC = () => {
  const onRedirectCallback = (appState: AppState) => {
    if (appState && appState.returnTo) {
      console.log(appState.returnTo);
      window.location.href = appState.returnTo;
    }
  };

  return (
    <Auth0Provider
      domain={`${process.env.REACT_APP_AUTH0_DOMAIN ?? ''}`}
      clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}`}
      redirectUri={window.location.origin}
      audience={`${process.env.REACT_APP_AUTH0_AUDIENCE ?? ''}`}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      <AppContent />
    </Auth0Provider>
  );
};

const AppContent: React.FC = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { isAuthenticated, user } = useAuth0();

  const AUTH0_NAMESPACE = process.env.REACT_APP_AUTH0_NAMESPACE ?? '';

  React.useEffect(() => {
    if (!isAuthenticated || state.emailAddress || !user) {
      return;
    }
    const roles = isArray(user[`${AUTH0_NAMESPACE}/roles`])
      ? user[`${AUTH0_NAMESPACE}/roles`]
      : [];
    const role: Role =
      roles.length > 0 ? (roles[0] as Role) : 'bizapp-management-read-only';
    dispatch({ type: 'SET_ROLE', role: role });
    dispatch({ type: 'SET_USER_ID', userId: user.email ?? '' });
    dispatch({ type: 'SET_EMAIL_ADDRESS', emailAddress: user.email ?? '' });
  }, [isAuthenticated, state.emailAddress, user, AUTH0_NAMESPACE]);

  const APPLICATION_CONTROLLER_BASE_URL =
    process.env.REACT_APP_API_APPLICATION_CONTROLLER_BASE_URL ?? '';
  const CONTRACT_BASE_URL = process.env.REACT_APP_API_CONTRACT_BASE_URL ?? '';
  const CUSTOMER_BASE_URL = process.env.REACT_APP_API_CUSTOMER_BASE_URL ?? '';
  const TENANT_MANAGEMENT_BASE_URL =
    process.env.REACT_APP_API_TENANT_MANAGEMENT_BASE_URL ?? '';
  const PAYMENT_GATEWAY_BASE_URL =
    process.env.REACT_APP_API_PAYMENT_GATEWAY_BASE_URL ?? '';
  const MFK_BASE_URL = process.env.REACT_APP_MFK_BASE_URL ?? '';
  const FEE_CALCULATOR_BASE_URL =
    process.env.REACT_APP_API_FEE_CALCULATOR_BASE_URL ?? '';
  const VIEW_CONTROLLER_BASE_URL =
    process.env.REACT_APP_API_VIEW_CONTROLLER_BASE_URL ?? '';
  const ENABLE_REMOVAL_TOOL =
    process.env.REACT_APP_ENABLE_REMOVAL_TOOL === 'true' ? true : false;

  return (
    <GlobalsContext.Provider value={{ state, dispatch }}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route
            path="/"
            element={<ProtectedElement element={<ManagementDashboardPage />} />}
          >
            <Route
              path="/"
              element={
                <ProtectedElement
                  element={<Navigate to="/customers" replace />}
                />
              }
            />
            <Route
              path="/customers"
              element={
                <ProtectedElement
                  element={
                    <CustomerAccountListPage
                      viewControllerBaseUrl={VIEW_CONTROLLER_BASE_URL}
                    />
                  }
                />
              }
            />
          </Route>
          <Route
            path="/customers/:customerId"
            element={
              <ProtectedElement
                element={
                  <CustomerDetailPage
                    customerBaseUrl={CUSTOMER_BASE_URL}
                    tenantManagementBaseUrl={TENANT_MANAGEMENT_BASE_URL}
                    contractBaseUrl={CONTRACT_BASE_URL}
                    paymentGatewayBaseUrl={PAYMENT_GATEWAY_BASE_URL}
                  />
                }
              />
            }
          >
            <Route
              path="info"
              element={
                <ProtectedElement element={<CustomerDetailInfoPage />} />
              }
            />
            <Route
              path="services"
              element={
                <ProtectedElement
                  element={
                    <CustomerDetailTenantListPage
                      contractBaseUrl={CONTRACT_BASE_URL}
                      applicationControllerBaseUrl={
                        APPLICATION_CONTROLLER_BASE_URL
                      }
                    />
                  }
                />
              }
            />
            <Route
              path="services/:serviceId"
              element={
                <ProtectedElement
                  element={
                    <CustomerDetailTenantPage
                      contractBaseUrl={CONTRACT_BASE_URL}
                      applicationControllerBaseUrl={
                        APPLICATION_CONTROLLER_BASE_URL
                      }
                    />
                  }
                />
              }
            />
            <Route
              path="billing-information"
              element={
                <ProtectedElement
                  element={
                    <CustomerDetailApplicationPage
                      contractBaseUrl={CONTRACT_BASE_URL}
                      applicationControllerBaseUrl={
                        APPLICATION_CONTROLLER_BASE_URL
                      }
                      paymentGatewayAPIBaseUrl={PAYMENT_GATEWAY_BASE_URL}
                      mfkBaseUrl={MFK_BASE_URL}
                      feeCalculatorAPIBaseUrl={FEE_CALCULATOR_BASE_URL}
                    />
                  }
                />
              }
            />
          </Route>
          <Route
            path="/services/:serviceId/customers"
            element={
              <ProtectedElement
                element={
                  <ServiceCustomerList
                    applicationControllerBaseUrl={
                      APPLICATION_CONTROLLER_BASE_URL
                    }
                  />
                }
              />
            }
          />
          <Route path="/internal/page-list" element={<PageList />} />
          <Route
            path="/internal/old/customers"
            element={
              <OldCustomerListPage
                applicationControllerBaseUrl={APPLICATION_CONTROLLER_BASE_URL}
                customerBaseUrl={CUSTOMER_BASE_URL}
              />
            }
          />
          {ENABLE_REMOVAL_TOOL && (
            <Route
              path="/internal/removal-tool"
              element={
                <ProtectedElement
                  element={
                    <RemovalTool
                      applicationControllerBaseUrl={
                        APPLICATION_CONTROLLER_BASE_URL
                      }
                    />
                  }
                />
              }
            />
          )}
          <Route
            path="/internal/*"
            element={<Navigate to="/internal/page-list" replace />}
          />
          <Route path="*" element={<Navigate to="/customers" replace />} />
        </Routes>
      </ThemeProvider>
    </GlobalsContext.Provider>
  );
};

export const ProtectedElement: React.PropsWithChildren<any> = ({
  element,
  development,
}: {
  element?: React.ReactElement | null;
  development?: boolean;
}) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    if (development || isLoading || isAuthenticated) {
      return;
    }
    (async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: window.location.href,
        },
      });
    })();
  }, [development, isAuthenticated, isLoading, loginWithRedirect]);

  return development || isAuthenticated ? element : <></>;
};

export default App;
