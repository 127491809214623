import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  styled,
  Theme,
} from '@material-ui/core/styles';

import { StyledButton } from '@bizapp-frontend/management/molecules/StyledButton';
import { StyledModal } from '@bizapp-frontend/management/molecules/StyledModal';
import {
  dateStrToJSTDayLastMs,
  MAX_DATE_MARK,
  MAX_DATE_NS,
} from '@bizapp-frontend/management/templates/form/utils';
import { FormCheckbox } from '../molecules/form/FormCheckbox';

const timestamp2Date = (timestamp: number) => {
  if (!timestamp) {
    return '';
  }
  return new Date(timestamp / 1000000).toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      width: '464px',
      height: '432px',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 16px 32px #00000014',
      padding: theme.spacing(4),
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    cancelBtn: {
      boxShadow: '0px 2px 4px #00000029',
      width: theme.spacing(22.5),
      height: theme.spacing(5),
      paddingTop: theme.spacing(1.2),
      paddingBottom: theme.spacing(1.2),
      fontSize: '13px',
    },
    updateBtn: {
      width: theme.spacing(22.5),
      height: theme.spacing(5),
      paddingTop: theme.spacing(1.2),
      paddingBottom: theme.spacing(1.2),
      fontSize: '12px',
    },
    label: {
      fontSize: '14px',
      color: '#777777',
      marginBottom: theme.spacing(1),
    },
    value: {
      fontSize: '16px',
      color: '#333333',
    },
    dateTimePicker: {
      '&::-webkit-calendar-picker-indicator': {
        display: 'none',
        '-webkit-appearance': 'none',
      },
      '& .Mui-disabled': {
        backgroundColor: '#EDEFF3',
        borderColor: 'transparent !important',
        color: '#333333',
      },
      marginBottom: theme.spacing(1),
    },
    note: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
    warningField: {
      '& > .MuiInputBase-root': {
        color: '#F4B142 !important',
        borderColor: '#F4B142 !important',
      },
    },
    warningMessage: {
      margin: theme.spacing(-2, 0, 1),
      padding: 0,
      color: '#F4B142',
      fontWeight: 'bold',
      textAlign: 'right',
    },
  }),
);

export interface ExtendTrialModalProps {
  open: boolean;
  contractId: string;
  endDate: number;
  note: string;
  onCancelClicked?: Function;
  onUpdateClicked?: Function;
}

export const ExtendTrialModal: React.FC<ExtendTrialModalProps> = ({
  open,
  contractId,
  endDate: currEndDateNs,
  note,
  onCancelClicked,
  onUpdateClicked,
}) => {
  const classes = useStyles();
  const [selectedEndDateMs, setSelectedEndDateMs] = React.useState(0);
  const [selectedEndDate, setSelectedEndDate] = React.useState('');
  const [newNote, setNewNote] = React.useState(note);
  const [warning, setWarning] = React.useState(false);
  const [infinity, setInfinity] = React.useState(false);
  const [endDateSelectDisabled, setEndDateSelectDisabled] = React.useState(
    false,
  );

  React.useEffect(() => {
    setSelectedEndDateMs(currEndDateNs / 1000000);
  }, [currEndDateNs]);

  React.useEffect(() => {
    setSelectedEndDate(
      timestamp2Date(selectedEndDateMs * 1000000).replaceAll('/', '-'),
    );
    setInfinity(selectedEndDateMs * 1000000 === MAX_DATE_NS);

    // show warning if current end date is less than selected date
    setWarning(selectedEndDateMs * 1000000 < currEndDateNs);
  }, [selectedEndDateMs, currEndDateNs]);

  React.useEffect(() => {
    setEndDateSelectDisabled(infinity);
    const newEndDateNs = infinity ? MAX_DATE_NS : currEndDateNs;
    setSelectedEndDateMs(newEndDateNs / 1000000);
  }, [infinity, currEndDateNs]);

  const Footer = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  }));

  return (
    <StyledModal open={open}>
      <div className={classes.root}>
        <Typography component={'span'} className={classes.label}>
          契約ID
        </Typography>
        <Typography component={'span'} className={classes.value}>
          {contractId}
        </Typography>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            marginTop: '16px',
            marginBottom: '16px',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography component={'span'} className={classes.label}>
              利用終了日（更新前)
            </Typography>
            <Typography component={'span'} className={classes.value}>
              {timestamp2Date(currEndDateNs)}
            </Typography>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography component={'span'} className={classes.label}>
              利用終了日（更新後)
            </Typography>
            <TextField
              value={selectedEndDate}
              type="date"
              className={warning ? classes.warningField : ''}
              classes={{ root: classes.dateTimePicker }}
              disabled={endDateSelectDisabled}
              InputProps={{
                disableUnderline: true,
                style: {
                  border: '1px solid #C2CAD8',
                  borderRadius: '3px',
                  paddingLeft: '8px',
                  paddingRight: '8px',
                  width: '180px',
                  fontSize: '14px',
                },
              }}
              inputProps={{
                // It's workaround for preventing to input date before today.
                // getTime() return millisecond, but the argument of timesatamp2Dat is nanoseconds
                min: timestamp2Date(new Date().getTime() * 1000000).replaceAll(
                  '/',
                  '-',
                ),
              }}
              onChange={(event) => {
                const ms = dateStrToJSTDayLastMs(event.target.value);
                setSelectedEndDateMs(ms);
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
            <FormCheckbox
              id="endTimeIndefinite"
              label="無期限にする"
              value={infinity}
              setValue={(v) => setInfinity(v)}
            />
          </div>
        </div>
        {warning && (
          <Typography className={classes.warningMessage}>
            更新後の日付が更新前よりも前の日付です
          </Typography>
        )}
        <Typography component={'span'} className={classes.label}>
          コメント
        </Typography>
        <TextField
          className={classes.note}
          variant="outlined"
          multiline
          rows={3}
          defaultValue={note}
          onChange={(event) => setNewNote(event.target.value)}
        />
        <Footer>
          <StyledButton
            className={classes.cancelBtn}
            variant="secondary"
            label="キャンセル"
            onClick={() => {
              if (onCancelClicked) {
                onCancelClicked();
              }
            }}
          />
          <StyledButton
            className={classes.updateBtn}
            variant="primary"
            label="更新"
            onClick={() => {
              if (onUpdateClicked) {
                onUpdateClicked({
                  newEndDate: infinity ? MAX_DATE_MARK : selectedEndDateMs,
                  note: newNote,
                });
              }
            }}
          />
        </Footer>
      </div>
    </StyledModal>
  );
};
