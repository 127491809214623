import React from 'react';
import {
  Box,
  ClickAwayListener,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  Theme,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { SimplePagination } from '@bizapp-frontend/management/atoms/SimplePagination';
import {
  FormInput,
  FormInputProps,
} from '@bizapp-frontend/management/molecules/form/FormInput';

export interface AddressType {
  zipcode: string;
  prefecture: string;
  address: string;
}

// code from JIS X 0401
export const prefectureMap = new Map<string, string>([
  ['01', '北海道'],
  ['02', '青森県'],
  ['03', '岩手県'],
  ['04', '宮城県'],
  ['05', '秋田県'],
  ['06', '山形県'],
  ['07', '福島県'],
  ['08', '茨城県'],
  ['09', '栃木県'],
  ['10', '群馬県'],
  ['11', '埼玉県'],
  ['12', '千葉県'],
  ['13', '東京都'],
  ['14', '神奈川県'],
  ['15', '新潟県'],
  ['16', '富山県'],
  ['17', '石川県'],
  ['18', '福井県'],
  ['19', '山梨県'],
  ['20', '長野県'],
  ['21', '岐阜県'],
  ['22', '静岡県'],
  ['23', '愛知県'],
  ['24', '三重県'],
  ['25', '滋賀県'],
  ['26', '京都府'],
  ['27', '大阪府'],
  ['28', '兵庫県'],
  ['29', '奈良県'],
  ['30', '和歌山県'],
  ['31', '鳥取県'],
  ['32', '島根県'],
  ['33', '岡山県'],
  ['34', '広島県'],
  ['35', '山口県'],
  ['36', '徳島県'],
  ['37', '香川県'],
  ['38', '愛媛県'],
  ['39', '高知県'],
  ['40', '福岡県'],
  ['41', '佐賀県'],
  ['42', '長崎県'],
  ['43', '熊本県'],
  ['44', '大分県'],
  ['45', '宮崎県'],
  ['46', '鹿児島県'],
  ['47', '沖縄県'],
]);

// Issue: remove it later
export const prefectureNameNameMap = new Map(
  Array.from(prefectureMap.entries())
    .sort((a1, a2) => {
      if (a1[0] === a2[0]) {
        return 0;
      }
      return a1[0] < a2[0] ? -1 : 1;
    })
    .map((v) => [v[1], v[1]]),
);

export const prefectureNameKeyMap = new Map(
  Array.from(prefectureMap.entries()).map((v) => [v[1], v[0]]),
);

type ZipcodeAPIResponse = {
  message: string;
  results: {
    zipcode: string;
    prefcode: string;
    address1: string;
    address2: string;
    address3: string;
    kana1: string;
    kana2: string;
    kana3: string;
  }[];
};

const convertionMap = new Map([
  ['ー', '-'],
  ['―', '-'],
  ['‐', '-'],
  ['‑', '-'],
  ['–', '-'],
  ['—', '-'],
  ['−', '-'],
  ['ｰ', '-'],
  ['－', '-'],
]);

const REGEXP_CONVERT = new RegExp(
  String.raw`(${Array.from(convertionMap.keys()).join('|')})`,
  'g',
);

const sanitizeQuery = (query: string) => {
  const converted = query
    .replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    })
    .replace(REGEXP_CONVERT, (match) => convertionMap.get(match) ?? match);

  if (converted.slice(3, 4) === '-') {
    return converted.replace('-', '');
  }
  return converted;
};

const REGEXP_ZIPCODE_QUERY = new RegExp(/^\d{3,7}$/);
const isValidQuery = (query: string) => {
  return query.match(REGEXP_ZIPCODE_QUERY) !== null;
};

const decodeResponse = (resp: ZipcodeAPIResponse) => {
  return resp.results
    .map((v) => {
      return {
        zipcode: v.zipcode.slice(0, 3) + '-' + v.zipcode.slice(3),
        prefecture: prefectureMap.get(v.prefcode) ?? '',
        address: v.address2 + v.address3,
      } as AddressType;
    })
    .sort((a1, a2) => {
      if (a1.zipcode === a2.zipcode) {
        return 0;
      }
      return a1.zipcode < a2.zipcode ? -1 : 1;
    });
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    zipcode: {},
    popper: {
      width: '100%',
      maxWidth: '343px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '400px',
      },
    },
    paper: {
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      boxShadow: '0px 4px 8px #00000040',
      borderRadius: '2px',
    },
    subtext: {
      padding: theme.spacing(1.5, 1),
      '& .MuiTypography-body1': {
        color: '#7F7F7F',
        fontSize: '14px',
        lineHeight: '21px',
      },
    },
    optionList: {
      paddingTop: '6px',
      paddingBottom: '6px',
    },
    option: {
      padding: theme.spacing(0.75, 1),
      '& .MuiListItemText-root': {
        margin: 0,
      },
      '& .MuiTypography-body1': {
        color: '#333333',
        fontSize: '14px',
        lineHeight: '21px',
      },
    },
    optionSelected: {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    pagination: {},
  }),
);

export interface FormZipcodeProps
  extends Omit<FormInputProps, 'value' | 'setValue'> {
  baseUrl?: string;
  value?: AddressType;
  setValue?: (
    value: AddressType | ((prevVar: AddressType) => AddressType),
  ) => void;
  postSelect?: (value: AddressType) => void;
}

export const FormZipcode: React.FC<FormZipcodeProps> = ({
  className = '',
  baseUrl,
  value,
  setValue,
  postSelect,
  onChange,
  ...props
}) => {
  const classes = useStyles();

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [options, setOptions] = React.useState<AddressType[]>([]);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = React.useState(3);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const optionPerPage = 7;

  const handleFocusZipcode = (
    ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setAnchorEl(ev.currentTarget);
    setOpen(true);
  };

  const handleClickPopper = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleClickAway = (ev: React.MouseEvent<EventTarget>) => {
    if (
      inputRef.current &&
      inputRef.current.contains(ev.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleKeyDown = (
    ev: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    switch (ev.key) {
      case 'Tab':
        setOpen(false);
        break;
      case 'ArrowUp':
        const newIdxUp = selectedIndex > 0 ? selectedIndex - 1 : 0;
        setSelectedIndex(newIdxUp);
        setPage(Math.floor(newIdxUp / optionPerPage) + 1);
        break;
      case 'ArrowDown':
        const maxIdx = options.length - 1;
        const newIdxDown = selectedIndex < maxIdx ? selectedIndex + 1 : maxIdx;
        setSelectedIndex(newIdxDown);
        setPage(Math.floor(newIdxDown / optionPerPage) + 1);
        break;
      case 'Enter':
        handleSelect(options[selectedIndex]);
        break;
      default:
    }
  };

  const handlePageChange = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number,
  ) => {
    setSelectedIndex((page - 1) * optionPerPage);
    setPage(page);
  };

  const handleChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange && onChange(ev);
    setValue &&
      setValue({
        zipcode: ev.target.value,
        prefecture: value?.prefecture ?? '',
        address: value?.address ?? '',
      });
  };

  const handleSelect = (v: AddressType) => {
    setValue && setValue(v);
    setOpen(false);
    postSelect && postSelect(v);
  };

  React.useEffect(() => {
    let active = true;

    (async () => {
      let addrs = [] as AddressType[];

      const query = sanitizeQuery(value?.zipcode ?? '');
      if (isValidQuery(query)) {
        try {
          const response = await fetch(
            `${baseUrl}/api/zipcode/search?zipcode=${query}`,
            {
              method: 'GET',
            },
          );
          addrs = decodeResponse(await response.json());
        } catch {
          addrs = [];
        }
      }

      if (active) {
        setOptions(addrs);
      }
    })();

    return () => {
      active = false;
    };
  }, [value, baseUrl]);

  React.useEffect(() => {
    setCount(Math.ceil(options.length / optionPerPage) || 1);
    setPage(1);
    setSelectedIndex(0);
  }, [options]);

  const toOptionString = (addr: AddressType) => {
    return addr ? `${addr.zipcode}　${addr.prefecture}${addr.address}` : '';
  };

  return (
    <>
      <FormInput
        {...props}
        value={value?.zipcode || ''}
        inputRef={inputRef}
        onChange={handleChange}
        onFocus={handleFocusZipcode}
        onKeyDown={handleKeyDown}
        helperText="郵便番号を入力してください。"
        className={`${classes.zipcode} ${className}`}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        className={classes.popper}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper className={classes.paper}>
            {options.length === 0 && (
              <Box onClick={handleClickPopper} className={classes.subtext}>
                <Typography>
                  {sanitizeQuery(value?.zipcode ?? '').length < 3
                    ? '3桁以上の郵便番号を入力してください'
                    : '対応する住所が存在しません'}
                </Typography>
              </Box>
            )}
            {options.length > 0 && (
              <List className={classes.optionList}>
                {options
                  .slice((page - 1) * optionPerPage, page * optionPerPage)
                  .map((v: AddressType, i) => (
                    <ListItem
                      button
                      onClick={() => handleSelect(v)}
                      tabIndex="-1"
                      key={i}
                      className={`${classes.option} ${
                        i === selectedIndex % optionPerPage
                          ? classes.optionSelected
                          : ''
                      }`}
                    >
                      <ListItemText>{toOptionString(v)}</ListItemText>
                    </ListItem>
                  ))}
              </List>
            )}
            <Divider />
            <SimplePagination
              count={count}
              page={page}
              onChange={handlePageChange}
              onClick={handleClickPopper}
              tabIndex="-1"
              className={classes.pagination}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
