import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: true;
    tabletEx: true;
    laptop: true;
  }
}

interface ThemeColor {
  main: string;
  gradationBegin: string;
  gradationEnd: string;
}
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    themeColor: ThemeColor;
  }
  interface PaletteOptions {
    themeColor: ThemeColor;
  }
}

const defaultTheme = createMuiTheme();
export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      mobile: 375,
      tablet: 768,
      tabletEx: 1024,
      laptop: 1366,
    },
  },
  palette: {
    themeColor: {
      main: '#4E7FF2',
      gradationBegin: '#6A8AFF',
      gradationEnd: '#6AA4FF',
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    h1: {
      color: '#333333',
      fontSize: '28px',
      lineHeight: '42px',
      fontWeight: 'bold',
      letterSpacing: 0,
      [defaultTheme.breakpoints.up(768)]: {
        fontSize: '32px',
        lineHeight: '48px',
      },
    },
    h2: {
      color: '#333333',
      fontSize: '24px',
      lineHeight: '36px',
      fontWeight: 'bold',
      letterSpacing: 0,
      [defaultTheme.breakpoints.up(768)]: {
        fontSize: '28px',
        lineHeight: '42px',
      },
    },
    h3: {
      color: '#333333',
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 'bold',
      letterSpacing: 0,
      [defaultTheme.breakpoints.up(768)]: {
        fontSize: '24px',
        lineHeight: '36px',
      },
    },
    h4: {
      color: '#333333',
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    h5: {
      color: '#333333',
      fontSize: '17px',
      lineHeight: '25px',
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    h6: {
      color: '#333333',
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    subtitle1: {
      color: '#333333',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '21px',
      letterSpacing: 0,
    },
    subtitle2: {
      color: '#333333',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '21px',
      letterSpacing: 0,
    },
    body1: {
      color: '#333333',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '21px',
      letterSpacing: 0,
    },
    body2: {
      color: '#333333',
      fontSize: '14px',
      fontWeight: 'normal',
      lineHeight: '21px',
      letterSpacing: 0,
    },
    button: {
      color: '#333333',
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    caption: {
      color: '#333333',
      letterSpacing: 0,
    },
    overline: {
      color: '#333333',
      letterSpacing: 0,
    },
  },
});
