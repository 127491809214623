import React from 'react';

import {
  CustomerList,
  Customer,
} from '@bizapp-frontend/management/organisms/CustomerList';
import { PageTemplate } from '@bizapp-frontend/management/templates/PageTemplate';

const HEADER = [
  '登録日時',
  '企業名',
  '担当者名',
  '担当者Email',
  '所属/役職',
  '顧客ID',
  'トライアル終了日',
];

export interface CustomerListTemplateProps {
  customers: Customer[];
  onCustomerClick: Function;
  onScrollEnd?: Function;
}

export const CustomerListTemplate: React.FC<CustomerListTemplateProps> = ({
  customers,
  onCustomerClick,
  onScrollEnd,
}) => {
  const handleOnRowClick = (index: number) => {
    // donot trigger click event when user selects some text
    if (window.getSelection()?.toString().length !== 0) {
      return;
    }
    onCustomerClick(index);
  };

  React.useEffect(() => {
    const handleScroll = () => {
      const bottom =
        Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;

      if (bottom && onScrollEnd) {
        onScrollEnd();
      }
    };

    window.addEventListener('scroll', handleScroll, {
      passive: true,
    });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [onScrollEnd]);

  return (
    <PageTemplate>
      <CustomerList
        headers={HEADER}
        customers={customers}
        onRowClick={handleOnRowClick}
      />
    </PageTemplate>
  );
};
