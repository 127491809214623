import React from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import {
  Application,
  CustomerDetailApplicationTemplate,
} from '@bizapp-frontend/management/templates/CustomerDetailApplicationTemplate';

import {
  ApplicationDialogMode,
  ApplicationModal,
} from '@bizapp-frontend/management/templates/form/ApplicationModal';
import {
  BillKindName,
  byteToGB,
  Contract,
  isStandardPlan,
  lastDayOfMonthMs,
  minus1Second,
  NanoFactor,
  planId2ServiceId,
  Plans,
  PlanType,
  ServiceName,
  standardPlanIdsOf,
  startOfMonth,
  startOfNextMonth,
  startOfPrevMonth,
  thresholdOfCurrMonth,
  toNextDay,
  toPreviousDay,
  toThresholdDateMsOf,
} from '@bizapp-frontend/management/templates/form/utils';
import { CustomerDetailContext } from '@bizapp-frontend/management/pages/CustomerDetailPage';
import {
  filter,
  flatMap,
  flow,
  groupBy,
  last,
  map,
  mapValues,
  sortBy,
  values,
} from 'lodash/fp';
import { GlobalsContext, Role } from '@bizapp-frontend/management/globals';
import _ from 'lodash';

interface PaymentMethodResp {
  last4: string;
  exp_year: number;
  exp_month: number;
  funding: string;
  country: string;
  brand: string;
  payment_customer_id: string;
}

export interface BillingInfo {
  paymentGatewayId?: string;
  stripeCard?: string;
  mfkCustomerUrl?: string;
  transaction?: string;
  billing?: string;
  applications?: string;
}

interface MFKCustomer {
  id: string;
  number: string;
}

interface MFKTransaction {
  id: string;
  created_at: string;
}

interface MFKTransactionResp {
  items: MFKTransaction[];
}

interface MFKBill {
  id: string;
  issue_date: string;
}

interface MFKBillResp {
  items: MFKBill[];
}

export interface CustomerDetailApplicationPageProps {
  contractBaseUrl: string;
  applicationControllerBaseUrl: string;
  paymentGatewayAPIBaseUrl: string;
  mfkBaseUrl: string;
  feeCalculatorAPIBaseUrl: string;
}

export const CustomerDetailApplicationPage: React.FC<CustomerDetailApplicationPageProps> = ({
  contractBaseUrl,
  applicationControllerBaseUrl,
  paymentGatewayAPIBaseUrl,
  feeCalculatorAPIBaseUrl,
  mfkBaseUrl,
}) => {
  const { state } = React.useContext(GlobalsContext);
  const now = React.useCallback(() => {
    let ms = Date.now();
    if (state.development && state.developmentMeta.currentTimeMs) {
      ms = state.developmentMeta.currentTimeMs;
    }
    return ms;
  }, [state.development, state.developmentMeta.currentTimeMs]);

  const role: Role = state.role;
  const createContractPermission = role === 'bizapp-management-full-access';
  const deleteContractPermission = role === 'bizapp-management-full-access';
  const updateContractPermission = role === 'bizapp-management-full-access';
  const modifyContractPermission = role === 'bizapp-management-full-access';
  const messageBarPermission = role === 'bizapp-management-full-access';

  const { customerId } = useParams();
  const [applications, setApplications] = React.useState<Application[]>([]);

  const [applicationModalOpen, setApplicationModalOpen] = React.useState(false);

  const [currContract, setCurrContract] = React.useState<Contract | undefined>(
    undefined,
  );

  const [dialogMode, setDialogMode] = React.useState<ApplicationDialogMode>(
    'create',
  );
  const handleCreateClick = () => {
    setDialogMode('create');
    setApplicationModalOpen(true);
    setCurrContract(undefined);
  };
  const handleModalClose = () => {
    setDialogMode('create');
    setApplicationModalOpen(false);
    setCurrContract(undefined);
  };

  const {
    customerDetailState: { contracts },
  } = React.useContext(CustomerDetailContext);

  const openContract = React.useCallback((contract: Contract) => {
    setCurrContract(contract);
    setDialogMode('view');
    setApplicationModalOpen(true);
  }, []);

  const [notShownContracts, setNotShownContracts] = React.useState<Contract[]>(
    [],
  );
  const handleApplicationClick = React.useCallback(
    (application: Application) => {
      const contract = contracts.find(
        (contract) => contract.contractId === application.contractId,
      );
      if (contract) {
        openContract({
          ...contract,
          readOnlyContract: application.readOnlyContract,
          baseContractId: application.baseContractId,
          updatedContractId: application.updatedContractId,
          originalContractStartDate: application.originalContractStartDate,
          isOriginalContract: application.isOriginalContract,
          hasOptionPlan: application.hasOptionPlan,
        });
      }
    },
    [contracts, openContract],
  );

  React.useEffect(() => {
    const filterContractsAccordingToIssueDate = (contract: Contract) => {
      const nowMs = now();
      const displayThreshold = thresholdOfCurrMonth(nowMs);

      if (nowMs < displayThreshold) {
        // previous month contracts
        return (
          contract.endDate >= startOfPrevMonth(nowMs) * 1000000 &&
          contract.calculationStartDate < startOfMonth(nowMs) * 1000000
        );
      } else {
        // current month contracts
        return (
          contract.endDate >= startOfMonth(nowMs) * 1000000 &&
          contract.calculationStartDate < startOfNextMonth(nowMs) * 1000000
        );
      }
    };

    // serviceId -> has any option plans
    const serviceHasOptionPlan = flow(
      groupBy((it: Contract) => it.serviceId),
      // has option plan
      mapValues((contracts) =>
        contracts.some((it) => !isStandardPlan(it.planId)),
      ),
    )(contracts);

    setApplications(
      flow(
        // only show purchased contract
        filter((contract: Contract) => contract.usageKind === 'purchased'),
        filter((contract: Contract) => {
          // hide oneshot plan after issueDate
          const plan = Plans.get(contract.planId);
          const threshold = toNextDay(
            toThresholdDateMsOf(contract.calculationStartDate / 1000000),
          );
          return (
            plan != null && (plan.billKind !== 'oneshot' || now() < threshold)
          );
        }),
        map((contract: Contract) => {
          return {
            ...contract,
            hasOptionPlan: serviceHasOptionPlan[contract.serviceId],
          };
        }),
        groupBy('planId'),
        values,
        flatMap((contractsOfPlan) => {
          const planId = contractsOfPlan[0].planId;
          const plan = Plans.get(planId);

          if (plan == null) {
            console.error('failed to get plan with id', planId);
          } else {
            const dealWithUpdateContracts = () => {
              if (
                plan.billKind === 'monthly' &&
                plan.billingBehavior === 'none'
              ) {
                // for non-daily-basis only show the updated one
                return flow(
                  sortBy((contract: Contract) => contract.timestamp),
                  (contracts) => {
                    const firstContract = contracts[0];
                    return contracts.map((contract) => {
                      return {
                        ...contract,
                        isOriginalContract: firstContract === contract,
                        originalContractStartDate: firstContract.startDate,
                      };
                    });
                  },
                  last,
                )(contractsOfPlan);
              } else if (
                plan.billKind === 'monthly' &&
                plan.billingBehavior === 'daily-basis'
              ) {
                // showing 2 contracts before/on the issue date
                // for the contract has been updated

                return flow(
                  sortBy((contract: Contract) => contract.timestamp),
                  (contracts) => {
                    const firstContract = contracts[0];
                    return contracts.map((contract, index, contracts) => {
                      const prevContract = contracts[index - 1];
                      const nextContract = contracts[index + 1];

                      let endDate;
                      if (nextContract) {
                        if (plan.billingBehavior === 'daily-basis') {
                          if (
                            contract.discountInfo &&
                            (contract.discountInfo?.kind !==
                              nextContract.discountInfo?.kind ||
                              contract.discountInfo?.unitPrice !==
                                nextContract.discountInfo?.unitPrice ||
                              contract.discountInfo?.price !==
                                nextContract.discountInfo?.price ||
                              contract.discountInfo?.amount !==
                                nextContract.discountInfo?.amount ||
                              contract.discountInfo?.rate !==
                                nextContract.discountInfo?.rate)
                          ) {
                            endDate = minus1Second(
                              nextContract.calculationStartDate,
                            );
                          } else {
                            // if (contract.volume !== nextContract.volume)
                            // or price and volume both not changed
                            endDate =
                              lastDayOfMonthMs(
                                toPreviousDay(
                                  nextContract.calculationStartDate /
                                    NanoFactor,
                                ),
                              ) * NanoFactor;
                          }
                        }
                      } else {
                        endDate = contract.endDate;
                      }

                      return {
                        ...contract,
                        endDate: endDate,
                        isOriginalContract: firstContract === contract,
                        originalContractStartDate: firstContract.startDate,
                        readOnlyContract: !!nextContract,
                        baseContractId: prevContract && prevContract.contractId,
                        updatedContractId:
                          nextContract && nextContract.contractId,
                      };
                    });
                  },
                  filter((contract: Contract) => {
                    const shown = filterContractsAccordingToIssueDate(contract);
                    if (!shown) {
                      setNotShownContracts((prev) => {
                        return [...prev, contract];
                      });
                    }
                    return shown;
                  }),
                  (contracts) => {
                    return contracts.map((contract, index, contracts) => {
                      const prevContract = contracts[index - 1];
                      let volume, usageVolume;
                      if (prevContract) {
                        if (plan.billingBehavior === 'daily-basis') {
                          if (
                            contract.discountInfo &&
                            (contract.discountInfo?.kind !==
                              prevContract.discountInfo?.kind ||
                              contract.discountInfo?.unitPrice !==
                                prevContract.discountInfo?.unitPrice ||
                              contract.discountInfo?.price !==
                                prevContract.discountInfo?.price ||
                              contract.discountInfo?.amount !==
                                prevContract.discountInfo?.amount ||
                              contract.discountInfo?.rate !==
                                prevContract.discountInfo?.rate)
                          ) {
                            volume = contract.volume;
                            usageVolume = contract.usageVolume;
                          } else {
                            // if (contract.volume !== prevContract.volume)
                            // or price and volume both not changed
                            volume = prevContract
                              ? (contract.volume ?? 0) -
                                (prevContract.volume ?? 0)
                              : contract.volume;
                            usageVolume =
                              contract.usageVolume && prevContract
                                ? (contract.usageVolume ?? 0) -
                                  (prevContract.usageVolume ?? 0)
                                : contract.usageVolume;
                          }
                        }
                      } else {
                        volume = contract.volume;
                        usageVolume = contract.usageVolume;
                      }

                      return {
                        ...contract,
                        volume: volume,
                        usageVolume: usageVolume,
                      };
                    });
                  },
                )(contractsOfPlan);
              } else {
                console.error('not supported plan', plan);
                return [];
              }
            };

            const contractsWithRelationshipInfo = contractsOfPlan.map(
              (contract: Contract) => {
                return {
                  ...contract,
                  isOriginalContract: true,
                  originalContractStartDate: contract.startDate,
                };
              },
            );
            if (plan.billKind === 'oneshot') {
              return contractsWithRelationshipInfo;
            } else if (isStandardPlan(plan.planId)) {
              return dealWithUpdateContracts();
            } else if (plan.contractKind === 'specified') {
              return contractsWithRelationshipInfo;
            } else {
              return dealWithUpdateContracts();
            }
          }
        }),
        filter(filterContractsAccordingToIssueDate),
        sortBy([
          'serviceId',
          (contract: Contract) => {
            if (isStandardPlan(contract.planId)) {
              return 0;
            } else return 1;
          },
        ]),
        map((contract: Contract) => {
          const plan = Plans.get(contract.planId);
          const billKind = plan?.billKind;

          let unitPrice;
          let volume;

          if (contract.contractKind === 'specified') {
            unitPrice = contract.price ?? 0;
            volume = '---';
          } else {
            switch (contract.discountInfo?.kind) {
              case 'none':
                unitPrice = plan?.unitPrice ?? 0;
                break;
              default:
                unitPrice = contract.discountInfo?.unitPrice ?? 0;
                break;
            }
            if (contract.contractKind === 'usage') {
              let _usageVolume = contract.usageVolume ?? 0;
              if (contract.planId === 'ebm-storage-usage-option') {
                _usageVolume = byteToGB(contract?.usageVolume ?? 0);
              }
              volume = `${contract.volume ?? 0}${
                plan?.listVolumeLabel
              }（${_usageVolume}${plan?.listUsageVolumeLabel}）`;
            } else {
              volume = `${contract.volume ?? 0}${plan?.listVolumeLabel}`;
            }
            if (contract.contractKind === 'constant') {
              volume = '---';
            }
          }

          return {
            contractId: contract.contractId,
            serviceId: contract.serviceId,
            serviceName: ServiceName.get(contract.serviceId),
            planId: contract.planId,
            planKind: plan?.planName,
            billKind: billKind && BillKindName.get(billKind),
            unitPrice: unitPrice,
            usage: volume,
            startDate: contract.calculationStartDate,
            endDate: contract.endDate,
            readOnlyContract: !!contract.readOnlyContract,
            originalContractStartDate: contract.originalContractStartDate,
            baseContractId: contract.baseContractId,
            updatedContractId: contract.updatedContractId,
            isOriginalContract: contract.isOriginalContract,
            hasOptionPlan: contract.hasOptionPlan,
          } as Application;
        }),
      )(contracts),
    );
  }, [contracts, customerId, now]);

  const [paymentGatewayId, setPaymentGatewayId] = React.useState('');
  const [billingInfo, setBillingInfo] = React.useState<BillingInfo>({
    stripeCard: '---',
  });

  const { dispatch: dispatchCustomerDetail } = React.useContext(
    CustomerDetailContext,
  );
  const { getAccessTokenSilently } = useAuth0();

  React.useEffect(() => {
    if (customerId) {
      const getPaymentMethod = async () => {
        dispatchCustomerDetail({
          type: 'SET_PROCESSING_DIALOG',
          processingDialogState: 'wait',
        });
        const accessToken = await getAccessTokenSilently();

        // const tenantResp = await fetch(
        //   `${tenantManagementAPIBaseUrl}/api/tenant-management/customers/${customerId}`,
        //   {
        //     method: 'GET',
        //     headers: {
        //       'Content-Type': 'application/json',
        //       Authorization: `Bearer ${accessToken}`,
        //     },
        //   },
        // );
        // const tenantJson = await tenantResp.json();

        const paymentKindResp = await fetch(
          `${contractBaseUrl}/api/contract/payment-kind/customers/${customerId}/latest`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (paymentKindResp.ok) {
          return Promise.resolve(paymentKindResp.json());
        } else {
          return Promise.resolve(undefined);
        }
      };
      const f = async () => {
        let _paymentGatewayId;
        try {
          const paymentKindJson = await getPaymentMethod();
          _paymentGatewayId = paymentKindJson.paymentGatewayId;
        } catch {
          // for case customer only have trial contract
          // api returns 404
          _paymentGatewayId = '';
        } finally {
          setPaymentGatewayId(_paymentGatewayId);
          dispatchCustomerDetail({
            type: 'SET_PROCESSING_DIALOG',
            processingDialogState: 'close',
          });
        }
      };
      f();
    }
  }, [
    contractBaseUrl,
    customerId,
    dispatchCustomerDetail,
    getAccessTokenSilently,
  ]);

  React.useEffect(() => {
    if (customerId && paymentGatewayId) {
      const getCardInfoFromStripe = async () => {
        const accessToken = await getAccessTokenSilently();
        const paymentResp = await fetch(
          `${paymentGatewayAPIBaseUrl}/api/payment-gateway/${paymentGatewayId}/customers/${customerId}/card`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (paymentResp.ok) {
          const paymentJson: PaymentMethodResp = await paymentResp.json();
          return {
            paymentGatewayId: paymentGatewayId,
            stripeCard: `クレジットカード（${paymentJson.brand} ****${paymentJson.last4}）`,
          } as BillingInfo;
        } else {
          return Promise.reject(new Error('failed to get payment method'));
        }
      };
      const getBillingInfoFromMFK = async () => {
        const accessToken = await getAccessTokenSilently();
        const resps = await Promise.all([
          fetch(
            `${paymentGatewayAPIBaseUrl}/api/payment-gateway/${paymentGatewayId}/customers/${customerId}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            },
          ),
          fetch(
            `${paymentGatewayAPIBaseUrl}/api/payment-gateway/${paymentGatewayId}/customers/${customerId}/transactions`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            },
          ),
          fetch(
            `${paymentGatewayAPIBaseUrl}/api/payment-gateway/${paymentGatewayId}/customers/${customerId}/billings`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            },
          ),
        ]);

        let mfkCustomerId = '',
          mfkCustomerNumber = '',
          mkfTransactionId = '',
          mfkBillingId = '';
        let customerResp: MFKCustomer;
        if (resps[0].status === 200) {
          customerResp = await resps[0].json();
          mfkCustomerId = customerResp.id;
          mfkCustomerNumber = customerResp.number;
        }
        if (resps[1].status === 200) {
          const transactionResp: MFKTransactionResp = await resps[1].json();
          const transaction =
            transactionResp.items.length > 0
              ? transactionResp.items[0]
              : undefined;
          mkfTransactionId = transaction?.id ?? '';
        }
        if (resps[2].status === 200) {
          const billingResp: MFKBillResp = await resps[2].json();
          const billing =
            billingResp.items?.length > 0 ? billingResp.items[0] : undefined;
          mfkBillingId = billing?.id ?? '';
        }

        return {
          paymentGatewayId: paymentGatewayId,
          mfkCustomerUrl:
            mfkCustomerId && `${mfkBaseUrl}/customers/${mfkCustomerId}`,
          transaction:
            mkfTransactionId &&
            `${mfkBaseUrl}/a/transactions/${mkfTransactionId}`,
          billing: mfkBillingId && `${mfkBaseUrl}/a/claimunits/${mfkBillingId}`,
          applications:
            mfkCustomerNumber &&
            `${mfkBaseUrl}/a/claimunits?searchText=${mfkCustomerNumber}`,
        } as BillingInfo;
      };
      const f = async () => {
        try {
          if (paymentGatewayId === 'stripe') {
            setBillingInfo(await getCardInfoFromStripe());
          } else if (paymentGatewayId === 'mfkessai') {
            setBillingInfo(await getBillingInfoFromMFK());
          }

          dispatchCustomerDetail({
            type: 'SET_PROCESSING_DIALOG',
            processingDialogState: 'close',
          });
        } catch {
          setBillingInfo({
            paymentGatewayId: paymentGatewayId,
            stripeCard: '---',
          });

          dispatchCustomerDetail({
            type: 'SET_PROCESSING_DIALOG',
            processingDialogState: 'error',
          });
        }
      };
      f();
    }
  }, [
    customerId,
    dispatchCustomerDetail,
    getAccessTokenSilently,
    mfkBaseUrl,
    now,
    paymentGatewayAPIBaseUrl,
    paymentGatewayId,
  ]);

  const availablePlanIds = React.useMemo(() => {
    const planIds = Array.from(Plans.keys());
    const existedPlanIds = applications
      .map((it) => it.planId)
      // for modify, filter out current plan id, since modify will remove current contract
      // for update, availablePlanIds is not used since planId cannot be changed
      .filter((it) => it !== currContract?.planId);
    return planIds.filter((planId) => {
      const plan = Plans.get(planId);
      if (!plan) return false;

      // serviceId -> std plan ids
      const serviceHasStandardPlan = flow(
        groupBy((it: PlanType) => planId2ServiceId(it)),
        // has option plan
        mapValues((planIds: PlanType[]) =>
          _.uniq(planIds.filter((it) => isStandardPlan(it))),
        ),
      )(existedPlanIds as PlanType[]);

      let canCreateMultiple;
      if (plan.billKind === 'oneshot') {
        canCreateMultiple = true;
      } else if (isStandardPlan(plan.planId)) {
        canCreateMultiple = false;
      } else if (plan.contractKind === 'specified') {
        canCreateMultiple = true;
      } else {
        canCreateMultiple = false;
      }

      const existedStdPlanIds =
        serviceHasStandardPlan[planId2ServiceId(planId)] ?? [];

      const canCreateStdPlan =
        existedStdPlanIds.length === 0 || existedStdPlanIds.includes(planId);

      return (
        // Check only create multiple plans is allowed
        (canCreateMultiple || !existedPlanIds.includes(planId)) &&
        // Check only one of std plan can be created
        (!isStandardPlan(planId) || canCreateStdPlan) &&
        // Restrict registration of option plans when standard plan of that service is not registered
        (isStandardPlan(planId) ||
          standardPlanIdsOf(planId).some((id) => existedPlanIds.includes(id)))
      );
    });
  }, [applications, currContract?.planId]);

  const handleShowContract = React.useCallback(
    (contractId: string) => {
      const _application = applications.find(
        (application) => application.contractId === contractId,
      );
      if (_application) {
        handleApplicationClick(_application);
      } else {
        const _contract = notShownContracts.find(
          (c) => c.contractId === contractId,
        );
        _contract && openContract(_contract);
      }
    },
    [applications, handleApplicationClick, notShownContracts, openContract],
  );

  return (
    <>
      <CustomerDetailApplicationTemplate
        applications={applications}
        onApplicationClick={handleApplicationClick}
        onCreateClick={handleCreateClick}
        billingInfo={billingInfo}
        createContractPermission={createContractPermission}
      />
      <ApplicationModal
        open={applicationModalOpen}
        onClose={handleModalClose}
        applicationControllerBaseUrl={applicationControllerBaseUrl}
        feeCalculatorAPIBaseUrl={feeCalculatorAPIBaseUrl}
        customerId={customerId ?? ''}
        contract={currContract}
        mode={dialogMode}
        paymentGatewayId={paymentGatewayId}
        availablePlanIds={availablePlanIds}
        onShowContract={handleShowContract}
        deletePermission={deleteContractPermission}
        modifyPermission={modifyContractPermission}
        updatePermission={updateContractPermission}
        messageBarPermission={messageBarPermission}
      />
    </>
  );
};
