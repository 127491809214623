import {
  addDays,
  addMonths,
  addSeconds,
  addYears,
  format,
  getTime,
  parse,
  setDate,
  startOfDay,
  toDate,
} from 'date-fns/fp';
import {
  formatInTimeZone,
  utcToZonedTime,
  zonedTimeToUtc,
} from 'date-fns-tz/fp';
import flow from 'lodash/fp/flow';
import _ from 'lodash';
import { groupBy, mapValues } from 'lodash/fp';

const JSTTimeZone = 'Asia/Tokyo';
export const NanoFactor = 1000000;
const JST_OFFSET = -9 * 60;

const toJSTWithSameLocalTime = (ms: number) => {
  return ms + (JST_OFFSET - new Date().getTimezoneOffset()) * 60 * 1000;
};

const toLocalWithSameLocalTime = (ms: number) => {
  return ms + (new Date().getTimezoneOffset() - JST_OFFSET) * 60 * 1000;
};

type getCalcDateFn = (contractDate: string) => string;

export const MAX_DATE_NS = 9214646399000000000;
export const MAX_DATE_MARK = -1;
export const MAX_DATE_STR = '2262-01-01'; // MAX_DATE
const maxDate: getCalcDateFn = () => {
  return MAX_DATE_STR;
};

const dateToString = format('yyyy-MM-dd');
const nextDay: getCalcDateFn = (contractDate) => {
  return flow(addDays(1), dateToString)(stringToDate(contractDate));
};
const sameDay: getCalcDateFn = (contractDate) => {
  return contractDate;
};
const lastDayOfMonth: getCalcDateFn = (contractDate) => {
  return flow(
    addMonths(1),
    setDate(1),
    addDays(-1),
    dateToString,
  )(stringToDate(contractDate));
};
export const lastDayOfMonthMs = (ms: number) => {
  return flow(
    utcToZonedTime(JSTTimeZone),
    addMonths(1),
    setDate(1),
    addSeconds(-1),
    zonedTimeToUtc(JSTTimeZone),
    getTime,
  )(ms);
};

export const minus1Second = (dateNanos: number) => {
  return dateNanos - 1000 * NanoFactor;
};
export const startOfMonth = (now: number) => {
  return flow(
    setDate(1),
    startOfDay,
    getTime,
    toJSTWithSameLocalTime,
  )(new Date(toLocalWithSameLocalTime(now)));
};

export const startOfPrevMonth = (now: number) => {
  return flow(
    addMonths(-1),
    setDate(1),
    startOfDay,
    getTime,
    toJSTWithSameLocalTime,
  )(new Date(toLocalWithSameLocalTime(now)));
};

export const startOfNextMonth = (now: number) => {
  return flow(
    addMonths(1),
    setDate(1),
    startOfDay,
    getTime,
    toJSTWithSameLocalTime,
  )(new Date(toLocalWithSameLocalTime(now)));
};

const ISSUE_DAY = 2;
const THRESHOLD_DAY = ISSUE_DAY + 1;
export const thresholdOfCurrMonth = (now: number) => {
  return flow(
    setDate(THRESHOLD_DAY),
    startOfDay,
    getTime,
    toJSTWithSameLocalTime,
  )(new Date(toLocalWithSameLocalTime(now)));
};

const ONE_DAY_MS = 24 * 60 * 60 * 1000;
export const toNextDay = (dateMs: number): number => {
  return dateMs + ONE_DAY_MS;
};

export const toPreviousDay = (dateMs: number): number => {
  return dateMs - ONE_DAY_MS;
};

export const toIssueDateMsOf = (dateMs: number): number => {
  return flow(
    setDate(ISSUE_DAY),
    addMonths(1),
    startOfDay,
    getTime,
    toJSTWithSameLocalTime,
  )(new Date(toLocalWithSameLocalTime(dateMs)));
};

export const toThresholdDateMsOf = (dateMs: number): number => {
  return toNextDay(toIssueDateMsOf(dateMs));
};

export type ServiceType =
  | 'pjb'
  | 'pjr'
  | 'ess'
  | 'ebm'
  | 'workflow'
  | 'workflow-internal'
  | 'chatbot'
  | 'digitalinvoice';
export type PlanType =
  | 'pjb-basic'
  | 'pjb-specified-option'
  | 'pjb-iprestriction-option'
  | 'pjr-basic'
  | 'pjr-specified-option'
  | 'ess-basic'
  | 'ess-business'
  | 'ess-specified-option'
  | 'ess-iprestriction-option'
  | 'ebm-basic'
  | 'ebm-storage-usage-option'
  | 'ebm-api-integration-option'
  | 'ebm-specified-option'
  | 'workflow-basic'
  | 'workflow-monthly-specified-option'
  | 'workflow-oneshot-specified-option'
  | 'chatbot-basic'
  | 'chatbot-initial-fee-option'
  | 'chatbot-faq-registration-option'
  | 'chatbot-call-usage-option'
  | 'chatbot-contents-addition-option'
  | 'chatbot-m17n-option'
  | 'chatbot-proxy-option'
  | 'chatbot-line-integration-option'
  | 'chatbot-teams-integration-option'
  | 'chatbot-conversation-api-option'
  | 'chatbot-saml-option'
  | 'chatbot-webmtg-support-option'
  | 'chatbot-monthly-specified-option'
  | 'chatbot-oneshot-specified-option'
  | 'digitalinvoice-basic'
  | 'digitalinvoice-oneshot-specified-option';
export type PriceType = 'normal-price' | 'special-price' | 'discount';
export type BillKind = 'monthly' | 'oneshot';
export type ContractKind = 'volume' | 'specified' | 'constant' | 'usage';
export type CalculationType = 'pro-rata-basis' | 'normal' | 'specified';
export type BillingBehavior = 'daily-basis' | 'none';

export interface Plan {
  planId: PlanType;
  planName: string;
  unitPrice: number;
  billKind: BillKind;
  contractKind: ContractKind;
  billingBehavior: BillingBehavior;
  volumeLabel?: string;
  usageVolumeLabel?: string;
  usageVolumeSubLabel?: string;
  specifiedDescription?: string;
  listVolumeLabel?: string;
  listUsageVolumeLabel?: string;
  defaultCalcStartDataFn: getCalcDateFn;
  defaultCalcEndDateFn: getCalcDateFn;
}

export type DiscountKind = 'none' | 'fixed' | 'rate' | 'specified';

export interface DiscountInfo {
  kind: DiscountKind;
  unitPrice?: number | null;
  amount: number;
  rate: number;
  price: number;
}

export interface Contract {
  applicationId: string;

  contractId: string;
  contractKind: ContractKind;
  usageKind: 'purchased' | 'trial' | 'freemium';

  serviceId: ServiceType;
  planId: PlanType;

  tenantId: string;
  customerId: string;

  startDate: number;
  availableDate: number;
  calculationStartDate: number;
  endDate: number;

  note: string;

  discountInfo?: DiscountInfo;
  volume?: number | null;
  usageVolume?: number | null;
  price?: number | null;
  description?: string;

  timestamp: number;

  readOnlyContract?: boolean;
  baseContractId?: string;
  updatedContractId?: string;
  originalContractStartDate?: number;
  isOriginalContract: boolean;
  hasOptionPlan: boolean;
}

export interface DetailApplicationContract {
  customerId: string;
  serviceId: string;
  planId: string;
  contractDateMs: number;
  calculationStartDateMs: number;
  availableDateMs: number;
  calculationEndDateMs: number;
  priceType: PriceType;
  price?: number | null;
  specialPrice?: number | null;
  discount?: number;
  volume: number;
  usageVolume?: number;
  note?: string;
  specifiedPrice?: number;

  specifiedContractDescription?: string;
}

export const ServiceName = new Map<ServiceType, string>([
  ['pjb', 'Project Board'],
  ['pjr', 'Project Room'],
  ['ess', 'ESS'],
  ['ebm', 'EBM'],
  ['workflow', 'Workflow'],
  ['workflow-internal', 'Workflow（Test）'],
  ['chatbot', 'Chatbot'],
  ['digitalinvoice', 'HUEデジタルインボイス'],
]);

const planList: Plan[] = [
  {
    planId: 'pjb-basic',
    planName: 'Basicプラン（月額）',
    unitPrice: 960,
    billKind: 'monthly',
    contractKind: 'volume',
    billingBehavior: 'daily-basis',
    volumeLabel: 'ライセンス数',
    listVolumeLabel: 'ライセンス',
    defaultCalcStartDataFn: nextDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'pjb-specified-option',
    planName: 'その他（単発）',
    unitPrice: 0,
    billKind: 'oneshot',
    contractKind: 'specified',
    billingBehavior: 'none',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: lastDayOfMonth,
  },
  {
    planId: 'pjb-iprestriction-option',
    planName: '接続IP制御（単発）',
    unitPrice: 10000,
    billKind: 'oneshot',
    contractKind: 'usage',
    billingBehavior: 'none',
    volumeLabel: '請求本数',
    usageVolumeLabel: '作業数',
    listVolumeLabel: '本',
    listUsageVolumeLabel: '作業',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: lastDayOfMonth,
  },
  {
    planId: 'pjr-basic',
    planName: 'Basicプラン（月額）',
    unitPrice: 1300,
    billKind: 'monthly',
    contractKind: 'volume',
    billingBehavior: 'daily-basis',
    volumeLabel: 'ライセンス数',
    listVolumeLabel: 'ライセンス',
    defaultCalcStartDataFn: nextDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'pjr-specified-option',
    planName: 'その他（単発）',
    unitPrice: 0,
    billKind: 'oneshot',
    contractKind: 'specified',
    billingBehavior: 'none',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: lastDayOfMonth,
  },
  {
    planId: 'ess-basic',
    planName: 'Basicプラン（月額）',
    unitPrice: 300,
    billKind: 'monthly',
    contractKind: 'volume',
    billingBehavior: 'daily-basis',
    volumeLabel: 'ライセンス数',
    listVolumeLabel: 'ライセンス',
    defaultCalcStartDataFn: nextDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'ess-business',
    planName: 'Businessプラン（月額）',
    unitPrice: 500,
    billKind: 'monthly',
    contractKind: 'volume',
    billingBehavior: 'daily-basis',
    volumeLabel: 'ライセンス数',
    listVolumeLabel: 'ライセンス',
    defaultCalcStartDataFn: nextDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'ess-specified-option',
    planName: 'その他（単発）',
    unitPrice: 0,
    billKind: 'oneshot',
    contractKind: 'specified',
    billingBehavior: 'none',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: lastDayOfMonth,
  },
  {
    planId: 'ess-iprestriction-option',
    planName: '接続IP制御（単発）',
    unitPrice: 10000,
    billKind: 'oneshot',
    contractKind: 'usage',
    billingBehavior: 'none',
    volumeLabel: '請求本数',
    usageVolumeLabel: '作業数',
    listVolumeLabel: '本',
    listUsageVolumeLabel: '作業',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: lastDayOfMonth,
  },
  {
    planId: 'ebm-basic',
    planName: 'Basicプラン（月額）',
    unitPrice: 30000,
    billKind: 'monthly',
    contractKind: 'constant',
    billingBehavior: 'daily-basis',
    volumeLabel: 'ライセンス数',
    listVolumeLabel: 'ライセンス',
    defaultCalcStartDataFn: nextDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'ebm-storage-usage-option',
    planName: 'ファイル管理容量課金（月額）',
    unitPrice: 5000,
    billKind: 'monthly',
    contractKind: 'usage',
    billingBehavior: 'none',
    volumeLabel: '請求本数',
    usageVolumeLabel: 'ファイル管理容量（Byte）',
    usageVolumeSubLabel: 'ファイル管理容量（GB）',
    listVolumeLabel: '本',
    listUsageVolumeLabel: 'GB',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'ebm-api-integration-option',
    planName: 'API連携システム追加（月額）',
    unitPrice: 50000,
    billKind: 'monthly',
    contractKind: 'usage',
    billingBehavior: 'none',
    volumeLabel: '請求本数',
    usageVolumeLabel: 'システム数',
    listVolumeLabel: '本',
    listUsageVolumeLabel: 'システム',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'ebm-specified-option',
    planName: 'その他（単発）',
    unitPrice: 0,
    billKind: 'oneshot',
    contractKind: 'specified',
    billingBehavior: 'none',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: lastDayOfMonth,
  },
  {
    planId: 'workflow-basic',
    planName: 'Basicプラン（月額）',
    unitPrice: 300,
    billKind: 'monthly',
    contractKind: 'volume',
    billingBehavior: 'daily-basis',
    volumeLabel: 'ライセンス数',
    listVolumeLabel: 'ライセンス',
    defaultCalcStartDataFn: nextDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'workflow-monthly-specified-option',
    planName: 'その他（月額）',
    unitPrice: 0,
    billKind: 'monthly',
    contractKind: 'specified',
    billingBehavior: 'none',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'workflow-oneshot-specified-option',
    planName: 'その他（単発）',
    unitPrice: 0,
    billKind: 'oneshot',
    contractKind: 'specified',
    billingBehavior: 'none',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: lastDayOfMonth,
  },
  {
    planId: 'chatbot-basic',
    planName: 'Basicプラン（月額）',
    unitPrice: 150000,
    billKind: 'monthly',
    contractKind: 'constant',
    billingBehavior: 'daily-basis',
    volumeLabel: 'ライセンス数',
    defaultCalcStartDataFn: nextDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'chatbot-initial-fee-option',
    planName: '初期構築費用（単発）',
    unitPrice: 500000,
    billKind: 'oneshot',
    contractKind: 'constant',
    billingBehavior: 'none',
    volumeLabel: 'ライセンス数',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: lastDayOfMonth,
  },
  {
    planId: 'chatbot-faq-registration-option',
    planName: 'FAQ登録数追加サービス（単発）',
    unitPrice: 0,
    billKind: 'oneshot',
    contractKind: 'specified',
    billingBehavior: 'none',
    specifiedDescription: 'FAQ登録数追加サービス',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: lastDayOfMonth,
  },
  {
    planId: 'chatbot-call-usage-option',
    planName: '月間問い合わせ数（月額）',
    unitPrice: 10000,
    billKind: 'monthly',
    contractKind: 'usage',
    billingBehavior: 'none',
    volumeLabel: '請求本数',
    usageVolumeLabel: '問い合わせ数',
    listVolumeLabel: '本',
    listUsageVolumeLabel: '問い合わせ',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'chatbot-contents-addition-option',
    planName: 'コンテンツ追加（月額）',
    unitPrice: 10000,
    billKind: 'monthly',
    contractKind: 'usage',
    billingBehavior: 'daily-basis',
    volumeLabel: '請求本数',
    usageVolumeLabel: '追加数',
    listVolumeLabel: '本',
    listUsageVolumeLabel: 'コンテンツ',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'chatbot-m17n-option',
    planName: '多言語対応（月額）',
    unitPrice: 10000,
    billKind: 'monthly',
    contractKind: 'volume',
    billingBehavior: 'daily-basis',
    volumeLabel: '対応言語数',
    listVolumeLabel: '言語',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'chatbot-proxy-option',
    planName: 'プロキシ構築（月額）',
    unitPrice: 10000,
    billKind: 'monthly',
    contractKind: 'constant',
    billingBehavior: 'daily-basis',
    volumeLabel: 'ライセンス数',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'chatbot-line-integration-option',
    planName: 'LINE連携（月額）',
    unitPrice: 20000,
    billKind: 'monthly',
    contractKind: 'constant',
    billingBehavior: 'daily-basis',
    volumeLabel: 'ライセンス数',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'chatbot-teams-integration-option',
    planName: 'Microsoft Teams連携（月額）',
    unitPrice: 20000,
    billKind: 'monthly',
    contractKind: 'constant',
    billingBehavior: 'daily-basis',
    volumeLabel: 'ライセンス数',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'chatbot-conversation-api-option',
    planName: '対話API提供（月額）',
    unitPrice: 10000,
    billKind: 'monthly',
    contractKind: 'constant',
    billingBehavior: 'daily-basis',
    volumeLabel: 'ライセンス数',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'chatbot-saml-option',
    planName: 'SAML認証対応（月額）',
    unitPrice: 20000,
    billKind: 'monthly',
    contractKind: 'constant',
    billingBehavior: 'daily-basis',
    volumeLabel: 'ライセンス数',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'chatbot-webmtg-support-option',
    planName: 'Web会議による対面サポート（単発）',
    unitPrice: 60000,
    billKind: 'oneshot',
    contractKind: 'volume',
    billingBehavior: 'none',
    volumeLabel: '利用数',
    listVolumeLabel: '式',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: lastDayOfMonth,
  },
  {
    planId: 'chatbot-monthly-specified-option',
    planName: 'その他（月額）',
    unitPrice: 0,
    billKind: 'monthly',
    contractKind: 'specified',
    billingBehavior: 'daily-basis',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'chatbot-oneshot-specified-option',
    planName: 'その他（単発）',
    unitPrice: 0,
    billKind: 'oneshot',
    contractKind: 'specified',
    billingBehavior: 'none',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: lastDayOfMonth,
  },
  {
    planId: 'digitalinvoice-basic',
    planName: 'Basicプラン（月額）',
    unitPrice: 19000,
    billKind: 'monthly',
    contractKind: 'constant',
    billingBehavior: 'none',
    volumeLabel: 'ライセンス数',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: maxDate,
  },
  {
    planId: 'digitalinvoice-oneshot-specified-option',
    planName: 'その他（単発）',
    unitPrice: 0,
    billKind: 'oneshot',
    contractKind: 'specified',
    billingBehavior: 'none',
    defaultCalcStartDataFn: sameDay,
    defaultCalcEndDateFn: lastDayOfMonth,
  },
];

export const Plans = new Map<PlanType, Plan>(
  planList.map((plan) => {
    return [plan.planId, plan];
  }),
);
export const planId2ServiceId = (planId: PlanType): ServiceType => {
  return planId.split('-')[0] as ServiceType;
};
const servicePlanEntries = flow(
  groupBy((it: Plan): ServiceType => planId2ServiceId(it.planId)),
  mapValues((plans: Plan[]) => {
    return new Map<PlanType, string>(
      plans.map((plan) => {
        return [plan.planId, plan.planName];
      }),
    );
  }),
  (m) =>
    Object.entries(m).map(
      ([serviceId, plans]) =>
        [serviceId, plans] as [ServiceType, Map<PlanType, string>],
    ),
)(planList);

export const ServicePlan = new Map<ServiceType, Map<PlanType, string>>(
  servicePlanEntries,
);

export const BillKindName: Map<BillKind, string> = new Map([
  ['monthly', '月額'],
  ['oneshot', '単発'],
]);

export const initContract = (
  customerId: string,
  serviceId: string,
  planId: string,
): DetailApplicationContract => {
  return {
    customerId: customerId,
    serviceId: serviceId,
    planId: planId,
    contractDateMs: 0,
    calculationStartDateMs: 0,
    availableDateMs: 0,
    calculationEndDateMs: 0,
    priceType: 'normal-price',
    price: 0,
    volume: 0,
    note: '',
  };
};

// yyyy-MM-dd to local Date
const stringToDate = (dateStr: string, timezoneOffset?: number) => {
  // Support for ISO 8601 formats differs in that date-only strings
  // (e.g. "1970-01-01") are treated as UTC, not local.
  // ref: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
  return new Date(
    new Date(dateStr).getTime() +
      (timezoneOffset ?? new Date().getTimezoneOffset()) * 60 * 1000,
  );
};

export const dateStrToJSTMs = (dateStr: string) => {
  return stringToDate(dateStr, JST_OFFSET).getTime();
};

export const dateTimeStrToJstMs = (dateStr: string) => {
  return flow(
    parse(new Date(0), "yyyy-MM-dd'T'HH:mm"),
    getTime,
    toJSTWithSameLocalTime,
  )(dateStr);
};

export const addingEndDateSeconds = (ms: number) => {
  return ms + ((23 * 60 + 59) * 60 + 59) * 1000;
};

// const contractDefaultCalcStartDate = new Map<ContractKind, getCalcDateFn>([
//   ['volume', nextDay],
//   ['specified', sameDay],
//   ['constant', nextDay],
//   ['usage', sameDay],
// ]);

export const defaultCalculationStartDateOf = (
  planId: PlanType,
  contractDate: string,
): string => {
  const dataFn = Plans.get(planId)?.defaultCalcStartDataFn;
  return (dataFn && dataFn(contractDate)) ?? '';
};

// const contractDefaultCalcEndDate = new Map<ContractKind, getCalcDateFn>([
//   ['volume', maxDate],
//   ['specified', currMonthLastDay],
//   ['constant', maxDate],
//   ['usage', maxDate],
// ]);

export const defaultCalculationEndDateOf = (
  planId: PlanType,
  contractDate: string,
): string => {
  const dataFn = Plans.get(planId)?.defaultCalcEndDateFn;
  return (dataFn && dataFn(contractDate)) ?? '';
};

export const calculationTypeOf = (planId: PlanType): CalculationType => {
  const plan = Plans.get(planId);
  if (plan) {
    return plan.billingBehavior === 'daily-basis' ? 'pro-rata-basis' : 'normal';
  }
  return 'normal';
};

export const contractKindOf = (planId: PlanType): ContractKind | undefined => {
  return Plans.get(planId)?.contractKind;
};

export const billKindOf = (planId: PlanType): BillKind | undefined => {
  return Plans.get(planId)?.billKind;
};

// TODO: it should be calculated by API
export const BYTE_FACTOR = 1024;
export const byteToGB = (byte?: number) => {
  if (byte) {
    return _.floor(byte / (BYTE_FACTOR * BYTE_FACTOR * BYTE_FACTOR));
  } else {
    return 0;
  }
};

export const isStandardPlan = (planId: PlanType) => !/-option$/.test(planId);

export const standardPlanIdsOf = (planId: PlanType): PlanType[] => {
  const serviceId = planId2ServiceId(planId);
  if (serviceId) {
    const planMap = ServicePlan.get(serviceId as ServiceType);
    if (planMap) {
      return Array.from(planMap.keys()).filter(isStandardPlan);
    }
  }
  return [];
};

export const getCurrMonthLastDay = (now: number) => {
  const d = new Date(now);
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setMilliseconds(0);
  return (
    toJSTWithSameLocalTime(
      flow(addMonths(1), setDate(1), addDays(-1), getTime)(d),
    ) * NanoFactor
  );
};

export const toEndOfMonthMs = (dateStr: string): number => {
  return toJSTWithSameLocalTime(
    flow(setDate(1), addMonths(1), addDays(-1), getTime)(stringToDate(dateStr)),
  );
};

export const toStartOfMonthMs = (dateStr: string): number => {
  return toJSTWithSameLocalTime(
    flow(setDate(1), getTime)(stringToDate(dateStr)),
  );
};

export const toLastModifyDateStr = (dateMs: number): string => {
  return flow(
    toPreviousDay,
    toDate,
    formatInTimeZone('M/d', JSTTimeZone),
  )(dateMs);
};

// i.e. '2020-01-01'
export const getCurrMonthFirstDayJSTStr = (now: number) => {
  return flow(
    setDate(1),
    formatInTimeZone('yyyy-MM-dd', JSTTimeZone),
  )(new Date(now));
};

export const jstMsToLocalDateStr = (ms: number) => {
  return flow(formatInTimeZone('yyyy-MM-dd', JSTTimeZone))(new Date(ms));
};

export const jstMsToLocalDateTimeStr = (ms: number) => {
  return flow(formatInTimeZone("yyyy-MM-dd'T'HH:mm", JSTTimeZone))(
    new Date(ms),
  );
};

export const dateStrToJSTDayLastMs = (dateStr: string) => {
  return toJSTWithSameLocalTime(
    flow(addDays(1), getTime)(stringToDate(dateStr)) - 1,
  );
};

export const toNextYearMs = (dateStr: string): number => {
  return toJSTWithSameLocalTime(
    flow(addYears(1), getTime)(stringToDate(dateStr)),
  );
};
