import React from 'react';
import * as _ from 'lodash';
import {
  FormControl,
  OutlinedTextFieldProps,
  TextField,
  Theme,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  StyledDescription,
  StyledHelperText,
  StyledInputLabel,
} from '@bizapp-frontend/management/molecules/form/FormComponent';

export interface FormDatePickerProps
  extends Omit<OutlinedTextFieldProps, 'variant' | 'size'> {
  helperText?: React.ReactNode;
  description?: React.ReactNode;
  setValue?: (value: string | ((prevVar: string) => string)) => void;
  setIsValid?: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  forceValidation?: boolean;
  setForceValidation?: (
    value: boolean | ((prevVar: boolean) => boolean),
  ) => void;
  minValue?: string;
  postChange?: (value: string) => void;
  postBlur?: (value: string) => void;
  updated?: boolean;
  disableKeyboard?: boolean;
}

export const FormDatePicker: React.FC<FormDatePickerProps> = ({
  className = '',
  id,
  label,
  required = false,
  disabled = false,
  hidden = false,
  helperText,
  description,
  defaultValue = '',
  minValue = '',
  setValue,
  setIsValid,
  forceValidation,
  setForceValidation,
  onChange,
  postChange,
  onBlur,
  postBlur,
  updated,
  disableKeyboard = false,
  ...props
}) => {
  const [checkedDefaultValue, setCheckedDefaultValue] = React.useState(false);
  const [error, setError] = React.useState(props.error ?? false);
  const [helper, setHelper] = React.useState(helperText ?? '');

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      dateTimePicker: {
        '&::-webkit-calendar-picker-indicator': {
          display: 'none',
          '-webkit-appearance': 'none',
        },
        '& .Mui-disabled': {
          backgroundColor: '#EDEFF3',
          border: 'none !important',
          color: '#333333',
        },
      },
    }),
  )();

  const handleValidate = React.useCallback(
    (value: string) => {
      if (required) {
        if (!_.isEmpty(value)) {
          setError(false);
          setIsValid && setIsValid(true);
        } else {
          setError(true);
          setIsValid && setIsValid(false);
        }
      }
    },
    [required, setIsValid],
  );

  const handleChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange && onChange(ev);
    setValue && setValue(ev.target.value as string);
    postChange && postChange(ev.target.value as string);
  };

  const handleBlur = (
    ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onBlur && onBlur(ev);
    handleValidate(props.value as string);
    postBlur && postBlur(props.value as string);
  };

  React.useEffect(() => {
    if (helperText) {
      setHelper(helperText);
    } else if (required) {
      setHelper('入力必須項目です。');
    }
  }, [helperText, required]);

  React.useEffect(() => {
    if (forceValidation) {
      handleValidate((props.value as string) ?? '');
      setForceValidation && setForceValidation(false);
    }
  }, [props.value, forceValidation, handleValidate, setForceValidation]);

  React.useEffect(() => {
    if (!checkedDefaultValue) {
      setCheckedDefaultValue(true);
      defaultValue && setValue && setValue(defaultValue as string);
      if (!_.isEmpty(defaultValue)) {
        handleValidate(defaultValue as string);
      }
    }
  }, [defaultValue, setValue, handleValidate, checkedDefaultValue]);

  return (
    <>
      {hidden || (
        <FormControl
          required={required}
          error={props.error || error}
          disabled={disabled}
          className={className}
        >
          <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>
          <TextField
            type="date"
            disabled={disabled}
            classes={{ root: classes.dateTimePicker }}
            value={props.value}
            InputProps={{
              disableUnderline: true,
              style: {
                color: updated ? '#4285F4' : undefined,
                height: '32px',
                border: '1px solid #C2CAD8',
                borderRadius: '3px',
                paddingLeft: '8px',
                paddingRight: '8px',
                fontSize: '14px',
              },
            }}
            inputProps={{
              min: minValue,
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={(e) => {
              if (disableKeyboard) {
                e.preventDefault();
              }
            }}
          />
          {helper && (props.error || error) && (
            <StyledHelperText children={helper} />
          )}
          {description && <StyledDescription children={description} />}
        </FormControl>
      )}
    </>
  );
};
