import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';

const StyledPaper = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
}))(Paper);

const StyledTableHeader = withStyles((theme) => ({
  body: {
    fontSize: 14,
    color: '#A4AAB0',
    fontWeight: 'bold',
    paddingTop: theme.spacing(1.125),
    paddingBottom: theme.spacing(1.125),
    paddingLeft: theme.spacing(4),
    borderBottom: 'none',
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
    color: '#333333',
    paddingTop: theme.spacing(1.125),
    paddingBottom: theme.spacing(1.125),
    paddingRight: theme.spacing(4),
    borderBottom: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    borderTop: '1px solid #DCE0E7',
    '&:first-child': {
      borderTop: '1px solid #A4AAB0',
    },
    '&:last-child': {
      borderBottom: '1px solid #A4AAB0',
    },
  },
}))(TableRow);

export interface Data {
  label: string;
  value: string | React.ReactNode;
}

export interface CustomerInfoTableProps {
  className?: string;
  title: string;
  data: Data[];
}

export const CustomerInfoTable: React.FC<CustomerInfoTableProps> = ({
  className,
  title,
  data,
}) => {
  const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
  }));

  const Title = styled('span')(({ theme }) => ({
    display: 'block',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#4A5560',
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
  }));

  return (
    <Root className={className}>
      <Title>{title}</Title>
      <TableContainer component={StyledPaper}>
        <Table>
          <TableBody>
            {data.map((item, i) => (
              <StyledTableRow key={i}>
                <StyledTableHeader width="37%">{item.label}</StyledTableHeader>
                <StyledTableCell>{item.value}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Root>
  );
};
