import React from 'react';
import { flatMap, map } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { Customer } from '@bizapp-frontend/management/organisms/CustomerList';
import Meta from '@bizapp-frontend/management/organisms/Meta';

import { CustomerListTemplate } from '@bizapp-frontend/management/templates/CustomerListTemplate';

export interface CustomerListPageProps {
  applicationControllerBaseUrl: string;
  customerBaseUrl: string;
}

export const CustomerListPage: React.FC<CustomerListPageProps> = ({
  applicationControllerBaseUrl,
  customerBaseUrl,
}) => {
  const [lastEvaluatedKey, setLastEvaluatedKey] = React.useState('');
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [customersBase, setCustomersBase] = React.useState<Customer[]>([]);
  const [customersExt, setCustomersExt] = React.useState<
    | {
        [key: string]: string;
      }
    | undefined
  >(undefined);
  const [fetchedCustomersExt, setFetchedCustomersExt] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [reachEnd, setReachEnd] = React.useState(false);
  const location = useLocation();
  const { getAccessTokenSilently } = useAuth0();

  const title = '顧客台帳 - HUE Works Suite マネジメントサイト';

  const handleOnCustomerClick = (index: number) => {
    window.open(
      `/customers/${customers[index].customerId}/info?backUrl=${btoa(
        location.pathname,
      )}`,
      '_blank',
    );
  };

  const handleOnScrollEnd = () => {
    if (reachEnd || loading) {
      return;
    }
    setLoading(true);
    fetchCustomerList();
  };

  const fetchCustomerList = React.useCallback(async () => {
    const accessToken = await getAccessTokenSilently();
    const url = `${customerBaseUrl}/api/customer/customers?limit=100&lastEvaluatedKey=${lastEvaluatedKey}`;
    const resp = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (resp.status === 200) {
      const res = await resp.json();
      const customerArray = flatMap(
        res.customers.sort((a: any, b: any) => {
          return b.timestamp - a.timestamp;
        }),
        (customer) => {
          const company = customer.company;
          return map(customer.users, (user) => ({
            companyName: company.companyName,
            userName: user.userName,
            email: user.email,
            jobTitle: user.jobTitle,
            customerId: company.customerId,
            timestamp: new Date(company.timestamp / 1000000).toLocaleString(
              'ja-JP',
              {
                timeZone: 'Asia/Tokyo',
              },
            ),
            userId: user.userId,
            endDate: '---',
          }));
        },
      );
      setCustomersBase((customers) => [...customers, ...customerArray]);
      if (res.lastEvaluatedKey === '') {
        setReachEnd(true);
      }
      setLastEvaluatedKey(res.lastEvaluatedKey);
    }
    setLoading(false);
  }, [customerBaseUrl, getAccessTokenSilently, lastEvaluatedKey]);

  React.useEffect(() => {
    if (!fetchedCustomersExt) {
      const f = async () => {
        const accessToken = await getAccessTokenSilently();
        const url = `${applicationControllerBaseUrl}/api/application-controller/services/pjb/customers?limit=500`;
        const resp = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setFetchedCustomersExt(true);
        if (resp.status === 200) {
          const res = await resp.json();
          const customers: { [key: string]: string } = {};
          for (const i in res.customers) {
            const contract = res.customers[i].contract;
            if (contract.usageKind === 'trial') {
              customers[contract.customerId] = new Date(
                contract.endDate / 1000000,
              ).toLocaleString('ja-JP', {
                timeZone: 'Asia/Tokyo',
              });
            }
          }
          setCustomersExt(customers);
        }
      };
      f();
    }
  }, [
    applicationControllerBaseUrl,
    fetchedCustomersExt,
    getAccessTokenSilently,
  ]);

  React.useEffect(() => {
    if (customersBase.length === 0) {
      setLoading(true);
      fetchCustomerList();
    }
  }, [customersBase.length, fetchCustomerList]);

  React.useEffect(() => {
    if (customersBase.length !== 0 && customersExt !== undefined) {
      const customers: Customer[] = [];
      customersBase.forEach((base) => {
        customers.push({
          ...base,
          endDate: customersExt[base.customerId] ?? '---',
        });
      });
      setCustomers(customers);
    }
  }, [customersBase, customersExt]);

  return (
    <>
      <Meta>
        <title>{title}</title>
        <meta property="og:title" content={title} />
      </Meta>
      <CustomerListTemplate
        customers={customers}
        onCustomerClick={handleOnCustomerClick}
        onScrollEnd={handleOnScrollEnd}
      />
    </>
  );
};
