import React from 'react';
import { Box, Button, Link, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';

import { Alert } from '@bizapp-frontend/management/atoms/Alert';
import { FormButton } from '@bizapp-frontend/management/molecules/form/FormButton';
import { StyledModal } from '@bizapp-frontend/management/molecules/StyledModal';
import { StyledSnackbar } from '@bizapp-frontend/management/molecules/StyledSnackbar';
import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/management/organisms/ProcessingDialog';
import {
  ComponentSize,
  DatePickerProps,
  FormComponentProps,
  FormGenerator,
  initFormDataPairs,
  SelectNormalProps,
} from '@bizapp-frontend/management/organisms/form/FormGenerator';
import { WarningDialog } from '@bizapp-frontend/management/organisms/WarningDialog';
import { ContractForm } from '@bizapp-frontend/management/templates/form/ContractForm';
import {
  addingEndDateSeconds,
  billKindOf,
  byteToGB,
  CalculationType,
  calculationTypeOf,
  Contract,
  contractKindOf,
  dateStrToJSTMs,
  defaultCalculationEndDateOf,
  defaultCalculationStartDateOf,
  DiscountInfo,
  getCurrMonthFirstDayJSTStr,
  initContract,
  isStandardPlan,
  jstMsToLocalDateStr,
  MAX_DATE_MARK,
  MAX_DATE_STR,
  Plans,
  PlanType,
  PriceType,
  ServiceName,
  ServicePlan,
  ServiceType,
  toEndOfMonthMs,
  toIssueDateMsOf,
  toLastModifyDateStr,
  toPreviousDay,
  toStartOfMonthMs,
} from '@bizapp-frontend/management/templates/form/utils';
import {
  FirstMonthType,
  MonthPrice,
  PriceTableInfo,
} from '@bizapp-frontend/management/templates/form/PriceTable';
import { CustomerDetailContext } from '@bizapp-frontend/management/pages/CustomerDetailPage';
import { GlobalsContext } from '@bizapp-frontend/management/globals';
import { endOfMonth, format, getDaysInMonth, isBefore } from 'date-fns';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      padding: theme.spacing(3, 2, 3, 2),
    },
    headTitle: {
      display: 'block',
      color: '#333333',
      fontSize: 20,
    },
    alert: {
      margin: theme.spacing(0, 2, 4, 0),
    },
    foot: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      padding: theme.spacing(2.75, 2),
      borderTop: '#DCE0E7 solid 1px',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: theme.spacing(109),
      maxHeight: '100%',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 16px 32px #00000014',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 5,

      '&:focus': {
        outline: 'none',
      },
    },
    title: {
      display: 'block',
      marginBottom: theme.spacing(4),
      color: '#333333',
      fontSize: 17,
      fontWeight: 'bold',
    },
    content: {
      minHeight: 300,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'overlay',
      padding: theme.spacing(0, 2, 0, 2),
    },
    form: {
      marginBottom: theme.spacing(3),
    },
    deleteBtn: {
      color: '#7F7F7F',
      fontSize: 14,
      fontWeight: 'bold',
      padding: 0,
    },
    spacer: {
      flex: '1 1 auto',
    },
    cancelBtn: {
      color: '#7F7F7F',
      fontSize: 14,
      lineHeight: '21px',
      fontWeight: 'bold',
      padding: 0,
    },
    modifyBtn: {
      color: '#4285F4',
      alignSelf: 'center',
      fontSize: 14,
      lineHeight: '21px',
      padding: 0,
      '&.Mui-disabled': {
        color: '#4285f442',
      },
    },
    submitBtn: {
      '& Button': {
        color: '#4285F4',
        alignSelf: 'center',
        fontSize: 14,
        lineHeight: '21px',
        padding: 0,
        '&.Mui-disabled': {
          color: '#4285f442',
        },
      },
    },
    label: {
      fontSize: '14px',
      color: '#777777',
      marginBottom: theme.spacing(1),
      padding: 0,
    },
    value: {
      fontSize: '16px',
      color: '#333333',
    },
  }),
);

type FormData = {
  [key: string]: number | string | undefined;
};

export interface ApplicationModalProps {
  applicationControllerBaseUrl: string;
  feeCalculatorAPIBaseUrl: string;
  customerId: string;
  open: boolean;
  onClose: Function;
  contract?: Contract;
  mode: ApplicationDialogMode;
  paymentGatewayId: string;
  availablePlanIds: PlanType[];
  onShowContract: Function;
  deletePermission: boolean;
  modifyPermission: boolean;
  updatePermission: boolean;
  messageBarPermission: boolean;
}

export type ContractAction = 'create' | 'modify' | 'update';
export type ApplicationDialogMode = 'view' | ContractAction;

const ModeTitle = new Map<ApplicationDialogMode, string>([
  ['create', '請求登録'],
  ['view', '請求詳細'],
  ['modify', '請求の訂正'],
  ['update', '請求の更改'],
]);

interface PriceInfo {
  secondMonth: MonthPrice;
  firstMonth: MonthPrice;
}

interface UsageInfo extends PriceInfo {
  volume: number;
}

export const ApplicationModal: React.FC<ApplicationModalProps> = ({
  applicationControllerBaseUrl,
  feeCalculatorAPIBaseUrl,
  customerId,
  open,
  onClose,
  contract: contractInput,
  mode: modeInput,
  paymentGatewayId,
  availablePlanIds,
  onShowContract,
  deletePermission,
  modifyPermission,
  updatePermission,
  messageBarPermission,
}) => {
  const { state } = React.useContext(GlobalsContext);
  const now = React.useCallback(() => {
    let ms = Date.now();
    if (state.development && state.developmentMeta.currentTimeMs) {
      ms = state.developmentMeta.currentTimeMs;
    }
    return ms;
  }, [state.development, state.developmentMeta.currentTimeMs]);

  const classes = useStyles();
  const [initialized, setInitialized] = React.useState(false);

  const [contractDate, setContractDate] = React.useState('');
  const [availableDate, setAvailableDate] = React.useState('');
  const [calcStartDate, setCalcStartDate] = React.useState('');
  const [calcEndDate, setCalcEndDate] = React.useState('');
  const [serviceId, setServiceId] = React.useState('');
  const [planId, setPlanId] = React.useState<PlanType | ''>('');

  const plan = React.useMemo(() => {
    return planId ? Plans.get(planId) : undefined;
  }, [planId]);

  const [contract, setContract] = React.useState(
    initContract(customerId, serviceId, planId),
  );
  const [priceType, setPriceType] = React.useState(contract.priceType);

  // const [calculationType, setCalculationType] = React.useState<CalculationType>(
  //   'normal',
  // );

  const [disabled, setDisabled] = React.useState(false);
  const [servicePartDisabled, setServicePartDisabled] = React.useState(false);
  // const [
  //   systemReflectDateDisabled,
  //   setSystemReflectDateDisabled,
  // ] = React.useState(true);
  const [dialogState, setDialogState] = React.useState<ProcessingDialogState>(
    'close',
  );

  const [mode, setMode] = React.useState(modeInput);
  React.useEffect(() => {
    setMode(modeInput);
  }, [modeInput]);

  const [toClearAll, setToClearAll] = React.useState(false);

  const [issueDateMs, setIssueDateMs] = React.useState(-1);
  const [baseContractId, setBaseContractId] = React.useState<
    string | undefined
  >(undefined);
  const [updatedContractId, setUpdatedContractId] = React.useState<
    string | undefined
  >(undefined);
  const [readonlyContract, setReadonlyContract] = React.useState<boolean>(
    false,
  );
  const [
    originalContractStartDate,
    setOriginalContractStartDate,
  ] = React.useState<number | undefined>(undefined);
  const [isOriginalContract, setIsOriginalContract] = React.useState<boolean>(
    false,
  );
  const [hasOptionPlan, setHasOptionPlan] = React.useState<boolean>(false);

  React.useEffect(() => {
    switch (mode) {
      case 'view':
        setDisabled(true);
        setServicePartDisabled(true);
        break;
      case 'create':
        setDisabled(false);
        setServicePartDisabled(false);
        break;
      case 'modify':
        setDisabled(false);
        setServicePartDisabled(!isOriginalContract);
        break;
      case 'update':
        setDisabled(false);
        setServicePartDisabled(true);
        break;
      default:
        console.error(mode, 'not supported');
    }
  }, [isOriginalContract, mode]);

  React.useEffect(() => {
    if (!open) {
      setToClearAll(true);
    }
  }, [open]);

  React.useEffect(() => {
    setToClearAll(true);
    if (contractInput) {
      setBaseContractId(contractInput.baseContractId);
      setUpdatedContractId(contractInput.updatedContractId);
      setReadonlyContract(!!contractInput.readOnlyContract);
      setOriginalContractStartDate(contractInput.originalContractStartDate);
      setIsOriginalContract(contractInput.isOriginalContract);
      setHasOptionPlan(contractInput.hasOptionPlan);

      let _priceType: PriceType = 'normal-price';
      let price = 0,
        discount = 0,
        specialPrice = undefined;
      // console.log('contractInput', contractInput);
      const planInput = Plans.get(contractInput.planId);
      const isSpecifiedContract = planInput?.contractKind === 'specified';
      if (isSpecifiedContract) {
        price = contractInput.price ?? 0;
      } else {
        const discountInfo = contractInput.discountInfo;
        if (discountInfo) {
          switch (discountInfo.kind) {
            case 'none':
              _priceType = 'normal-price';
              price = planInput?.unitPrice ?? 0;
              break;
            case 'rate':
              _priceType = 'discount';
              price = planInput?.unitPrice ?? 0;
              discount = discountInfo.rate / 10000;
              break;
            case 'fixed':
              _priceType = 'special-price';
              price = (discountInfo.unitPrice ?? 0) + discountInfo.amount;
              specialPrice = discountInfo.unitPrice ?? 0;
              break;
            default:
              console.error(
                'discountInfo.kind',
                discountInfo.kind,
                'not supported',
              );
          }
        }
      }

      setContract({
        customerId: contractInput.customerId,
        serviceId: contractInput.serviceId,
        planId: contractInput.planId,
        contractDateMs: contractInput.startDate / 1000000,
        calculationStartDateMs: contractInput.calculationStartDate / 1000000,
        availableDateMs: contractInput.availableDate / 1000000,
        calculationEndDateMs: contractInput.endDate / 1000000,
        priceType: _priceType,
        price: price,
        specialPrice: specialPrice,
        discount: discount,
        volume: contractInput.volume ?? 0,
        usageVolume: contractInput.usageVolume ?? 0,
        note: contractInput.note,
        specifiedPrice: isSpecifiedContract ? contractInput.price ?? 0 : 0,
        specifiedContractDescription: contractInput.description,
      });

      // TODO set issue date according to paymentKind info
      setIssueDateMs(
        toIssueDateMsOf(contractInput.calculationStartDate / 1000000),
      );
    } else {
      setBaseContractId(undefined);
      setUpdatedContractId(undefined);
      setReadonlyContract(false);
      setOriginalContractStartDate(undefined);
      setIsOriginalContract(false);
      setHasOptionPlan(false);
    }
  }, [contractInput]);

  const [prevFormData, setPrevFormData] = React.useState<FormData>({});

  const [formData, setFormData] = React.useState<FormData>({});
  const isUpdate = React.useCallback(
    (field: string) => {
      // console.log(
      //   field,
      //   prevFormData[field],
      //   formData[field],
      //   'updated',
      //   (mode === 'update' || mode === 'modify') &&
      //     prevFormData[field] !== formData[field],
      // );
      return (
        (mode === 'update' || mode === 'modify') &&
        prevFormData[field] !== formData[field]
      );
    },
    [formData, mode, prevFormData],
  );

  const [
    showServiceWarningDialog,
    setShowServiceWarningDialog,
  ] = React.useState(false);
  const [showPlanWarningDialog, setShowPlanWarningDialog] = React.useState(
    false,
  );

  const clearByService = React.useCallback((v: string) => {
    setAvailableDate('');
    setCalcStartDate('');
    setContractDate('');
    setServiceId(v);
    setPlanId('');
    setPriceType('normal-price');
    setFormData({
      'service-id': ServiceName.get(v as ServiceType),
      'price-type': 'normal-price',
    });
    setPriceTableData(undefined);
    setIssueDateMs(-1);
  }, []);

  const clearByPlan = React.useCallback(
    (v: string) => {
      setAvailableDate('');
      setCalcStartDate('');
      setContractDate('');
      setPlanId(v as PlanType);
      setPriceType('normal-price');
      const servicePlan = ServicePlan.get(serviceId as ServiceType);
      const planName = servicePlan?.get(v as PlanType);
      setFormData((pre) => ({
        'service-id': pre['service-id'],
        'plan-id': planName,
        'price-type': 'normal-price',
      }));
      setPriceTableData(undefined);
    },
    [serviceId],
  );

  const [serviceIdOpen, setServiceIdOpen] = React.useState(false);
  const [planIdOpen, setPlanIdOpen] = React.useState(false);

  const getServiceAndDateComponents = React.useCallback((): FormComponentProps[][] => {
    const showOriginalStartDate =
      mode === 'update' ||
      ((mode === 'view' || mode === 'modify') && !isOriginalContract);
    const currMonthMinValue =
      mode === 'update' || (mode === 'modify' && baseContractId != null)
        ? getCurrMonthFirstDayJSTStr(now())
        : '';
    const calcMinValue = contractDate ? contractDate : currMonthMinValue;

    const contractStartDateProp: DatePickerProps = {
      kind: 'datepicker-normal',
      id: 'contract-date',
      label: !showOriginalStartDate ? '契約日' : '契約更改日',
      required: true,
      disabled: disabled,
      updated: !disabled && isUpdate('contract-date'),
      size: 'medium',
      minValue: currMonthMinValue,
      disableKeyboard: true,
      postChange: (_contractDate) => {
        setContractDate(_contractDate);
        let _calcStartDate = '';
        if (!_contractDate || !planId) {
          _calcStartDate = '';
          setCalcStartDate(_calcStartDate);
          setAvailableDate(_calcStartDate);
          setCalcEndDate('');
        } else {
          _calcStartDate = defaultCalculationStartDateOf(planId, _contractDate);
          setCalcStartDate(_calcStartDate);
          setAvailableDate(_calcStartDate);
          setCalcEndDate(defaultCalculationEndDateOf(planId, _calcStartDate));
        }
        setFormData((pre) => ({
          ...pre,
          'contract-date': _contractDate,
          'start-date': _calcStartDate,
        }));

        setIsValids &&
          setIsValids((prev) => ({
            ...prev,
            'start-date': true,
          }));
      },
    };

    const calcStartDateProp = {
      kind: 'datepicker-normal',
      id: 'start-date',
      label: '請求開始日',
      required: true,
      disabled: disabled,
      disableKeyboard: true,
      updated: !disabled && isUpdate('start-date'),
      size: 'medium' as ComponentSize,
      // size: mode !== 'update' ? 'medium' : 'short',
      minValue: calcMinValue,
      postChange: (_calcStartDate: string) => {
        setCalcStartDate(_calcStartDate);
        setAvailableDate(_calcStartDate);
        if (planId) {
          setCalcEndDate(defaultCalculationEndDateOf(planId, _calcStartDate));
        }
      },
    } as DatePickerProps;

    const originalContractStartDateProp: FormComponentProps = {
      kind: 'datepicker-normal',
      id: 'original-contract-date',
      label: '契約日',
      disabled: true,
      disableKeyboard: true,
      size: 'medium',
      value:
        originalContractStartDate &&
        jstMsToLocalDateStr(originalContractStartDate / 1000000),
      postChange: () => {
        setIsValids &&
          setIsValids((prev) => ({
            ...prev,
            'original-contract-date': true,
          }));
      },
    };

    // const systemReflectDateProp = {
    //   kind: 'datepicker-switch',
    //   id: 'system-reflect-date',
    //   label: 'システム反映日',
    //   switchLabel: '更改日と異なる日を指定',
    //   disabled: disabled || systemReflectDateDisabled,
    //   size: 'short' as ComponentSize,
    //   minValue: calcStartDate,
    //   setDisabled: (disabled) => setSystemReflectDateDisabled(disabled),
    //   postChange: () => {},
    // } as DatePickerSwitchProps;

    let dateParts: FormComponentProps[][];
    if (showOriginalStartDate) {
      dateParts = [
        [originalContractStartDateProp],
        [contractStartDateProp, calcStartDateProp],
      ];
    } else {
      dateParts = [[contractStartDateProp, calcStartDateProp]];
    }

    let availablePlanOptions;
    if (mode === 'create' || (mode === 'modify' && isOriginalContract)) {
      const planEntries = Array.from(
        ServicePlan.get(serviceId as ServiceType)?.entries() ?? [],
      );
      availablePlanOptions = new Map(
        planEntries.filter(([planId]) => {
          return availablePlanIds.includes(planId);
        }),
      );
    } else {
      availablePlanOptions = ServicePlan.get(serviceId as ServiceType);
    }

    return [
      [
        {
          kind: 'select-normal',
          id: 'service-id',
          label: 'サービス',
          required: true,
          disabled: disabled || servicePartDisabled,
          size: 'medium',
          options: ServiceName,
          value: serviceId,
          updated: isUpdate('service-id'),
          postChange: (v) => {
            clearByService(v);
            if (v) {
              setServiceIdOpen(false);
            }
          },
          onClickSelect: (readOnly) => {
            if (readOnly && !disabled) {
              setShowServiceWarningDialog(true);
            }
          },
          forceOpen: serviceIdOpen,
        },
        {
          kind: 'select-normal',
          id: 'plan-id',
          label: 'プラン',
          required: true,
          disabled: disabled || servicePartDisabled,
          size: 'medium',
          options: !serviceId ? new Map() : availablePlanOptions,
          updated: isUpdate('plan-id'),
          postChange: (v) => {
            clearByPlan(v);
            if (v) {
              setPlanIdOpen(false);
            }
          },
          onClickSelect: (readOnly) => {
            if (serviceId && readOnly && !disabled) {
              setShowPlanWarningDialog(true);
            }
          },
          forceOpen: planIdOpen,
        },
      ],
      ...dateParts,
    ];
  }, [
    mode,
    isOriginalContract,
    baseContractId,
    now,
    contractDate,
    disabled,
    isUpdate,
    originalContractStartDate,
    servicePartDisabled,
    serviceId,
    serviceIdOpen,
    planIdOpen,
    planId,
    availablePlanIds,
    clearByService,
    clearByPlan,
  ]);

  const clearPriceInfo = React.useCallback(() => {
    setFormData((prev) => ({
      ...prev,
      'special-price': '',
      discount: '',
    }));
  }, []);

  const getPriceComponents = React.useCallback((): FormComponentProps[][] => {
    if (!plan) {
      return [];
    }
    const components: FormComponentProps[][] = [];
    if (plan.contractKind !== 'specified') {
      components.push([
        {
          kind: 'radio-group-normal',
          id: 'price-type',
          label: '価格調整',
          required: true,
          options: new Map<PriceType, string>([
            ['normal-price', 'なし'],
            ['special-price', '特別単価'],
            ['discount', '割引'],
          ]),
          // value: contract.priceType,
          postChange: (v) => {
            setPriceType(v as PriceType);
            clearPriceInfo();
            setIsValids &&
              setIsValids((prev) => ({
                ...prev,
                'special-price': true,
                discount: true,
              }));
          },
          disabled: disabled,
          updated: isUpdate('price-type'),
        },
      ]);
    } else {
      components.push([
        {
          kind: 'input-normal',
          id: 'description',
          label: '件名',
          size: 'short',
          required: true,
          disabled: disabled || !!plan.specifiedDescription,
          value: plan.specifiedDescription ?? '',
          updated: isUpdate('description'),
        },
      ]);
    }

    const priceRow: FormComponentProps[] = [
      {
        kind: 'input-positive-number-including-zero',
        id: 'price',
        label: '単価（税抜）',
        size: 'short',
        required: true,
        disabled: plan.contractKind !== 'specified' || disabled,
        updated: isUpdate('price'),
        // value: contract.price,`
        textAlign: 'right',
      },
    ];

    if (plan.contractKind !== 'specified') {
      if (priceType === 'discount') {
        priceRow.push({
          kind: 'input-positive-number',
          id: 'discount',
          label: '割引（％）',
          size: 'short',
          disabled: disabled,
          updated: isUpdate('discount'),
          // value: contract.specialPrice,
          textAlign: 'right',
          required: true,
          description: '割引額は四捨五入されます',
        });
      } else if (priceType === 'special-price') {
        priceRow.push({
          kind: 'input-positive-number-including-zero',
          id: 'special-price',
          label: '特別単価（税抜）',
          size: 'short',
          disabled: disabled,
          updated: isUpdate('special-price'),
          // value: contract.specialPrice,
          textAlign: 'right',
          required: true,
        });
      }
    }

    components.push(priceRow);
    return components;
  }, [clearPriceInfo, disabled, isUpdate, plan, priceType]);

  const getVolumeComponents = React.useCallback((): FormComponentProps[][] => {
    if (!plan) {
      return [];
    }
    const components: FormComponentProps[][] = [];
    switch (plan.contractKind) {
      case 'volume':
        components.push([
          {
            kind: 'input-positive-number-including-zero',
            id: 'volume',
            label: plan.volumeLabel,
            size: 'short',
            required: true,
            disabled: disabled,
            // value: contract.volume,
            textAlign: 'right',
            updated: isUpdate('volume'),
          },
        ]);
        break;
      case 'constant':
        break;
      case 'usage':
        const volumeInput: FormComponentProps = {
          kind: 'input-positive-number-including-zero',
          id: 'volume',
          label: plan.volumeLabel,
          size: 'short',
          disabled: true,
          // value: contract.volume,
          textAlign: 'right',
        };
        if (plan.planId === 'ebm-storage-usage-option') {
          // TODO: temporary solution since cannot get ファイル管理容量 from EBM

          components.push([
            {
              kind: 'input-positive-number-including-zero',
              id: 'usage-volume',
              label: plan.usageVolumeLabel,
              size: 'short',
              required: true,
              disabled: disabled,
              updated: isUpdate('usage-volume'),
              // value: contract.usageVolume ?? 0,
              textAlign: 'right',
              postChange: (v) => {
                setFormData((prev) => {
                  return {
                    ...prev,
                    'usage-volume-gb': byteToGB(_.toNumber(v) || 0),
                  };
                });
                setIsValids &&
                  setIsValids((prev) => ({
                    ...prev,
                    'usage-volume-gb': true,
                    volume: true,
                  }));
              },
            },
          ]);
          components.push([
            {
              kind: 'input-positive-number-including-zero',
              id: 'usage-volume-gb',
              label: plan.usageVolumeSubLabel,
              size: 'short',
              disabled: true,
              // value: byteToGB(contract.usageVolume ?? 0),
              textAlign: 'right',
            },
            volumeInput,
          ]);
        } else {
          components.push([
            {
              kind: 'input-positive-number-including-zero',
              id: 'usage-volume',
              label: plan.usageVolumeLabel,
              size: 'short',
              // value: contract.usageVolume ?? 0,
              textAlign: 'right',
              disabled: disabled,
              updated: isUpdate('usage-volume'),
            },
            volumeInput,
          ]);
        }
        break;
      // case 'specified':
      //   components.push([
      //     {
      //       kind: 'input-positive-number',
      //       id: 'volume',
      //       label: '数量',
      //       size: 'short',
      //       required: true,
      //       disabled: disabled,
      //       value: contract.volume,
      //       textAlign: 'right',
      //     },
      //   ]);
      //   break;
    }
    return components;
  }, [disabled, plan, isUpdate]);

  const getNoteComponents = React.useCallback((): FormComponentProps[][] => {
    if (!plan) {
      return [];
    }
    return [
      [
        {
          kind: 'input-normal',
          id: 'note',
          label: 'コメント',
          multiline: true,
          rows: 2,
          // value: contract.note,
          disabled: disabled,
          updated: isUpdate('note'),
        },
      ],
    ];
  }, [disabled, plan, isUpdate]);

  // prepare form components
  const [
    serviceAndDateComponentsProps,
    setServiceAndDateComponentsProps,
  ] = React.useState(getServiceAndDateComponents());
  const [
    priceFormComponentsProps,
    setPriceFormComponentsProps,
  ] = React.useState(getPriceComponents());
  const [
    volumeFormComponentsProps,
    setVolumeFormComponentsProps,
  ] = React.useState(getVolumeComponents());
  const [noteFormComponentProps, setNoteFormComponentProps] = React.useState(
    getNoteComponents(),
  );

  const [kinds] = React.useState(
    _.flatMapDeep(
      _.concat(
        serviceAndDateComponentsProps,
        priceFormComponentsProps,
        volumeFormComponentsProps,
        noteFormComponentProps,
      ),
    )
      .filter((v) => !v.kind.startsWith('text'))
      .map((v) => v),
  );

  const getInitValids = React.useCallback(() => {
    return _.fromPairs(kinds.map((v) => [v.id, false]));
  }, [kinds]);

  // init dialog with existed contract data
  React.useEffect(() => {
    if (!initialized) {
      if (contractInput == null) {
        setInitialized(true);
        initFormDataPairs(kinds);
      } else if (contract.planId !== '') {
        setInitialized(true);
        initFormDataPairs(kinds);
        // setInitializing(true);
        const _serviceId = contract.serviceId as ServiceType;
        const _planId = contract.planId as PlanType;
        const _plan = Plans.get(_planId);
        const _contractDate = jstMsToLocalDateStr(contract.contractDateMs);
        const _calcStartDate = jstMsToLocalDateStr(
          contract.calculationStartDateMs,
        );
        const _availableDate = jstMsToLocalDateStr(contract.availableDateMs);
        const _calcEndDate = jstMsToLocalDateStr(contract.calculationEndDateMs);
        setServiceId(_serviceId);
        setPlanId(_planId);
        setPriceType(contract.priceType);
        setContractDate(_contractDate);
        setAvailableDate(_availableDate);
        setCalcStartDate(_calcStartDate);
        setCalcEndDate(_calcEndDate);
        const _formData = {
          'service-id': ServiceName.get(_serviceId),
          'plan-id': _plan?.planName,
          'contract-date': _contractDate,
          'start-date': _calcStartDate,
          'price-type': contract.priceType,
          volume: contract.volume.toString(),
          'usage-volume': contract.usageVolume?.toString(),
          'usage-volume-gb': byteToGB(contract.usageVolume).toString(),
          note: contract.note,
          price: contract.price ? contract.price.toString() : '0',
          discount: contract.discount ? contract.discount.toString() : '',
          'special-price': _.isNumber(contract.specialPrice)
            ? contract.specialPrice.toString()
            : '',
          description: contract.specifiedContractDescription,
        };
        setPrevFormData(_.cloneDeep(_formData));
        setFormData(_formData);
        setForceValidation(true);
      }
    }
  }, [contract, contractInput, initialized, kinds]);

  const [serviceIdReadOnly, setServiceIdReadOnly] = React.useState(false);
  React.useEffect(() => {
    setServiceIdReadOnly(!!serviceId);
  }, [serviceId]);

  // React.useEffect(() => {
  //   setFormData((pre) => ({
  //     ...pre,
  //     'contract-date': contractDate,
  //     'start-date': calcStartDate,
  //   }));
  // }, [contractDate, calcStartDate]);

  // React.useEffect(() => {
  //   setFormData((pre) => ({
  //     ...pre,
  //     'plan-id': '',
  //   }));
  // }, [serviceId]);

  const [planIdReadOnly, setPlanIdReadOnly] = React.useState(false);
  React.useEffect(() => {
    setPlanIdReadOnly(!serviceId || !!planId);
  }, [serviceId, planId]);

  React.useEffect(() => {
    // if (planId) {
    if (plan) {
      // setCalculationType(calculationTypeOf(planId));
      setFormData((prev) => {
        return {
          ...prev,
          price: (contract.specifiedPrice || plan.unitPrice).toString(),
          volume: plan.contractKind === 'constant' ? 1 : prev.volume,
        };
      });
    }
    // }
  }, [contract.specifiedPrice, plan]);

  // TODO remove test code
  // React.useEffect(() => {
  //   console.log(formData);
  // }, [formData]);

  React.useEffect(() => {
    setServiceAndDateComponentsProps(() => {
      const final = getServiceAndDateComponents();
      (final[0][0] as SelectNormalProps).readOnly = serviceIdReadOnly;
      (final[0][1] as SelectNormalProps).readOnly = planIdReadOnly;
      return final;
    });
  }, [getServiceAndDateComponents, serviceIdReadOnly, planIdReadOnly]);

  React.useEffect(() => {
    setPriceFormComponentsProps(getPriceComponents());
  }, [getPriceComponents]);

  React.useEffect(() => {
    setVolumeFormComponentsProps(getVolumeComponents());
  }, [getVolumeComponents]);

  React.useEffect(() => {
    setNoteFormComponentProps(getNoteComponents());
  }, [getNoteComponents]);

  const handleClose = React.useCallback(() => {
    // clearAll();
    onClose();
  }, [onClose]);

  const handleCancel = () => {
    handleClose();
  };

  const clearAll = React.useCallback(() => {
    setContractDate('');
    setCalcStartDate('');
    setAvailableDate('');
    setCalcEndDate('');
    setServiceId('');
    setPlanId('');
    setPriceType('normal-price');
    setServiceIdOpen(false);
    setPlanIdOpen(false);
    setFormData({});
    setIsValids(getInitValids());
    setPriceTableData(undefined);
    setFirstMonthType('normal');
    setFirstMonthRemaining(undefined);
    setPriceInfoDelta(undefined);
    setPriceInfoUpdated(undefined);
    setSecondMonthCurrent(undefined);
    setFirstMonthType('normal');
    // setDiscountInfoCurrent(getDiscountInfo());
    setCalculated(false);
    setDialogState('close');
  }, [getInitValids]);

  React.useEffect(() => {
    if (toClearAll) {
      setToClearAll(false);
      clearAll();
      setInitialized(false);
    }
  }, [clearAll, toClearAll]);

  const [isValids, setIsValids] = React.useState(getInitValids());
  const [forceValidation, setForceValidation] = React.useState(false);
  const [isAllValid, setIsAllValid] = React.useState(true);
  React.useEffect(() => {
    // TODO remove test code
    // console.log(isValids);
    setIsAllValid(Object.values(isValids).every((it) => it));
  }, [isValids]);

  const { state: contextState } = React.useContext(GlobalsContext);
  const { getAccessTokenSilently } = useAuth0();
  const { dispatch: dispatchCustomerDetail } = React.useContext(
    CustomerDetailContext,
  );

  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = React.useState(
    false,
  );
  const handleDelete = async () => {
    setShowDeleteConfirmDialog(true);
  };

  const [disableAction, setDisableAction] = React.useState(false);
  const deleteContract = React.useCallback(() => {
    if (!disableAction) {
      const _deleteContract = async () => {
        const accessToken = await getAccessTokenSilently();
        const jsonData = {
          contractId: contractInput?.contractId,
        };
        const body = {
          applicationType: 'contract',
          applicationSubtype: 'delete',
          userKind: 'operator',
          userId: contextState.userId,
          customerId: customerId,
          serviceId: serviceId,
          tenantId: contractInput?.tenantId,
          notificationKind: 'none',
          jsonData: JSON.stringify(jsonData),
        };
        const resp = await fetch(
          `${applicationControllerBaseUrl}/api/application-controller/applications`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(body),
          },
        );

        if (resp.ok) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      };

      const f = async () => {
        try {
          setDisableAction(true);
          setDialogState('wait');
          await _deleteContract();
          setDialogState('close');
          setTimeout(() => {
            dispatchCustomerDetail({
              type: 'RELOAD_CONTRACTS',
            });
          }, 5000);
          handleClose();
          setSnackBarMessage('請求内容を削除しました');
          setSnackBarOpen(true);
        } catch {
          setDialogState('error');
        } finally {
          setDisableAction(false);
        }
      };
      f();
    }
  }, [
    applicationControllerBaseUrl,
    contextState.userId,
    contractInput?.contractId,
    contractInput?.tenantId,
    customerId,
    disableAction,
    dispatchCustomerDetail,
    getAccessTokenSilently,
    handleClose,
    serviceId,
  ]);

  const [priceTableData, setPriceTableData] = React.useState<
    PriceTableInfo | undefined
  >(undefined);

  const price = React.useMemo(() => {
    const data = _.toNumber(formData['price']);
    return _.isNaN(data) ? 0 : data;
  }, [formData]);
  const specialPrice = React.useMemo(() => {
    const data = _.toNumber(formData['special-price']);
    return _.isNaN(data) ? 0 : data;
  }, [formData]);
  const discount = React.useMemo(() => {
    const data = _.toNumber(formData['discount']);
    return _.isNaN(data) ? 0 : data;
  }, [formData]);
  const getDiscountInfo = React.useCallback(() => {
    const _unitPriceExcludingTax =
      priceTableData?.secondMonth?.unitPriceExcludingTax ?? 0;

    let discountInfo: DiscountInfo = {
      kind: 'none',
      amount: 0,
      price: 0,
      rate: 0,
      unitPrice: 0,
    };
    switch (priceType) {
      case 'normal-price':
        discountInfo = {
          kind: 'none',
          unitPrice: 0,
          amount: 0,
          rate: 0,
          price: 0,
        };
        break;
      case 'special-price':
        discountInfo = {
          kind: 'fixed',
          unitPrice: _unitPriceExcludingTax,
          amount: price - specialPrice,
          rate: 0,
          price: 0,
        };
        break;
      case 'discount':
        discountInfo = {
          kind: 'rate',
          unitPrice: _unitPriceExcludingTax,
          amount: 0,
          rate: discount * 10000,
          price: 0,
        };
        break;
    }
    return discountInfo;
  }, [
    discount,
    price,
    priceTableData?.secondMonth?.unitPriceExcludingTax,
    priceType,
    specialPrice,
  ]);

  const discountInfo = React.useMemo(() => {
    return getDiscountInfo();
  }, [getDiscountInfo]);

  const buildJsonData = React.useCallback(
    (action: ContractAction) => {
      let jsonData = {};
      if (planId) {
        const contractKind = contractKindOf(planId);
        const discountInfo = getDiscountInfo();
        const startDateMs = dateStrToJSTMs(contractDate);
        const endDateMs =
          calcEndDate === MAX_DATE_STR
            ? MAX_DATE_MARK
            : addingEndDateSeconds(dateStrToJSTMs(calcEndDate));
        const availableDateMs = dateStrToJSTMs(availableDate);
        const calculationStartDateMs = dateStrToJSTMs(calcStartDate);
        jsonData = {
          planId: planId,
          note: formData.note,

          volume: 0,
          usageVolume: 0,
          price: _.toNumber(formData['price']),
          description: '',
          discountInfo: {
            kind: 'none',
            amount: 0,
            price: 0,
            rate: 0,
            unitPrice: 0,
          },
        };

        switch (action) {
          case 'create':
            jsonData = {
              ...jsonData,
              usageKind: 'purchased',

              // used by created
              // to be updated in the future to be the same as modify and update api
              contractDateMs: startDateMs,
              calculationEndDateMs: endDateMs,
              availableDateMs: availableDateMs,
              calculationStartDateMs: calculationStartDateMs,
            };
            break;
          case 'modify':
          case 'update':
            jsonData = {
              ...jsonData,
              baseContractId: contractInput?.contractId,
              startDateMs: startDateMs,
              endDateMs: endDateMs,
              availableDateMs: availableDateMs,
              calculationStartDateMs: calculationStartDateMs,
            };
        }

        switch (contractKind) {
          case 'volume':
            jsonData = {
              ...jsonData,
              volume: _.toNumber(formData['volume']),
              discountInfo: discountInfo,
            };
            break;
          case 'constant':
            jsonData = {
              ...jsonData,
              volume: 1,
              discountInfo: discountInfo,
            };
            break;
          case 'usage':
            jsonData = {
              ...jsonData,
              volume: _.toNumber(formData['volume']),
              usageVolume: _.toNumber(formData['usage-volume']),
              discountInfo: discountInfo,
            };
            break;
          case 'specified':
            jsonData = {
              ...jsonData,
              price: _.toNumber(formData['price']),
              description: formData['description'] as string,
            };
            break;
        }
      }
      return jsonData;
    },
    [
      availableDate,
      calcEndDate,
      calcStartDate,
      contractDate,
      contractInput?.contractId,
      formData,
      getDiscountInfo,
      planId,
    ],
  );

  const doContractAction = React.useCallback(
    async (action: ContractAction): Promise<boolean> => {
      if (!disableAction && planId && customerId) {
        const _doContractAction = async () => {
          const accessToken = await getAccessTokenSilently();
          const jsonData = buildJsonData(action);

          const body = {
            applicationType: 'contract',
            applicationSubtype: action,
            userKind: 'operator',
            userId: contextState.userId,
            customerId: customerId,
            serviceId: serviceId,
            tenantId: action === 'create' ? '' : contractInput?.tenantId,
            notificationKind: 'none',
            jsonData: JSON.stringify(jsonData),
          };
          const resp = await fetch(
            `${applicationControllerBaseUrl}/api/application-controller/applications`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify(body),
            },
          );

          if (resp.ok) {
            return Promise.resolve();
          } else {
            return Promise.reject();
          }
        };
        let created = false;
        try {
          setDisableAction(true);
          setDialogState('wait');
          await _doContractAction();
          setDialogState('close');
          setTimeout(() => {
            dispatchCustomerDetail({
              type: 'RELOAD_CONTRACTS',
            });
            dispatchCustomerDetail({
              type: 'RELOAD_TENANTS',
            });
          }, 5000);
          setSnackBarMessage('請求内容を登録しました');
          setSnackBarOpen(true);
          created = true;
        } catch {
          setDialogState('error');
        } finally {
          setDisableAction(false);
        }
        return created;
      }
      return false;
    },
    [
      applicationControllerBaseUrl,
      buildJsonData,
      contextState.userId,
      contractInput?.tenantId,
      customerId,
      disableAction,
      dispatchCustomerDetail,
      getAccessTokenSilently,
      planId,
      serviceId,
    ],
  );

  const handleSubmit = async () => {
    let succeeded = false;
    switch (mode) {
      case 'create':
      case 'update':
      case 'modify':
        succeeded = await doContractAction(mode as ContractAction);
        break;
      default:
        console.error('not support mode', mode);
        break;
    }
    if (succeeded) {
      handleClose();
    }
  };

  const handleConfirmDelete = async () => {
    setShowDeleteConfirmDialog(false);
    deleteContract();
  };

  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');

  const volume = React.useMemo(() => {
    const data = _.toNumber(formData['volume']);
    return _.isNaN(data) ? 0 : data;
  }, [formData]);
  const usageVolume = React.useMemo(
    () => _.toNumber(formData['usage-volume']),
    [formData],
  );
  const specifiedPrice = React.useMemo(() => _.toNumber(formData['price']), [
    formData,
  ]);
  const [
    discountInfoCurrent,
    setDiscountInfoCurrent,
  ] = React.useState<DiscountInfo>(getDiscountInfo());
  const [secondMonthCurrent, setSecondMonthCurrent] = React.useState<
    MonthPrice | undefined
  >(undefined);

  const [firstMonthType, setFirstMonthType] = React.useState<FirstMonthType>(
    'normal',
  );

  const [priceInfoUpdated, setPriceInfoUpdated] = React.useState<
    PriceInfo | undefined
  >();
  const [priceInfoDelta, setPriceInfoDelta] = React.useState<
    PriceInfo | undefined
  >();
  const [firstMonthRemaining, setFirstMonthRemaining] = React.useState<
    MonthPrice | undefined
  >();
  const [calculated, setCalculated] = React.useState(false);
  React.useEffect(() => {
    if (
      open &&
      plan &&
      calcStartDate &&
      (usageVolume != null || volume != null)
    ) {
      const getPrice = async (
        typeId: CalculationType,
        usageStartDateMs: number,
        usageEndDateMs: number,
        volume: number,
        discountedUnitPrice?: number,
        discountRate?: number,
      ): Promise<PriceInfo> => {
        const method = 'POST';
        const url = `${feeCalculatorAPIBaseUrl}/fee-calculator`;
        const accessToken = await getAccessTokenSilently();
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };

        let actualPrice = price;
        let discountAmount = 0;
        //if true (= not undefined), it means corresponding discount kind is applied to this calculation
        if (discountedUnitPrice !== undefined) {
          actualPrice = discountedUnitPrice;
          discountAmount = price - discountedUnitPrice;
        } else if (discountRate !== undefined) {
          const rateDiscountAmount = Math.round(price * (discountRate / 100));
          actualPrice = price - rateDiscountAmount;
          discountAmount = rateDiscountAmount;
        }

        const _body = {
          unitPrice: actualPrice.toString(),
          quantity:
            plan.contractKind === 'specified' ||
            plan.contractKind === 'constant'
              ? '1'
              : volume.toString(),
          usageStartDate: format(usageStartDateMs, 'yyyy-MM-dd'),
          usageEndDate: format(usageEndDateMs, 'yyyy-MM-dd'),
          closingDate: format(endOfMonth(usageStartDateMs), 'yyyy-MM-dd'),
          billType:
            billKindOf(plan.planId) === 'monthly' ? 'monthly' : 'one-shot',
          productType: typeId === 'pro-rata-basis' ? ['by-the-day'] : [''],
        };

        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify(_body),
        });

        if (!resp.ok) {
          throw new Error(
            `Failed in getting price data. status is ${resp.status}`,
          );
        }

        const newResponse = await resp.json();

        //convert priceInfo of newly introduced /fee-calculator API to existing priceInfo
        const convertedResponse = convert(newResponse, discountAmount, volume);

        //Only true when calcStartDate is the first day of month upon updating a contract
        if (isBefore(usageEndDateMs, usageStartDateMs)) {
          convertedResponse.firstMonth.priceExcludingTax = 0;
          convertedResponse.firstMonth.priceIncludingTax = 0;
          convertedResponse.firstMonth.unitPriceExcludingTax = 0;
          convertedResponse.firstMonth.unitPriceIncludingTax = 0;
          convertedResponse.firstMonth.discount.remaining.target.days = getDaysInMonth(
            usageStartDateMs,
          );
          convertedResponse.firstMonth.discount.remaining.amountExcludingTax = -actualPrice;
        }

        return convertedResponse;
      };
      const convert = (
        newResponse: any,
        discountAmount: number,
        volume: number,
      ): PriceInfo => {
        return {
          firstMonth: {
            priceExcludingTax: 0,
            priceIncludingTax: _.toNumber(
              newResponse.discountedPrice.priceIncludingTax,
            ),
            licenseNumber: volume,
            basePriceExcludingTax: price,
            basePriceIncludingTax: 0,
            unitPriceExcludingTax: _.toNumber(
              newResponse.discountedPrice.unitPriceExcludingTax,
            ),
            unitPriceIncludingTax: _.toNumber(
              newResponse.discountedPrice.unitPriceIncludingTax,
            ),
            discount: {
              remaining: {
                amountIncludingTax: 0,
                amountExcludingTax: -newResponse.discountedPrice
                  .discountAmountForUnitPriceExcludingTax,
                target: {
                  days: newResponse.discountedPrice.daysUnused,
                  startDate: 0,
                  endDate: 0,
                },
                usage: {
                  days: 0,
                  startDate: 0,
                  endDate: 0,
                },
              },
              coupon: {
                amountIncludingTax: 0,
                amountExcludingTax: -discountAmount,
                target: {
                  days: 0,
                  startDate: 0,
                  endDate: 0,
                },
                usage: {
                  days: 0,
                  startDate: 0,
                  endDate: 0,
                },
              },
            },
          },
          secondMonth: {
            priceExcludingTax: 0,
            priceIncludingTax: _.toNumber(
              newResponse.normalPrice.priceIncludingTax,
            ),
            licenseNumber: volume,
            basePriceExcludingTax: price,
            basePriceIncludingTax: 0,
            unitPriceExcludingTax: _.toNumber(
              newResponse.normalPrice.unitPriceExcludingTax,
            ),
            unitPriceIncludingTax: _.toNumber(
              newResponse.normalPrice.unitPriceIncludingTax,
            ),
            discount: {
              remaining: {
                amountIncludingTax: 0,
                amountExcludingTax: 0,
                target: {
                  days: 0,
                  startDate: 0,
                  endDate: 0,
                },
                usage: {
                  days: 0,
                  startDate: 0,
                  endDate: 0,
                },
              },
              coupon: {
                amountIncludingTax: 0,
                amountExcludingTax: -discountAmount,
                target: {
                  days: 0,
                  startDate: 0,
                  endDate: 0,
                },
                usage: {
                  days: 0,
                  startDate: 0,
                  endDate: 0,
                },
              },
            },
          },
        };
      };
      const calcUsage = async (
        _planId: PlanType,
        usageStartDateMs: number,
        usageEndDateMs: number,
        usageVolume: number,
        discountedUnitPrice: number | undefined,
        discountRate: number | undefined,
      ): Promise<UsageInfo> => {
        const method = 'POST';
        const url = `${feeCalculatorAPIBaseUrl}/usage-calculator/plans/${_planId}`;
        const accessToken = await getAccessTokenSilently();
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        const contractKind = contractKindOf(_planId);
        const _body = {
          usageStartDateMs: usageStartDateMs,
          usageEndDateMs: usageEndDateMs,
          number: usageVolume,
          planId: _planId,
          contractKind: contractKind,
          discountedUnitPrice:
            discountedUnitPrice === undefined ? 0 : discountedUnitPrice,
          discountRate: discountRate === undefined ? 0 : discountRate,
        };

        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify(_body),
        });

        if (!resp.ok) {
          throw new Error(
            `Failed in getting price data. status is ${resp.status}`,
          );
        }

        return resp.json();
      };
      const callCalcApi = async (
        usageStartDateMs: number,
        usageEndDateMs: number,
        volume: number,
        usageVolume: number,
        discountedUnitPrice: number | undefined,
        discountRate: number | undefined,
      ): Promise<PriceInfo | UsageInfo> => {
        if (plan.contractKind === 'usage') {
          const usageInfo = await calcUsage(
            plan.planId,
            usageStartDateMs,
            usageEndDateMs,
            usageVolume,
            discountedUnitPrice,
            discountRate,
          );
          const priceInfo = await getPrice(
            calculationTypeOf(plan.planId),
            usageStartDateMs,
            usageEndDateMs,
            usageInfo.volume,
            discountedUnitPrice,
            discountRate,
          );
          return {
            ...priceInfo,
            volume: usageInfo.volume,
          };
        } else {
          return getPrice(
            calculationTypeOf(plan.planId),
            usageStartDateMs,
            usageEndDateMs,
            volume,
            discountedUnitPrice,
            discountRate,
          );
        }
      };
      const f = async () => {
        if (plan) {
          setCalculated(false);
          try {
            setDialogState('wait');

            let _volume = volume ?? 0;
            const _usageVolume = usageVolume;

            let discountedUnitPrice =
              priceType === 'special-price'
                ? _.toNumber(specialPrice)
                : undefined;
            let discountRate = priceType === 'discount' ? discount : undefined;

            const updatedData = await callCalcApi(
              dateStrToJSTMs(calcStartDate),
              toEndOfMonthMs(calcStartDate),
              _volume,
              _usageVolume,
              discountedUnitPrice,
              discountRate,
            );
            setPriceInfoUpdated(updatedData);

            if (plan.contractKind === 'usage') {
              _volume = (updatedData as UsageInfo).volume;
              setFormData((prev) => ({
                ...prev,
                volume: _volume,
              }));
            }

            if (mode === 'view') {
              setDiscountInfoCurrent({ ...discountInfo });
              setSecondMonthCurrent(updatedData.secondMonth);
            }
            if (mode === 'update' && plan.billingBehavior === 'daily-basis') {
              if (
                contract.priceType !== priceType ||
                (contract.priceType === 'special-price' &&
                  contract.specialPrice !== specialPrice) ||
                (contract.priceType === 'discount' &&
                  contract.discount !== discount) ||
                (plan.contractKind === 'specified' &&
                  contract.specifiedPrice !== specifiedPrice)
              ) {
                const discountedUnitPriceCurrent =
                  contract.priceType === 'special-price'
                    ? _.toNumber(contract.specialPrice ?? 0)
                    : undefined;
                const discountRateCurrent =
                  contract.priceType === 'discount'
                    ? contract.discount ?? 0
                    : undefined;

                const oldRemainData = await callCalcApi(
                  toStartOfMonthMs(calcStartDate),
                  toPreviousDay(dateStrToJSTMs(calcStartDate)),
                  contract.volume,
                  contract.usageVolume ?? 0,
                  discountedUnitPriceCurrent,
                  discountRateCurrent,
                );
                setFirstMonthRemaining(oldRemainData.firstMonth);
              } else if (contract.volume && contract.volume !== _volume) {
                const volumeDelta = _volume - contract.volume;
                if (volumeDelta < 0) {
                  setPriceInfoDelta(undefined);
                } else {
                  // since the delta of usage volume cannot to be used
                  // to calculate delta price, use estimation api instead
                  // use delta volume as volume to calculate directly
                  // e.g.
                  // usage volume: 3 -> 4
                  // volume: 1 -> 2
                  // delta usage volume: 1
                  // delta volume: 1
                  const deltaData = await getPrice(
                    'pro-rata-basis',
                    dateStrToJSTMs(calcStartDate),
                    toEndOfMonthMs(calcStartDate),
                    volumeDelta,
                    discountedUnitPrice,
                    discountRate,
                  );
                  setPriceInfoDelta(deltaData);
                }
              }
            }

            setCalculated(true);
          } catch {
            setDialogState('error');
          }
        }
      };
      f();
    }
  }, [
    calcStartDate,
    contract.discount,
    contract.priceType,
    contract.specialPrice,
    contract.specifiedPrice,
    contract.usageVolume,
    contract.volume,
    discount,
    discountInfo,
    feeCalculatorAPIBaseUrl,
    getAccessTokenSilently,
    mode,
    open,
    plan,
    priceType,
    specialPrice,
    specifiedPrice,
    usageVolume,
    volume,
    price,
  ]);

  React.useEffect(() => {
    if (plan && priceInfoUpdated && calculated) {
      setCalculated(false);
      let secondMonth,
        firstMonth = undefined,
        firstMonthCurrent = undefined,
        firstMonthUpdated = undefined;
      secondMonth = priceInfoUpdated.secondMonth;
      if (mode === 'update' && plan.billingBehavior === 'daily-basis') {
        if (
          contract.priceType !== priceType ||
          (contract.priceType === 'special-price' &&
            contract.specialPrice !== specialPrice) ||
          (contract.priceType === 'discount' &&
            contract.discount !== discount) ||
          (plan.contractKind === 'specified' &&
            contract.specifiedPrice !== specifiedPrice)
        ) {
          firstMonthUpdated = priceInfoUpdated.firstMonth;
          firstMonthCurrent = firstMonthRemaining;
          setFirstMonthType('price-diff');
        } else if (contract.volume && contract.volume !== volume) {
          firstMonthUpdated = priceInfoDelta?.firstMonth;
          firstMonthCurrent = secondMonthCurrent;
          setFirstMonthType('volume-diff');
        } else {
          firstMonth = priceInfoUpdated.firstMonth;
          setFirstMonthType('normal');
        }
      } else {
        firstMonth = priceInfoUpdated.firstMonth;
        setFirstMonthType('normal');
      }
      // console.log({
      //   secondMonth: secondMonth,
      //   firstMonth: firstMonth,
      //   firstMonthUpdated: firstMonthUpdated,
      //   firstMonthCurrent: firstMonthCurrent,
      // });
      setPriceTableData({
        secondMonth: secondMonth,
        firstMonth: firstMonth,
        firstMonthUpdated: firstMonthUpdated,
        firstMonthCurrent: firstMonthCurrent,
      });
      setDialogState('close');
    }
  }, [
    calculated,
    contract.discount,
    contract.priceType,
    contract.specialPrice,
    contract.specifiedPrice,
    contract.usageVolume,
    contract.volume,
    discount,
    firstMonthRemaining,
    mode,
    plan,
    priceInfoDelta,
    priceInfoUpdated,
    priceType,
    secondMonthCurrent,
    specialPrice,
    specifiedPrice,
    usageVolume,
    volume,
  ]);

  const [canDelete, setCanDelete] = React.useState(false);
  const [canModify, setCanModify] = React.useState(false);
  const [canUpdate, setCanUpdate] = React.useState(false);

  React.useEffect(() => {
    if (paymentGatewayId === 'stripe') {
      setCanDelete(false);
      setCanModify(false);
      setCanUpdate(false);
    } else {
      // mfkessai
      if (mode === 'view') {
        if (readonlyContract) {
          setCanDelete(false);
          setCanModify(false);
          setCanUpdate(false);
        } else {
          if (now() < issueDateMs) {
            // For std plan, if
            // 1. it is the first plan
            // 2. it has other option plan
            // then it cannot be deleted
            if (
              planId &&
              isStandardPlan(planId) &&
              isOriginalContract &&
              hasOptionPlan
            ) {
              setCanDelete(false);
            } else {
              setCanDelete(true);
            }

            setCanModify(true);
            setCanUpdate(false);
          } else {
            setCanDelete(false);
            setCanModify(false);
            if (plan) {
              if (plan.billKind === 'oneshot') {
                setCanUpdate(false);
              } else if (isStandardPlan(plan.planId)) {
                setCanUpdate(true);
              } else if (plan.contractKind === 'specified') {
                setCanUpdate(false);
              } else {
                setCanUpdate(true);
              }
            }
          }
        }
      } else {
        setCanDelete(false);
        setCanModify(false);
        setCanUpdate(false);
      }
    }
  }, [
    hasOptionPlan,
    isOriginalContract,
    issueDateMs,
    mode,
    now,
    paymentGatewayId,
    plan,
    planId,
    readonlyContract,
  ]);

  const resetActionStatus = () => {
    setCanDelete(false);
    setCanModify(false);
    setCanUpdate(false);
  };

  const toModifyMode = () => {
    setMode('modify');
    resetActionStatus();
  };

  const toUpdateMode = () => {
    setContractDate('');
    setCalcStartDate('');
    setAvailableDate('');
    setCalcEndDate('');
    const formDataResetPart = {
      'contract-date': '',
      'start-date': '',
    };
    setPrevFormData((prev) => ({
      ...prev,
      ...formDataResetPart,
    }));
    setFormData((prev) => ({
      ...prev,
      ...formDataResetPart,
    }));
    setMode('update');
    resetActionStatus();
  };

  return (
    <>
      <StyledModal open={open} onClose={handleClose}>
        <div className={classes.root}>
          <Typography component="span" className={classes.head}>
            <Typography className={classes.headTitle}>
              {ModeTitle.get(mode)}
            </Typography>
          </Typography>
          <Box className={classes.content}>
            {messageBarPermission &&
              !readonlyContract &&
              canModify &&
              baseContractId == null && (
                <Alert className={classes.alert}>
                  初回請求前日の{toLastModifyDateStr(issueDateMs)}
                  までは訂正が可能です。
                </Alert>
              )}
            {!readonlyContract && canModify && baseContractId != null && (
              <Alert className={classes.alert}>
                {messageBarPermission &&
                  `初回請求前日の${toLastModifyDateStr(issueDateMs)}
                までは訂正が可能です。`}
                更改前の請求詳細は
                <Link
                  component="button"
                  onClick={() => onShowContract(baseContractId)}
                >
                  こちら
                </Link>
                。
              </Alert>
            )}
            {readonlyContract && (
              <Alert className={classes.alert} severity={'warning'}>
                更改前の請求情報です。最新の請求詳細は
                <Link
                  component="button"
                  onClick={() => onShowContract(updatedContractId)}
                >
                  こちら
                </Link>
                。
              </Alert>
            )}
            {mode === 'update' && (
              <Alert className={classes.alert}>
                <Box>
                  <Typography>
                    更改に際しては、以下の点にご注意ください。
                  </Typography>
                  <div>
                    <div>
                      ・更改操作を行っても登録ミスの訂正は行う事はできません。
                    </div>
                    <div>
                      ・更改は、契約ライセンス数の増加等、契約更改をする目的で利用してください。
                    </div>
                  </div>
                </Box>
              </Alert>
            )}
            <Typography component="span" className={classes.title}>
              契約概要
            </Typography>
            <FormGenerator
              className={classes.form}
              formData={formData}
              setFormData={setFormData}
              formComponents={serviceAndDateComponentsProps}
              isValids={isValids}
              setIsValids={setIsValids}
              forceValidation={forceValidation}
              setForceValidation={setForceValidation}
            />
            {planId && (
              <ContractForm
                formData={formData}
                setFormData={setFormData}
                priceComponents={priceFormComponentsProps}
                volumeComponents={volumeFormComponentsProps}
                noteComponents={noteFormComponentProps}
                priceInfo={priceTableData}
                isValids={isValids}
                setIsValids={setIsValids}
                forceValidation={forceValidation}
                setForceValidation={setForceValidation}
                discountInfoCurrent={discountInfoCurrent}
                discountInfoUpdated={discountInfo}
                plan={plan}
                firstMonthType={firstMonthType}
                hideFirstMonthPriceTable={paymentGatewayId === 'stripe'}
              />
            )}
          </Box>
          <Box className={classes.foot}>
            <Button className={classes.cancelBtn} onClick={handleCancel}>
              {mode === 'create' ? 'キャンセル' : '閉じる'}
            </Button>
            <div className={classes.spacer} />
            {deletePermission && canDelete && (
              <Button className={classes.cancelBtn} onClick={handleDelete}>
                削除
              </Button>
            )}
            {modifyPermission && canModify && (
              <Button className={classes.modifyBtn} onClick={toModifyMode}>
                訂正
              </Button>
            )}
            {updatePermission && canUpdate && (
              <Button className={classes.modifyBtn} onClick={toUpdateMode}>
                更改
              </Button>
            )}
            {(mode === 'create' || mode === 'modify' || mode === 'update') && (
              <FormButton
                id={'create-btn'}
                label={'登録'}
                variant={'outlined'}
                className={classes.submitBtn}
                disabled={!isAllValid}
                isValids={isValids}
                forceValidation={forceValidation}
                setForceValidation={setForceValidation}
                postClick={handleSubmit}
              />
            )}
          </Box>
        </div>
      </StyledModal>
      <WarningDialog
        show={showServiceWarningDialog}
        errorMessage={
          'サービスを変更すると、入力済みのプランおよび請求情報がクリアされます。よろしいですか？'
        }
        onClickCancel={() => {
          setShowServiceWarningDialog(false);
        }}
        onClickOK={() => {
          setServiceIdReadOnly(false);
          setShowServiceWarningDialog(false);
          clearByService('');
          setServiceIdOpen(true);
        }}
      />
      <WarningDialog
        show={showPlanWarningDialog}
        errorMessage={
          'プランを変更すると、入力済みの請求情報がクリアされます。よろしいですか？'
        }
        onClickCancel={() => {
          setShowPlanWarningDialog(false);
        }}
        onClickOK={() => {
          setPlanIdReadOnly(false);
          setShowPlanWarningDialog(false);
          clearByPlan('');
          setPlanIdOpen(true);
        }}
      />
      <WarningDialog
        show={showDeleteConfirmDialog}
        errorMessage={
          '請求を削除すると、元に戻すことができません。 よろしいですか？'
        }
        onClickCancel={() => {
          setShowDeleteConfirmDialog(false);
        }}
        okLabel={'削除する'}
        onClickOK={handleConfirmDelete}
      />
      <StyledSnackbar
        open={snackBarOpen}
        severity="default"
        message={snackBarMessage}
        onClose={() => setSnackBarOpen(false)}
      />
      <ProcessingDialog
        state={dialogState ?? 'close'}
        onClickError={() => setDialogState('close')}
      />
    </>
  );
};
