import React from 'react';
import {
  Snackbar,
  SnackbarProps,
  Typography,
  IconButton,
} from '@material-ui/core';
import { makeStyles, styled, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& div': {
      width: 320,
      height: 48,
      borderRadius: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  icon: {
    fontSize: '20px',
    color: '#FFFFFF',
  },
  closeIcon: {
    color: '#7F7F7F',
  },
  message: {
    display: 'block',
    marginLeft: theme.spacing(1),
    color: '#FFFFFF',
    fontSize: '14px',
    lineHeight: '21px',
  },
}));

export interface StyledSnackbarProps extends SnackbarProps {
  severity: 'default';
  message: string;
  autoHide?: boolean;
  handleClose?: Function;
}

export const StyledSnackbar: React.FC<StyledSnackbarProps> = ({
  open,
  autoHideDuration,
  severity,
  message,
  onClose,
  autoHide = true,
  handleClose = () => {},
  ...props
}) => {
  const classes = useStyles();
  const [color, setColor] = React.useState('');

  React.useEffect(() => {
    switch (severity) {
      case 'default':
      default:
        setColor('#25323F');
    }
  }, [severity]);

  const Content = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 1.5),
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '8px',
    backgroundColor: color,
  }));

  return (
    <Snackbar
      className={classes.root}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      onClose={onClose}
      autoHideDuration={
        autoHide || autoHideDuration ? autoHideDuration ?? 3000 : null
      }
    >
      <Content>
        <Typography component="span" className={classes.message}>
          {message}
        </Typography>
        <IconButton onClick={() => handleClose()}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </Content>
    </Snackbar>
  );
};
