import React from 'react';
import { Typography } from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  styled,
  Theme,
} from '@material-ui/core/styles';

import {
  CustomerInfoTable,
  Data,
} from '@bizapp-frontend/management/organisms/CustomerInfoTable';

import { ExtendTrialModal } from '@bizapp-frontend/management/organisms/ExtendTrialModal';
import { StyledButton } from '@bizapp-frontend/management/molecules/StyledButton';
import { FreemiumModal } from '@bizapp-frontend/management/templates/form/FreemiumModal';

const NANO_SECONDS_FACTOR = 1000000;

const timestamp2Date = (timestamp: number) => {
  if (!timestamp) {
    return '';
  }
  return new Date(timestamp / NANO_SECONDS_FACTOR).toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
  });
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(3),
      maxWidth: theme.spacing(86),
    },
    extendEndDateBtn: {
      height: theme.spacing(4),
      width: theme.spacing(18),
      padding: theme.spacing(0.75, 2, 0.375, 2),
      fontSize: '14px',
      fontWeight: 'bold',
    },
    freemiumBtn: {
      height: theme.spacing(4),
      width: theme.spacing(20),
      padding: theme.spacing(0.75, 2),
      fontSize: '14px',
      fontWeight: 'bold',
    },
    serviceName: {
      display: 'block',
      color: '#333333',
      fontSize: '17px',
      fontWeight: 'bold',
      marginLeft: theme.spacing(1),
    },
    icon: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  }),
);

export interface ServiceDetailContract {
  contractId: string;
  tenantId: string;
  tenantName: string;
  serviceId: string;
  planId: string;
  usageKind: string;
  volume: string;
  startDate: number;
  endDate: number;
  note: string;
  status: string;
}

export interface CustomerDetailTenantTemplateProps {
  iconSrc?: string;
  serviceName?: string;
  contract: ServiceDetailContract;
  onUpdate?: Function;
  onUpdateUsageKind?: Function;
  extendTrialPermission: boolean;
  updateUsageKindPermission: boolean;
}

export const CustomerDetailTenantTemplate: React.FC<CustomerDetailTenantTemplateProps> = ({
  iconSrc,
  serviceName,
  contract,
  onUpdate,
  onUpdateUsageKind,
  extendTrialPermission,
  updateUsageKindPermission,
}) => {
  const classes = useStyles();
  const [extendTrialModalOpen, setExtendTrialModalOpen] = React.useState(false);
  const [contractInfo, setContractInfo] = React.useState<Data[]>([]);
  const [freemiumModalOpen, setFreemiumModalOpen] = React.useState(false);

  React.useEffect(() => {
    const data = [
      {
        label: 'Tenant ID',
        value: contract?.tenantId || '',
      },
      {
        label: 'Tenant Name',
        value: contract?.tenantName || '',
      },
      {
        label: '利用形態',
        value: (
          <>
            {contract?.status || ''}
            {updateUsageKindPermission &&
              contract?.serviceId === 'workflow' &&
              contract.usageKind === 'trial' && (
                <StyledButton
                  className={classes.freemiumBtn}
                  variant="secondary"
                  label="フリープランへ変更"
                  onClick={() => setFreemiumModalOpen(true)}
                />
              )}
          </>
        ),
      },
      {
        label: 'ライセンス数',
        value:
          contract?.volume && contract.volume !== '-1'
            ? contract.volume
            : '---',
      },
      {
        label: '申込日時',
        value:
          contract?.startDate !== undefined
            ? timestamp2Date(contract?.startDate)
            : '',
      },
      {
        label: '利用期限',
        value: (
          <>
            {timestamp2Date(Number(contract?.endDate || 0))}
            {extendTrialPermission &&
              contract?.usageKind === 'trial' &&
              (contract?.serviceId === 'pjb' ||
                contract?.serviceId === 'workflow' ||
                contract?.serviceId === 'chatbot') && (
                <StyledButton
                  className={classes.extendEndDateBtn}
                  variant="primary"
                  label="利用終了日の延長"
                  onClick={() => setExtendTrialModalOpen(true)}
                />
              )}
          </>
        ),
      },
      {
        label: 'コメント',
        value: contract?.note || '',
      },
    ];
    setContractInfo(data);
    return () => setContractInfo([]);
  }, [
    classes.extendEndDateBtn,
    contract,
    extendTrialPermission,
    updateUsageKindPermission,
    classes.freemiumBtn,
  ]);

  const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: theme.spacing(5),
  }));

  const TitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  }));

  return (
    <Root>
      <TitleContainer>
        <img src={iconSrc} className={classes.icon} alt={'service icon'} />
        <Typography component="span" className={classes.serviceName}>
          {serviceName}
        </Typography>
      </TitleContainer>
      <CustomerInfoTable
        className={classes.table}
        title="テナント情報"
        data={contractInfo}
      />
      <ExtendTrialModal
        open={extendTrialModalOpen}
        contractId={contract?.contractId || ''}
        endDate={contract?.endDate || 0}
        note={contract?.note || ''}
        onUpdateClicked={({
          newEndDate,
          note,
        }: {
          newEndDate: string;
          note: string;
        }) => {
          setExtendTrialModalOpen(false);
          if (onUpdate) {
            onUpdate({ newEndDate, note });
          }
        }}
        onCancelClicked={() => setExtendTrialModalOpen(false)}
      />
      <FreemiumModal
        open={freemiumModalOpen}
        contractId={contract.contractId}
        serviceId={contract.serviceId}
        planId={contract.planId}
        contractDateMs={contract.startDate / 1000000}
        onSubmitClicked={(
          contractDateMs: number,
          calculationEndDateMs: number,
          note: string,
        ) => {
          setFreemiumModalOpen(false);
          if (onUpdateUsageKind) {
            onUpdateUsageKind(contractDateMs, calculationEndDateMs, note);
          }
        }}
        onCancelClicked={() => setFreemiumModalOpen(false)}
      />
    </Root>
  );
};
