import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import { Paper } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

interface AlertStyleProps {
  color: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: AlertStyleProps) => ({
    // border: '1px solid',
    borderColor: props.color,
    borderRadius: '5px',
    padding: theme.spacing(1.5, 2),
    display: 'flex',
    alignItems: 'center',
  }),
  icon: (props: AlertStyleProps) => ({
    color: props.color,
    marginRight: theme.spacing(2),
  }),
}));

export type AlertSeverity = 'info' | 'warning';
export interface AlertProps {
  className?: string;
  severity?: AlertSeverity;
}
export const Alert: React.FC<AlertProps> = ({
  className,
  severity = 'info',
  children,
}) => {
  const props = { color: severity === 'warning' ? '#FF9800' : '#4285F4' };
  const classes = useStyles(props);
  return (
    <Paper
      className={`${className} ${classes.root}`}
      variant={'outlined'}
      elevation={0}
    >
      {severity === 'info' && <InfoIcon className={classes.icon} />}
      {severity === 'warning' && <WarningIcon className={classes.icon} />}
      {children}
    </Paper>
  );
};
