import React, { ReactNode } from 'react';
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export type ProcessingDialogState = 'close' | 'wait' | 'error';

interface ErrorMessage {
  title?: ReactNode;
  description?: ReactNode;
}

export interface ProcessingDialogConfig {
  state: ProcessingDialogState;
  errorMessage?: ErrorMessage;
}

export interface ProcessingDialogProps {
  state: ProcessingDialogState;
  errorMessage?: ErrorMessage;
  onClickError?: React.MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.modal + 1,
      color: '#fff',
    },
    errorTitle: {
      '& h2': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        lineHeight: '32px',
      },
      '& svg': {
        color: '#e91e63',
        marginRight: '10px',
      },
      '& div': {
        marginLeft: '11px',
      },
    },
    okButton: {
      color: '#e91e63',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: 'normal',
      transition: 'none !important',
      borderRadius: '2px',
      '&:hover': {
        backgroundColor: '#fce8ef',
      },
    },
    dialogContent: {
      paddingLeft: '80px',
      paddingTop: 0,
    },
  }),
);

export const ProcessingDialog: React.FC<ProcessingDialogProps> = ({
  state,
  errorMessage,
  onClickError = () => {},
}) => {
  const classes = useStyles();

  return (
    <>
      <Backdrop open={state === 'wait'} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog open={state === 'error'}>
        <DialogTitle className={classes.errorTitle}>
          <WarningIcon fontSize="large" />
          <div>
            {errorMessage?.title ?? 'サーバとの通信中に問題が発生しました。'}
          </div>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {errorMessage?.description ??
            'しばらく時間をおいて、再送信してください。'}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickError} className={classes.okButton}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
