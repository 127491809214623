import React from 'react';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Theme,
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { StyledHelperText } from '@bizapp-frontend/management/molecules/form/FormComponent';

export interface FormCheckboxProps extends Omit<CheckboxProps, 'checked'> {
  className?: string;
  id: string;
  label: React.ReactNode;
  helperText?: React.ReactNode;
  value?: boolean;
  setValue?: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  setIsValid?: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  forceValidation?: boolean;
  setForceValidation?: (
    value: boolean | ((prevVar: boolean) => boolean),
  ) => void;
  postChange?: (value: boolean) => void;
}

export const FormCheckbox: React.FC<FormCheckboxProps> = ({
  className,
  label,
  helperText,
  value,
  required,
  setValue,
  setIsValid,
  forceValidation,
  setForceValidation,
  postChange,
  ...props
}) => {
  const [error, setError] = React.useState(false);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      checkbox: {
        margin: 0,
        '& .MuiTypography-body1': {
          marginLeft: theme.spacing(1),
          fontSize: '14px',
          lineHeight: '21px',
          color: '#333333',
        },
        '& .MuiLink-root': {
          color: '#1565C0',
        },
        '& .MuiCheckbox-root': {
          padding: theme.spacing(0),
          color: '#787F86',
          '& svg': {
            width: '21px',
            height: '21px',
          },
        },
      },
    }),
  )();

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setValue && setValue(ev.target.checked);
    postChange && postChange(ev.target.checked);
    if (required) {
      if (ev.target.checked) {
        setError(false);
        setIsValid && setIsValid(true);
      } else {
        setError(true);
        setIsValid && setIsValid(false);
      }
    }
  };

  React.useEffect(() => {
    if (forceValidation && required) {
      if (value) {
        setError(false);
        setIsValid && setIsValid(true);
      } else {
        setError(true);
        setIsValid && setIsValid(false);
      }
      setForceValidation && setForceValidation(false);
    }
  }, [value, required, forceValidation, setIsValid, setForceValidation]);

  return (
    <div className={className}>
      <div>
        <FormControlLabel
          control={<Checkbox checked={value} onChange={handleChange} />}
          label={label}
          className={classes.checkbox}
        />
        {helperText && error && <StyledHelperText children={helperText} />}
      </div>
    </div>
  );
};
