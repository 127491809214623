import React from 'react';

export const ServiceIds = ['pjb'] as const;
export interface DevelopmentMeta {
  currentTimeMs?: number;
}

export type Role =
  | 'bizapp-management-full-access'
  | 'bizapp-management-read-only';

export type State = {
  development: boolean;
  developmentMeta: DevelopmentMeta;
  userId: string;
  emailAddress: string;
  serviceId: typeof ServiceIds[number];
  customerId: string;
  customerName: string;
  activeView: 'NONE' | 'APPLICATION_LIST';
  role: Role;
};

export type Action =
  | { type: 'SET_DEVELOPMENT'; development: boolean }
  | { type: 'SET_DEVELOPMENT_META'; developmentMeta: DevelopmentMeta }
  | { type: 'ACTIVATE_NONE_VIEW' }
  | { type: 'ACTIVATE_APPLICATION_LIST_VIEW' }
  | { type: 'SET_USER_ID'; userId: string }
  | { type: 'SET_EMAIL_ADDRESS'; emailAddress: string }
  | { type: 'SET_ROLE'; role: Role };

export const GlobalsContext = React.createContext(
  {} as {
    state: State;
    dispatch: React.Dispatch<Action>;
  },
);

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_DEVELOPMENT':
      return {
        ...state,
        development: action.development,
      };
    case 'SET_DEVELOPMENT_META':
      return {
        ...state,
        developmentMeta: action.developmentMeta,
      };
    case 'ACTIVATE_NONE_VIEW':
      return {
        ...state,
        activeView: 'NONE',
      };
    case 'SET_USER_ID':
      return {
        ...state,
        userId: action.userId,
      };
    case 'SET_EMAIL_ADDRESS':
      return {
        ...state,
        emailAddress: action.emailAddress,
      };
    case 'SET_ROLE':
      return {
        ...state,
        role: action.role,
      };
    default:
      return state;
  }
};

export const initialState: State = {
  development: false,
  developmentMeta: { currentTimeMs: undefined },
  serviceId: 'pjb',
  customerId: '',
  customerName: '',
  activeView: 'APPLICATION_LIST',
  userId: '',
  emailAddress: '',
  role: 'bizapp-management-read-only',
};
