import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  CustomerDetailTenantListTemplate,
  Service,
} from '@bizapp-frontend/management/templates/CustomerDetailTenantListTemplate';
import { CustomerDetailContext } from '@bizapp-frontend/management/pages/CustomerDetailPage';
import {
  Contract,
  getCurrMonthLastDay,
  isStandardPlan,
  ServiceName,
  ServicePlan,
} from '@bizapp-frontend/management/templates/form/utils';
import {
  filter,
  flow,
  groupBy,
  mapValues,
  sortBy,
  last,
  values,
  map,
} from 'lodash/fp';
import { GlobalsContext } from '@bizapp-frontend/management/globals';

export interface CustomerDetailTenantListPageProps {
  contractBaseUrl: string;
  applicationControllerBaseUrl: string;
}

export const CustomerDetailTenantListPage: React.FC<CustomerDetailTenantListPageProps> = ({
  contractBaseUrl,
  applicationControllerBaseUrl,
}) => {
  const { state: globalState } = React.useContext(GlobalsContext);
  const now = React.useCallback(() => {
    let ms = Date.now();
    if (globalState.development && globalState.developmentMeta.currentTimeMs) {
      ms = globalState.developmentMeta.currentTimeMs;
    }
    return ms;
  }, [globalState.development, globalState.developmentMeta.currentTimeMs]);

  const navigate = useNavigate();

  const { state } = useLocation();

  const { customerId } = useParams();
  const [services, setServices] = React.useState<Service[]>([]);

  const handleServiceClick = (serviceId: string) => {
    navigate(`/customers/${customerId}/services/${serviceId}`, {
      state: state,
    });
  };

  const {
    customerDetailState: { contracts },
  } = React.useContext(CustomerDetailContext);

  const toStatus = React.useCallback(
    (contract: Contract) => {
      const nowNanos = now() * 1000000;
      if (contract.usageKind === 'trial' && nowNanos <= contract.endDate) {
        return 'トライアル利用中'; // "Now using trial service".
      } else if (
        contract.usageKind === 'trial' &&
        nowNanos > contract.endDate
      ) {
        return 'トライアル終了'; // "Trial service ended (but hasn't purchased any paid plan)"
      } else if (
        contract.usageKind === 'purchased'
        // skip check for current release since it is also max_date
        // && contract.endDate === MAX_DATE_MS * 1000000
      ) {
        return '契約中'; // "Purchased a paid plan, and using the service"
      } else if (contract.usageKind === 'freemium') {
        return nowNanos <= contract.endDate
          ? 'フリープラン中'
          : 'フリープラン終了';
      } else {
        return '';
      }
    },
    [now],
  );

  const toExpirationDate = React.useCallback(
    (contract: Contract) => {
      if (contract.usageKind === 'trial' || contract.usageKind === 'freemium') {
        return contract.endDate;
      } else if (contract.usageKind === 'purchased') {
        return getCurrMonthLastDay(now());
      }
    },
    [now],
  );

  React.useEffect(() => {
    setServices(
      flow(
        filter(
          (contract: Contract) =>
            contract.usageKind === 'trial' || isStandardPlan(contract.planId),
        ),
        groupBy((it) => it.serviceId),
        mapValues((contracts) => {
          return flow(
            sortBy((it: Contract) => it.timestamp),
            last,
          )(contracts);
        }),
        values,
        map((contract: Contract) => {
          const servicePlan = ServicePlan.get(contract.serviceId);
          return {
            id: contract.contractId,
            serviceId: contract.serviceId,
            serviceName: ServiceName.get(contract.serviceId),
            planName: servicePlan && servicePlan.get(contract.planId),
            status: toStatus(contract),
            usageKind: contract.usageKind,
            licenseNumber:
              contract.contractKind === 'constant' ? '---' : contract.volume,
            applicationDate: contract.startDate,
            endDate: toExpirationDate(contract),
          } as Service;
        }),
      )(contracts),
    );
  }, [contracts, toExpirationDate, toStatus]);

  return (
    <CustomerDetailTenantListTemplate
      services={services}
      onServiceClick={handleServiceClick}
    />
  );
};
