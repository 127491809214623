import { formatInTimeZone } from 'date-fns-tz';
import { endOfMonth, isAfter } from 'date-fns';

interface serviceInfoType {
  name: string;
}

export const serviceInfo: { [key: string]: serviceInfoType } = {
  chatbot: { name: 'Chatbot' },
  ebm: { name: 'EBM' },
  ess: { name: 'ESS' },
  pjb: { name: 'Project Board' },
  pjr: { name: 'Project Room' },
  workflow: { name: 'Workflow' },
  digitalinvoice: { name: 'HUEデジタルインボイス' },
} as const;
export type ServiceType = keyof typeof serviceInfo;
export const serviceName = (serviceId: ServiceType): string => {
  // for 22.05.hotfix
  const id = serviceId.toString().replace(/-internal$/g, '');
  try {
    if (id === serviceId) {
      return serviceInfo[id].name;
    } else {
      return serviceInfo[id].name + '（Test）';
    }
  } catch {
    return 'Unknown Service';
  }
};

export type PaymentGateway = 'stripe' | 'mfkessai';
export type PaymentMethod = 'bank_transfer' | 'account_transfer';
export type UsageKind = 'trial' | 'purchased' | 'freemium';
export type ContractStatus =
  | 'trial_in_use'
  | 'trial_expired'
  | 'purchased'
  | 'freemium_in_use'
  | 'freemium_expired';
export type GatewayVariant = 'wape' | 'waps' | 'unknown';

export const gatewayVariantName = (
  gatewayVariant: GatewayVariant | undefined,
): string => {
  switch (gatewayVariant) {
    case 'wape':
      return 'WAPE';
    case 'waps':
      return 'WAPS';
    default:
      return '';
  }
};

export interface Contract {
  tenantId?: string;
  serviceId: ServiceType;
  serviceName?: string;
  usageKind: UsageKind;
  delete?: boolean;
  hidden?: boolean;
  endDate: number;
}

export interface Customer {
  customerId: string;
  companyName: string;
  contracts: Contract[];
  delete?: boolean;
  hidden?: boolean;
  paymentGatewayId?: PaymentGateway;
  paymentMethod?: PaymentMethod;
  registeredDate: number;
  userId?: string;
}

export const contractStatus = (
  usageKind: UsageKind,
  endDate: number,
): ContractStatus => {
  switch (usageKind) {
    case 'purchased':
      return 'purchased';
    case 'trial':
      return isTrialExpired(endDate) ? 'trial_expired' : 'trial_in_use';
    case 'freemium':
      return isFreemiumExpired(endDate)
        ? 'freemium_expired'
        : 'freemium_in_use';
  }
};

export const contractStatusName = (contractStatus: ContractStatus): string => {
  switch (contractStatus) {
    case 'trial_in_use':
      return 'トライアル中';
    case 'purchased':
      return '契約中';
    case 'trial_expired':
      return 'トライアル終了';
    case 'freemium_in_use':
      return 'フリープラン中';
    case 'freemium_expired':
      return 'フリープラン終了';
  }
};

export const paymentMethod = (
  paymentGatewayId?: PaymentGateway,
  paymentMethod?: PaymentMethod,
): string => {
  if (paymentGatewayId === 'stripe') {
    return 'クレジットカード';
  } else if (paymentGatewayId === 'mfkessai') {
    if (!paymentMethod) {
      // basically this case won't happen
      return '不明';
    } else if (paymentMethod === 'bank_transfer') {
      return '振込';
    } else if (paymentMethod === 'account_transfer') {
      return '口座振替';
    }
  }
  // no payment_gateway_id, which means it's a trial customer
  return '---';
};

const NANO_SECONDS_FACTOR = 1000000;
const JP_TIME_ZONE = 'Asia/Tokyo';
const DATE_FORMAT = 'yyyy/MM/dd';
export const timestamp2ZonedDate = (timestamp: number): string => {
  if (!timestamp) {
    return '';
  }
  const date = new Date(timestamp / NANO_SECONDS_FACTOR);
  return formatInTimeZone(date, JP_TIME_ZONE, DATE_FORMAT);
};

export const endOfCurrentMonth = (): string => {
  const endDate = endOfMonth(new Date()).setHours(0, 0, 0, 0);
  return formatInTimeZone(endDate, JP_TIME_ZONE, DATE_FORMAT);
};

export const isTrialExpired = (timestamp: number): boolean => {
  return isAfter(new Date(), new Date(timestamp / NANO_SECONDS_FACTOR));
};

export const isFreemiumExpired = (timestamp: number): boolean => {
  return isAfter(new Date(), new Date(timestamp / NANO_SECONDS_FACTOR));
};

export const DummyData = {
  customerMap: {
    c1: {
      customerId: 'c1',
      companyName: 'エレファス株式会社',
      contracts: [
        {
          serviceId: 'pjb',
          usageKind: 'trial',
          endDate: 1644246041539143400,
        },
      ],
      registeredDate: 1643986865254470700,
      userId: 'user',
    },
    c2: {
      customerId: 'c2',
      companyName: '中旗商事株式会社',
      contracts: [
        {
          serviceId: 'chatbot',
          usageKind: 'purchased',
          endDate: 1644246041539143400,
        },
      ],
      paymentGatewayId: 'mfkessai',
      paymentMethod: 'account_transfer',
      registeredDate: 1643986865254470700,
      userId: 'user',
    },
    c3: {
      customerId: 'c3',
      companyName: '株式会社アーバン計器',
      contracts: [
        {
          serviceId: 'pjb',
          usageKind: 'trial',
          endDate: 1644246041539143400,
        },
        {
          serviceId: 'chatbot',
          usageKind: 'trial',
          endDate: 1648286085906072600,
        },
        {
          serviceId: 'ebm',
          usageKind: 'trial',
          endDate: 1648286085906072600,
        },
      ],
      registeredDate: 1643986865254470700,
      userId: 'user',
    },
    c4: {
      customerId: 'c4',
      companyName: '株式会社石井田インテリア',
      contracts: [
        {
          serviceId: 'ess',
          usageKind: 'trial',
          endDate: 1644246041539143400,
        },
      ],
      registeredDate: 1643986865254470700,
      userId: 'user',
    },
    c5: {
      customerId: 'c5',
      companyName: '株式会社亜砂呂',
      contracts: [
        {
          serviceId: 'pjr',
          usageKind: 'purchased',
          endDate: 1644246041539143400,
        },
      ],
      paymentGatewayId: 'mfkessai',
      paymentMethod: 'bank_transfer',
      registeredDate: 1643986865254470700,
      userId: 'user',
    },
    c6: {
      customerId: 'c6',
      companyName: '株式会社アッシュケー',
      contracts: [
        {
          serviceId: 'workflow',
          usageKind: 'purchased',
          endDate: 1644246041539143400,
        },
      ],
      paymentGatewayId: 'stripe',
      registeredDate: 1643986865254470700,
      userId: 'user',
    },
  },
  lastEvaluatedKey: '',
  validCount: 0,
};
