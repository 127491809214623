import React from 'react';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import * as _ from 'lodash';

import {
  StyledInputLabel,
  StyledHelperText,
} from '@bizapp-frontend/management/molecules/form/FormComponent';
import { requiredFormInputValidator } from '@bizapp-frontend/management/molecules/form/FormInput';

export interface InputCheckboxType {
  id: string;
  label: string;
}

export interface FormCheckboxGroupValueType {
  [key: string]: {
    checked: boolean;
    value: React.ReactNode;
  };
}

export interface FormCheckboxGroupProps {
  className?: string;
  id: string;
  label?: React.ReactNode;
  required?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  items?: InputCheckboxType[];
  value?: FormCheckboxGroupValueType;
  setValue?: (
    value:
      | FormCheckboxGroupValueType
      | ((prevVar: FormCheckboxGroupValueType) => FormCheckboxGroupValueType),
  ) => void;
  setIsValid?: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  forceValidation?: boolean;
  setForceValidation?: (
    value: boolean | ((prevVar: boolean) => boolean),
  ) => void;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'block',
    },
    checkboxGroup: {
      '& .MuiCheckbox-root': { color: '#787F86' },
      '& .Mui-checked': { color: '#787F86' },
    },
    checkbox: {
      padding: theme.spacing(0),
      '& svg': {
        width: '21px',
        height: '21px',
      },
    },
    ctrLabel: {
      margin: theme.spacing(1.125, 0, 0, 0),
      '&:first-child': {
        marginTop: 0,
      },
      '& .MuiTypography-body1': {
        marginLeft: theme.spacing(1),
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: 0,
        color: '#333333',
      },
    },
    helperText: {
      marginTop: theme.spacing(1),
    },
  }),
);

export const FormCheckboxGroup: React.FC<FormCheckboxGroupProps> = ({
  className = '',
  id,
  label,
  required = false,
  helperText,
  items,
  value,
  setValue,
  setIsValid,
  forceValidation,
  setForceValidation,
  ...props
}) => {
  const [error, setError] = React.useState(props.error ?? false);
  const [helper, setHelper] = React.useState(helperText ?? '');

  const classes = useStyles();

  const handleChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    let newValue: FormCheckboxGroupValueType = {};
    if (value !== undefined) {
      newValue = {
        ...value,
        [ev.target.name]: {
          ...value[ev.target.name],
          checked: checked,
        },
      };
    }
    setValue && setValue(newValue);

    if (required) {
      if (_.some(_.values(newValue).map((v) => v.checked))) {
        setError(false);
        setIsValid && setIsValid(true);
      } else {
        setError(true);
        setIsValid && setIsValid(false);
      }
    }
  };

  React.useEffect(() => {
    if (forceValidation && required) {
      if (_.some(_.values(value).map((v) => v.checked))) {
        setError(false);
        setIsValid && setIsValid(true);
      } else {
        setError(true);
        setIsValid && setIsValid(false);
      }
      setForceValidation && setForceValidation(false);
    }
  }, [value, required, forceValidation, setIsValid, setForceValidation]);

  React.useEffect(() => {
    if (helperText) {
      setHelper(helperText);
    } else if (required) {
      setHelper(requiredFormInputValidator.helperText);
    }
  }, [helperText, required]);

  return (
    <FormControl
      component="fieldset"
      required={required}
      error={error}
      className={`${classes.root} ${className}`}
    >
      {label && <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>}
      <FormGroup className={classes.checkboxGroup}>
        {items &&
          items.map((v) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={(value && value[v.id]?.checked) ?? false}
                    name={v.id}
                    onChange={handleChange}
                    className={classes.checkbox}
                  />
                }
                label={v.label}
                key={v.id}
                className={classes.ctrLabel}
              />
            );
          })}
      </FormGroup>
      {helper && error && (
        <StyledHelperText children={helper} className={classes.helperText} />
      )}
    </FormControl>
  );
};
