import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as _ from 'lodash';

import { FormButton } from '@bizapp-frontend/management/molecules/form/FormButton';
import { StyledModal } from '@bizapp-frontend/management/molecules/StyledModal';
import {
  FormComponentProps,
  FormGenerator,
  initFormDataPairs,
} from '@bizapp-frontend/management/organisms/form/FormGenerator';
import {
  dateStrToJSTMs,
  Plans,
  PlanType,
  ServiceName,
  ServicePlan,
  ServiceType,
  jstMsToLocalDateStr,
  toNextYearMs,
  addingEndDateSeconds,
} from '@bizapp-frontend/management/templates/form/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    head: {
      padding: theme.spacing(3, 2, 3, 2),
    },
    headTitle: {
      display: 'block',
      color: '#333333',
      fontSize: 20,
    },
    alert: {
      margin: theme.spacing(0, 2, 4, 0),
    },
    foot: {
      display: 'flex',
      justifyContent: 'flex-end',
      width: '100%',
      padding: theme.spacing(2.75, 2),
      borderTop: '#DCE0E7 solid 1px',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: theme.spacing(109),
      maxHeight: '100%',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 16px 32px #00000014',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 5,

      '&:focus': {
        outline: 'none',
      },
    },
    title: {
      display: 'block',
      marginBottom: theme.spacing(4),
      color: '#333333',
      fontSize: 17,
      fontWeight: 'bold',
    },
    content: {
      minHeight: 300,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'overlay',
      padding: theme.spacing(0, 2, 0, 2),
    },
    form: {
      marginBottom: theme.spacing(3),
    },
    spacer: {
      flex: '1 1 auto',
    },
    cancelBtn: {
      color: '#7F7F7F',
      fontSize: 14,
      lineHeight: '21px',
      fontWeight: 'bold',
      padding: 0,
    },
    submitBtn: {
      '& Button': {
        color: '#4285F4',
        alignSelf: 'center',
        fontSize: 14,
        lineHeight: '21px',
        padding: 0,
        '&.Mui-disabled': {
          color: '#4285f442',
        },
      },
    },
    label: {
      fontSize: '14px',
      color: '#777777',
      marginBottom: theme.spacing(1),
      padding: 0,
    },
    value: {
      fontSize: '16px',
      color: '#333333',
    },
  }),
);

type FormData = {
  [key: string]: number | string | undefined;
};

export interface FreemiumModalProps {
  open: boolean;
  contractId: string;
  serviceId: string;
  planId: string;
  contractDateMs: number;
  onCancelClicked: Function;
  onSubmitClicked: Function;
}

export const FreemiumModal: React.FC<FreemiumModalProps> = ({
  open,
  contractId,
  serviceId,
  planId,
  contractDateMs,
  onCancelClicked,
  onSubmitClicked,
}) => {
  const classes = useStyles();
  const [formData, setFormData] = React.useState<FormData>({});
  const [contractDate, setContractDate] = React.useState(
    jstMsToLocalDateStr(contractDateMs),
  );
  const [endDate, setEndDate] = React.useState(
    jstMsToLocalDateStr(toNextYearMs(contractDate)),
  );

  const getFormComponents = (): FormComponentProps[][] => {
    return [
      [
        {
          kind: 'select-normal',
          id: 'service-id',
          label: 'サービス',
          required: true,
          disabled: true,
          size: 'medium',
          options: ServiceName,
          value: serviceId,
        },
        {
          kind: 'select-normal',
          id: 'plan-id',
          label: 'プラン',
          required: true,
          disabled: true,
          size: 'medium',
          options: ServicePlan.get(serviceId as ServiceType),
          value: planId,
        },
      ],
      [
        {
          kind: 'datepicker-normal',
          id: 'contract-date',
          label: '契約日',
          required: true,
          size: 'short',
          minValue: contractDate,
          postChange: (_contractDate) => {
            setContractDate(_contractDate);
            setEndDate(jstMsToLocalDateStr(toNextYearMs(_contractDate)));
            setIsValids &&
              setIsValids((prev) => ({
                ...prev,
                'contract-date': true,
              }));
          },
        },
        {
          kind: 'datepicker-normal',
          id: 'end-date',
          label: 'フリープラン終了日',
          required: true,
          size: 'short',
          minValue: formData['contract-date'] || contractDate,
          value: endDate,
          postChange: (_endDate) => {
            setEndDate(_endDate);
            setIsValids &&
              setIsValids((prev) => ({
                ...prev,
                'end-date': true,
              }));
          },
        },
      ],
      [
        {
          kind: 'input-normal',
          id: 'note',
          label: 'コメント',
          multiline: true,
          rows: 2,
        },
      ],
    ];
  };

  const [formComponentProps] = React.useState(getFormComponents());

  const [kinds] = React.useState(
    _.flatMapDeep(formComponentProps).map((v) => v),
  );
  const getInitValids = React.useCallback(() => {
    return _.fromPairs(kinds.map((v) => [v.id, false]));
  }, [kinds]);
  const [forceValidation, setForceValidation] = React.useState(false);
  const [isAllValid, setIsAllValid] = React.useState(true);
  const [isValids, setIsValids] = React.useState(getInitValids());
  React.useEffect(() => {
    setIsAllValid(Object.values(isValids).every((it) => it));
  }, [isValids]);

  React.useEffect(() => {
    initFormDataPairs(kinds);
    const _formData = {
      'service-id': ServiceName.get(serviceId as ServiceType),
      'plan-id': Plans.get(planId as PlanType)?.planName,
      'contract-date': contractDate,
      'end-date': endDate,
      note: '',
    };
    setFormData(_formData);
    setForceValidation(true);
  }, [contractId, kinds, serviceId, planId, contractDate, endDate]);

  const handleCancel = () => onCancelClicked();

  const handleSubmit = () => {
    if (formData['contract-date'] && formData['end-date']) {
      const contractDateMs = dateStrToJSTMs(
        formData['contract-date'] as string,
      );
      const calculationEndDateMs = addingEndDateSeconds(
        dateStrToJSTMs(formData['end-date'] as string),
      );
      onSubmitClicked(contractDateMs, calculationEndDateMs, formData['note']);
    }
  };

  return (
    <>
      <StyledModal open={open}>
        <div className={classes.root}>
          <Typography component="span" className={classes.head}>
            <Typography className={classes.headTitle}>
              フリープラン登録
            </Typography>
          </Typography>
          <Box className={classes.content}>
            <Typography component="span" className={classes.title}>
              契約概要
            </Typography>
            <FormGenerator
              className={classes.form}
              formData={formData}
              setFormData={setFormData}
              formComponents={formComponentProps}
              isValids={isValids}
              setIsValids={setIsValids}
              forceValidation={forceValidation}
              setForceValidation={setForceValidation}
            />
          </Box>
          <Box className={classes.foot}>
            <Button className={classes.cancelBtn} onClick={handleCancel}>
              キャンセル
            </Button>
            <div className={classes.spacer} />
            <FormButton
              id={'submit-btn'}
              label={'登録'}
              variant={'outlined'}
              className={classes.submitBtn}
              disabled={!isAllValid}
              isValids={isValids}
              forceValidation={forceValidation}
              setForceValidation={setForceValidation}
              postClick={handleSubmit}
            />
          </Box>
        </div>
      </StyledModal>
    </>
  );
};
