import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { theme } from '@bizapp-frontend/management/styles/theme';

const StyledPaper = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
}))(Paper);

const StyledTableHeader = withStyles((theme) => ({
  head: {
    fontSize: 14,
    color: '#7F7F7F',
    fontWeight: 'bold',
    paddingTop: theme.spacing(2.375),
    paddingBottom: theme.spacing(1.2),
    '&:first-child': {
      paddingLeft: theme.spacing(9),
    },
  },
}))(TableCell);

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
    color: '#333333',
    fontWeight: 'bold',
    paddingTop: theme.spacing(1.2),
    paddingBottom: theme.spacing(1.2),
    '&:first-child': {
      paddingLeft: theme.spacing(9),
    },
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:first-child': {
      borderTop: '2px solid #7F7F7F',
    },
    '&:last-child': {
      borderBottom: '2px solid #7F7F7F',
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    paddingLeft: theme.spacing(9),
  },
});

export interface Customer {
  companyName: string;
  userName: string;
  email: string;
  jobTitle: string;
  customerId: string;
  userId: string;
  timestamp: string;
  endDate: string;
}

export interface CustomerListProps {
  headers: string[];
  customers: Customer[];
  onRowClick?: Function;
}

export const CustomerList: React.FC<CustomerListProps> = ({
  headers,
  customers,
  onRowClick,
}) => {
  const classes = useStyles();
  const tableRef = React.useRef(null);

  const handleClick = (index: number) => {
    if (onRowClick) {
      onRowClick(index);
    }
  };

  const Headers: React.FC = () => {
    return (
      <TableHead>
        <TableRow>
          {headers.map((header, i) => (
            <StyledTableHeader key={i}>{header}</StyledTableHeader>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  const Body: React.FC = () => {
    return (
      <TableBody>
        {customers.map((customer, i) => (
          <StyledTableRow
            key={customer.customerId}
            onClick={(e) => handleClick(i)}
            hover={true}
          >
            <StyledTableCell>{customer.timestamp}</StyledTableCell>
            <StyledTableCell>{customer.companyName}</StyledTableCell>
            <StyledTableCell>{customer.userName}</StyledTableCell>
            <StyledTableCell>{customer.email}</StyledTableCell>
            <StyledTableCell>{customer.jobTitle}</StyledTableCell>
            <StyledTableCell>{customer.customerId}</StyledTableCell>
            <StyledTableCell>{customer.endDate}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    );
  };

  return (
    <TableContainer component={StyledPaper} ref={tableRef}>
      <Table className={classes.table}>
        <Headers />
        <Body />
      </Table>
    </TableContainer>
  );
};
