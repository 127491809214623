import React from 'react';
import { Container, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { PageTemplate } from '@bizapp-frontend/management/templates/PageTemplate';

interface CompleteStyleProps {
  themeColor?: string;
}

const useStyle = makeStyles<Theme, CompleteStyleProps>((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#ffffff',
      textAlign: 'center',
    },
    header: {
      padding: theme.spacing(5, 2),
      backgroundColor: '#F2F3FB',
    },
    message: (props: CompleteStyleProps) => ({
      padding: theme.spacing(5, 2, 0),
      textAlign: 'center',
      '& .MuiTypography-h2': {
        color: props.themeColor ?? '#333333',
      },
      '& p': {
        margin: theme.spacing(3, 'auto', 5),
        maxWidth: '448px',
        '& span': {
          display: 'block',
        },
      },
    }),
    link: {
      display: 'block',
      margin: theme.spacing(10, 'auto', 8),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'normal',
    },
  }),
);

export interface CompleteProps extends CompleteStyleProps {
  className?: string;
  heading1?: React.ReactNode;
  heading2?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
}

export const PageListTemplate: React.FC<CompleteProps> = ({
  className,
  themeColor,
  heading1,
  heading2,
  description,
  children,
}: CompleteProps) => {
  const classes = useStyle({ themeColor: themeColor });
  return (
    <>
      <PageTemplate>
        <Container
          disableGutters
          maxWidth={false}
          className={`${classes.root} ${className ?? ''}`}
        >
          <Container
            component="header"
            disableGutters
            maxWidth={false}
            className={classes.header}
          >
            <Typography variant="h1">{heading1}</Typography>
          </Container>
          <Container
            component="section"
            disableGutters
            maxWidth={false}
            className={classes.message}
          >
            <Typography variant="h2">{heading2}</Typography>
            {description && (
              <Typography variant="body1">{description}</Typography>
            )}
          </Container>
          {children}
        </Container>
      </PageTemplate>
    </>
  );
};
