import React, { ReactNode } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export interface WarningDialogProps {
  show: boolean;
  errorMessage?: ReactNode;
  onClickOK?: React.MouseEventHandler<HTMLButtonElement>;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
  okLabel?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {},
    dialogContent: {
      width: 578,
      // height: 110,
      padding: theme.spacing(0, 2, 0, 2),
      display: 'flex',
      alignItems: 'center',
      '& h2': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        lineHeight: '32px',
      },
      '& svg': {
        color: '#e91e63',
        marginRight: '10px',
      },
      '& div': {
        marginLeft: '11px',
      },
    },
    okButton: {
      color: '#e91e63',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: 'normal',
      transition: 'none !important',
      borderRadius: '2px',
      '&:hover': {
        backgroundColor: '#fce8ef',
      },
    },
    cancelButton: {
      color: '#7F7F7F',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: 'normal',
      transition: 'none !important',
      borderRadius: '2px',
    },
  }),
);

export const WarningDialog: React.FC<WarningDialogProps> = ({
  show,
  errorMessage,
  onClickOK = () => {},
  onClickCancel = () => {},
  okLabel,
}) => {
  const classes = useStyles();

  return (
    <>
      <Dialog open={show}>
        <DialogContent className={classes.dialogContent}>
          <WarningIcon fontSize="large" />
          {errorMessage}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickOK} className={classes.okButton}>
            {okLabel ?? 'OK'}
          </Button>
          <Button onClick={onClickCancel} className={classes.cancelButton}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
