import React from 'react';
import { Link as RouterLink, Outlet, useNavigate } from 'react-router-dom';
import {
  AppBar,
  IconButton,
  CssBaseline,
  Theme,
  Toolbar,
  Typography,
  Link,
} from '@material-ui/core';
import * as _ from 'lodash';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Menu, MenuLink } from '@bizapp-frontend/management/organisms/Menu';
import {
  gatewayVariantName,
  GatewayVariant,
} from '@bizapp-frontend/management/pages/utils';

export type Tenant = {
  customerId: string;
  serviceId: string;
  tenantId: string;
  tenantName: string;
  timestamp: number;
};

export type Company = {
  companyName: string;
  companyNameKana: string;
  zipCode: string;
  prefecture: string;
  address: string;
  tel: string;
  timestamp: number;
};

export type User = {
  userId: string;
  userName: string;
  userNameKana: string;
  department: string;
  jobTitle: string;
  email: string;
  tel: string;
  timestamp: number;
};

const drawerWidth = 200;

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: '#FFFFFF',
      minHeight: '100vh',
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      boxShadow: 'none',
      backgroundColor: '#ffffff',
    },
    toolbarContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingLeft: theme.spacing(7),
      paddingTop: theme.spacing(7),
    },
    toolbar: {
      minHeight: theme.spacing(12.5),
    },
    nav: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: drawerWidth,
      backgroundColor: '#FCFDFF',
      height: '100vh',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(3),
    },
    navHeader: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(8),
    },
    content: {
      flexGrow: 1,
    },
    outlet: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    customerId: {
      display: 'block',
      fontSize: '9px',
      color: '#7F7F7F',
    },
    customerName: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '20px',
      color: '#333333',
      fontWeight: 'bold',
    },
    devModeField: {
      border: 'orange solid 1px',
      backgroundColor: 'yellow',
    },
    gatewayVariant: {
      display: 'flex',
      justifyContent: 'center',
      width: theme.spacing(8),
      height: theme.spacing(3),
      marginLeft: theme.spacing(2),
      padding: theme.spacing(0.125, 1.5, 0.25, 1.5),
      fontSize: '14px',
      fontWeight: 'normal',
      border: '1px solid',
      borderRadius: theme.spacing(1.5),
    },
    waps: {
      color: '#8BC34A',
      borderColor: '#8BC34A',
    },
    wape: {
      color: '#03A9F4',
      borderColor: '#03A9F4',
    },
  }),
);

export interface CustomerDetailTemplateProps {
  links: MenuLink[];
  customerId?: string;
  companyName?: string;
  gatewayVariant?: GatewayVariant;
  backUrl?: string;
  children?: React.ReactNode;
  devNow?: number;
}

export const CustomerDetailTemplate: React.FC<CustomerDetailTemplateProps> = ({
  links,
  customerId,
  companyName,
  gatewayVariant,
  backUrl,
  devNow,
  children,
}) => {
  const navigate = useNavigate();
  const classes = useStyle();

  const handleBack = () => {
    // eslint returns error even if _.isEmpty return true whtn it's undefined
    navigate(
      backUrl !== undefined && !_.isEmpty(backUrl) ? backUrl : 'customers',
    );
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} color="transparent">
        <Toolbar className={classes.toolbarContent}>
          <Typography component="span" className={classes.customerId}>
            {`ID: ${customerId}`}
          </Typography>
          <Typography component="span" className={classes.customerName}>
            {companyName}
            {gatewayVariant !== 'unknown' && (
              <span
                className={`${classes.gatewayVariant} ${
                  gatewayVariant === 'wape' ? classes.wape : classes.waps
                }`}
              >
                {gatewayVariantName(gatewayVariant)}
              </span>
            )}
          </Typography>
          {devNow && (
            <>
              <div className={classes.devModeField}>
                <Typography title="Only show in dev mode">
                  Development Mode
                </Typography>
                <Typography>
                  {'Time: ' + new Date(devNow).toLocaleString()}
                </Typography>
                <Link component={RouterLink} to={`/internal/page-list`}>
                  Back to internal page list
                </Link>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
      <nav className={classes.nav}>
        <div className={classes.navHeader}>
          <IconButton size="small" onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <Menu links={links} />
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.outlet}>
          <Outlet />
        </div>
      </main>
    </div>
  );
};
