import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { filter, map, orderBy, values } from 'lodash';

import Meta from '@bizapp-frontend/management/organisms/Meta';
import { CustomerAccountListTemplate } from '@bizapp-frontend/management/templates/CustomerAccountListTemplate';
import { ManagementDashboardDataContext } from '@bizapp-frontend/management/pages/ManagementDashPage';
import { Customer } from '@bizapp-frontend/management/pages/utils';

const FETCH_CUSTOMER_LIST_API_KEY = 'fetch_customer_list';

const useInit = (callback: Function) => {
  const [mounted, setMounted] = React.useState(false);

  const resetInit = () => setMounted(false);

  React.useEffect(() => {
    if (!mounted) {
      setMounted(true);
      callback();
    }
  }, [mounted, callback]);

  return [resetInit];
};

export interface CustomerAccountListPageProps {
  viewControllerBaseUrl: string;
}

export const CustomerAccountListPage: React.FC<CustomerAccountListPageProps> = ({
  viewControllerBaseUrl,
}) => {
  const title = '顧客台帳 - HUE Works Suite マネジメントサイト';

  const { pathname } = useLocation();
  const [lastEvaluatedKey, setLastEvaluatedKey] = React.useState('');
  const [reachEnd, setReachEnd] = React.useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { managementDashboardDataState, dispatch } = React.useContext(
    ManagementDashboardDataContext,
  );
  const {
    customers,
    totalCustomerNumber,
    loadingStatus,
  } = managementDashboardDataState;
  const isLoading =
    loadingStatus[pathname]?.[FETCH_CUSTOMER_LIST_API_KEY] === 'wait';
  const orderedCustomers = React.useMemo(
    () =>
      orderBy(
        filter(customers, (customer) => !customer.delete && !customer.hidden),
        ['registeredDate'],
        ['desc'],
      ),
    [customers],
  );

  const fetchCustomerList = React.useCallback(
    async (reset: boolean) => {
      if (reachEnd || isLoading) {
        return;
      }
      try {
        const accessToken = await getAccessTokenSilently();
        const url = `${viewControllerBaseUrl}/api/v1/view-controller/view-customer-ledger?isAsc=false&limit=50&exclusiveStartKey=${encodeURIComponent(
          lastEvaluatedKey,
        )}`;
        dispatch({
          type: 'SET_LOADING_STATUS',
          loadingStatus: { FETCH_CUSTOMER_LIST_API_KEY: 'wait' },
          pathname: pathname,
        });
        const resp = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (resp.status === 200) {
          const res = await resp.json();
          if (res.lastEvaluatedKey === '{}') {
            setReachEnd(true);
          }
          setLastEvaluatedKey(res.lastEvaluatedKey);
          const customers: Customer[] = map(
            orderBy(values(res.customerMap), ['registeredDate'], ['desc']),
            (customer) => {
              return customer as Customer;
            },
          );
          dispatch({
            type: 'SET_TOTAL_CUSTOMER_NUMBER',
            totalCustomerNumber: res.validCount as number,
          });
          dispatch({
            type: 'SET_CUSTOMERS',
            customers: customers,
            reset: reset,
          });
          dispatch({
            type: 'SET_LOADING_STATUS',
            loadingStatus: { FETCH_CUSTOMER_LIST_API_KEY: 'close' },
            pathname: pathname,
          });
        } else {
          dispatch({
            type: 'SET_LOADING_STATUS',
            loadingStatus: { FETCH_CUSTOMER_LIST_API_KEY: 'error' },
            pathname: pathname,
          });
        }
      } catch (e) {
        dispatch({
          type: 'SET_LOADING_STATUS',
          loadingStatus: { FETCH_CUSTOMER_LIST_API_KEY: 'error' },
          pathname: pathname,
        });
      }
    },
    [
      viewControllerBaseUrl,
      pathname,
      isLoading,
      reachEnd,
      getAccessTokenSilently,
      dispatch,
      lastEvaluatedKey,
    ],
  );

  useInit(() => fetchCustomerList(true));

  const handleOnCustomerClick = (index: number) => {
    window.open(
      `/customers/${customers[index].customerId}/info?backUrl=${btoa(
        pathname,
      )}`,
      '_blank',
    );
  };

  const handleOnScrollEnd = () => fetchCustomerList(false);

  return (
    <>
      <Meta>
        <title>{title}</title>
        <meta property="og:title" content={title} />
      </Meta>
      <CustomerAccountListTemplate
        customers={orderedCustomers}
        totalCustomerNumber={totalCustomerNumber}
        onCustomerClick={handleOnCustomerClick}
        onScrollEnd={handleOnScrollEnd}
      />
    </>
  );
};
