import React from 'react';
import {
  createStyles,
  makeStyles,
  styled,
  Theme,
} from '@material-ui/core/styles';
import {
  Data,
  CustomerInfoTable,
} from '@bizapp-frontend/management/organisms/CustomerInfoTable';
import {
  Company,
  User,
} from '@bizapp-frontend/management/templates/CustomerDetailTemplate';

const NANO_SECONDS_FACTOR = 1000000;

const timestamp2Date = (timestamp: number) => {
  if (!timestamp) {
    return '';
  }
  return new Date(timestamp / NANO_SECONDS_FACTOR).toLocaleString('ja-JP', {
    timeZone: 'Asia/Tokyo',
  });
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      marginTop: theme.spacing(4),
      maxWidth: theme.spacing(86),
    },
  }),
);

export interface CustomerDetailInfoTemplateProps {
  company?: Company;
  user?: User;
}

export const CustomerDetailInfoTemplate: React.FC<CustomerDetailInfoTemplateProps> = ({
  company,
  user,
}) => {
  const classes = useStyles();

  const [companyInfo, setCompanyInfo] = React.useState<Data[]>([]);
  const [userInfo, setUserInfo] = React.useState<Data[]>([]);

  React.useEffect(() => {
    setCompanyInfo([
      {
        label: '会社・団体名',
        value: company?.companyName || '',
      },
      {
        label: '会社・団体名（フリガナ）',
        value: company?.companyNameKana || '',
      },
      {
        label: '郵便番号',
        value: company?.zipCode || '',
      },
      {
        label: '都道府県',
        value: company?.prefecture || '',
      },
      {
        label: '所在地',
        value: company?.address || '',
      },
      {
        label: '電話番号',
        value: company?.tel || '',
      },
      {
        label: '登録日時',
        value: timestamp2Date(Number(company?.timestamp || 0)),
      },
    ]);
    return () => setCompanyInfo([]);
  }, [company]);

  React.useEffect(() => {
    setUserInfo([
      {
        label: 'ユーザID',
        value: user?.userId || '',
      },
      {
        label: '氏名',
        value: user?.userName || '',
      },
      {
        label: '氏名（フリガナ）',
        value: user?.userNameKana || '',
      },
      {
        label: '所属',
        value: user?.department || '',
      },
      {
        label: '役職',
        value: user?.jobTitle || '',
      },
      {
        label: 'メールアドレス',
        value: user?.email || '',
      },
      {
        label: '携帯番号',
        value: user?.tel || '',
      },
      {
        label: '登録日時',
        value: timestamp2Date(Number(user?.timestamp || 0)),
      },
    ]);
    return () => setUserInfo([]);
  }, [user]);

  const Root = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: theme.spacing(1),
  }));

  return (
    <>
      <Root>
        <CustomerInfoTable
          className={classes.table}
          title="会社・団体情報"
          data={companyInfo}
        />
        <CustomerInfoTable
          className={classes.table}
          title="担当者情報"
          data={userInfo}
        />
      </Root>
    </>
  );
};
