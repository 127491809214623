import React from 'react';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { CustomerDetailContext } from '@bizapp-frontend/management/pages/CustomerDetailPage';
import {
  CustomerDetailTenantTemplate,
  ServiceDetailContract,
} from '@bizapp-frontend/management/templates/CustomerDetailTenantTemplate';

import { StyledSnackbar } from '@bizapp-frontend/management/molecules/StyledSnackbar';
import { GlobalsContext, Role } from '@bizapp-frontend/management/globals';
import {
  Contract,
  isStandardPlan,
  ServiceName,
  ServiceType,
} from '@bizapp-frontend/management/templates/form/utils';
import { flow, filter, sortBy, last } from 'lodash/fp';
import {
  MAX_DATE_MARK,
  MAX_DATE_NS,
} from '@bizapp-frontend/management/templates/form/utils';

const NANO_SECONDS_FACTOR = 1000000;

export interface CustomerDetailTenantPageProps {
  contractBaseUrl: string;
  applicationControllerBaseUrl: string;
}

export const CustomerDetailTenantPage: React.FC<CustomerDetailTenantPageProps> = ({
  contractBaseUrl,
  applicationControllerBaseUrl,
}) => {
  const customerDetail = React.useContext(CustomerDetailContext);
  const { tenants } = customerDetail.customerDetailState;
  const { customerId, serviceId } = useParams();
  const [contract, setContract] = React.useState<ServiceDetailContract>({
    contractId: '',
    tenantId: '',
    tenantName: '',
    usageKind: '',
    volume: '---',
    startDate: 0,
    endDate: 0,
    note: '',
    serviceId: '',
    planId: '',
    status: '',
  });
  const { getAccessTokenSilently } = useAuth0();
  const [
    updateSuccessSnackbarOpen,
    setUpdateSuccessSnackbarOpen,
  ] = React.useState(false);
  const [
    updateFreemiumSuccessSnackbarOpen,
    setUpdateFreemiumSuccessSnackbarOpen,
  ] = React.useState(false);
  const { state: contextState } = React.useContext(GlobalsContext);
  const {
    customerDetailState: { contracts },
    dispatch: dispatchCustomerDetail,
  } = React.useContext(CustomerDetailContext);
  const role: Role = contextState.role;
  const extendTrialPermission = role === 'bizapp-management-full-access';
  const updateUsageKindPermission = role === 'bizapp-management-full-access';

  const { state: globalState } = React.useContext(GlobalsContext);
  const now = React.useCallback(() => {
    let ms = Date.now();
    if (globalState.development && globalState.developmentMeta.currentTimeMs) {
      ms = globalState.developmentMeta.currentTimeMs;
    }
    return ms;
  }, [globalState.development, globalState.developmentMeta.currentTimeMs]);

  const toStatus = React.useCallback(
    (usageKind: string, endDate: number) => {
      const nowNanos = now() * 1000000;
      if (usageKind === 'trial' && nowNanos <= endDate) {
        return 'トライアル利用中'; // "Now using trial service".
      } else if (usageKind === 'trial' && nowNanos > endDate) {
        return 'トライアル終了'; // "Trial service ended (but hasn't purchased any paid plan)"
      } else if (
        usageKind === 'purchased'
        // skip check for current release since it is also max_date
        // && contract.endDate === MAX_DATE_MS * 1000000
      ) {
        return '契約中'; // "Purchased a paid plan, and using the service"
      } else if (usageKind === 'freemium') {
        return nowNanos <= endDate ? 'フリープラン中' : 'フリープラン終了';
      } else {
        return '';
      }
    },
    [now],
  );

  const handleUpdateSuccess = () => setUpdateSuccessSnackbarOpen(true);
  const handleUpdateSuccessClose = () => setUpdateSuccessSnackbarOpen(false);

  const handleUpdateFreemiumSuccess = () =>
    setUpdateFreemiumSuccessSnackbarOpen(true);
  const handleUpdateFreemiumSuccessClose = () =>
    setUpdateFreemiumSuccessSnackbarOpen(false);

  const fetchContractInfo = React.useCallback(async () => {
    dispatchCustomerDetail({
      type: 'SET_PROCESSING_DIALOG',
      processingDialogState: 'wait',
    });

    const contract = flow(
      filter(
        (contract: Contract) =>
          contract.serviceId === serviceId &&
          (contract.usageKind === 'trial' || isStandardPlan(contract.planId)),
      ),
      sortBy((contract) => contract.timestamp),
      last,
    )(contracts);

    if (contract) {
      const tenant = tenants.find((t) => t.tenantId === contract.tenantId);
      if (!tenant) {
        // TODO: handle no tenant error
        return;
      }

      setContract({
        contractId: contract.contractId,
        tenantId: tenant.tenantId,
        tenantName: tenant.tenantName,
        usageKind: contract.usageKind,
        volume:
          contract.contractKind === 'constant'
            ? '---'
            : (contract.volume ?? 0).toString(),
        startDate: contract.startDate,
        endDate: contract.endDate,
        note: contract.note,
        serviceId: serviceId || '',
        planId: contract.planId,
        status: toStatus(contract.usageKind, contract.endDate),
      });
    }

    dispatchCustomerDetail({
      type: 'SET_PROCESSING_DIALOG',
      processingDialogState: 'close',
    });
  }, [contracts, dispatchCustomerDetail, serviceId, tenants, toStatus]);

  const handleExtendTrial = async ({
    newEndDate,
    note,
  }: {
    newEndDate: number;
    note: string;
  }) => {
    const accessToken = await getAccessTokenSilently();

    const body = {
      applicationType: 'contract',
      applicationSubtype: 'extend',
      userKind: 'operator',
      userId: contextState.userId,
      customerId: customerId,
      serviceId: serviceId,
      tenantId: contract?.tenantId,
      notificationKind: 'none',
      jsonData: JSON.stringify({
        baseContractId: contract?.contractId,
        endDateMs: newEndDate,
        note: note || contract.note,
      }),
    };
    const applicationResp = await fetch(
      `${applicationControllerBaseUrl}/api/application-controller/applications`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(body),
      },
    );
    if (applicationResp.status === 201) {
      setContract({
        ...contract,
        endDate:
          newEndDate === MAX_DATE_MARK
            ? MAX_DATE_NS
            : newEndDate * NANO_SECONDS_FACTOR,
        note: note || contract.note,
      });
      setTimeout(() => {
        dispatchCustomerDetail({
          type: 'RELOAD_CONTRACTS',
        });
      }, 5000);
      handleUpdateSuccess();
    }
  };

  const handleUpdateUsageKind = async (
    contractDateMs: number,
    calculationEndDateMs: number,
    note: string,
  ) => {
    const jsonData = {
      planId: contract.planId,
      usageKind: 'freemium',
      contractDateMs: contractDateMs,
      calculationStartDateMs: contractDateMs,
      availableDateMs: contractDateMs,
      calculationEndDateMs: calculationEndDateMs,
      volume: -1,
      note: note,
    };

    const body = {
      applicationType: 'contract',
      applicationSubtype: 'create',
      userKind: 'operator',
      userId: contextState.userId,
      customerId: customerId,
      serviceId: serviceId,
      tenantId: contract.tenantId,
      notificationKind: 'none',
      jsonData: JSON.stringify(jsonData),
    };

    try {
      dispatchCustomerDetail({
        type: 'SET_PROCESSING_DIALOG',
        processingDialogState: 'wait',
      });
      const accessToken = await getAccessTokenSilently();
      const resp = await fetch(
        `${applicationControllerBaseUrl}/api/application-controller/applications`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(body),
        },
      );

      if (resp.status === 201) {
        setTimeout(() => {
          dispatchCustomerDetail({
            type: 'RELOAD_CONTRACTS',
          });
          dispatchCustomerDetail({
            type: 'SET_PROCESSING_DIALOG',
            processingDialogState: 'close',
          });
          handleUpdateFreemiumSuccess();
        }, 5000);
      } else {
        dispatchCustomerDetail({
          type: 'SET_PROCESSING_DIALOG',
          processingDialogState: 'close',
        });
      }
    } catch (e) {
      dispatchCustomerDetail({
        type: 'SET_PROCESSING_DIALOG',
        processingDialogState: 'close',
      });
    }
  };

  React.useEffect(() => {
    fetchContractInfo();
  }, [customerId, fetchContractInfo]);

  return (
    <>
      <CustomerDetailTenantTemplate
        iconSrc={`${process.env.PUBLIC_URL}/images/logo/${
          serviceId && serviceId.toString().replace(/-internal$/, '')
        }.png`}
        serviceName={ServiceName.get(serviceId as ServiceType)}
        contract={contract}
        onUpdate={handleExtendTrial}
        onUpdateUsageKind={handleUpdateUsageKind}
        extendTrialPermission={extendTrialPermission}
        updateUsageKindPermission={updateUsageKindPermission}
      />
      <StyledSnackbar
        open={updateSuccessSnackbarOpen}
        severity="default"
        message="更新完了しました。反映まで少し時間が掛る場合があります。"
        onClose={handleUpdateSuccessClose}
      />
      <StyledSnackbar
        open={updateFreemiumSuccessSnackbarOpen}
        severity="default"
        message="フリープランへ変更しました"
        onClose={handleUpdateFreemiumSuccessClose}
        handleClose={handleUpdateFreemiumSuccessClose}
      />
    </>
  );
};
