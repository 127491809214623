import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import { useAuth0 } from '@auth0/auth0-react';

import { Footer } from '@bizapp-frontend/management/organisms/Footer';
import { Header } from '@bizapp-frontend/management/organisms/Header';
import {
  NavigationList,
  NavigationDrawer,
} from '@bizapp-frontend/management/organisms/HeaderNav';

import { GlobalsContext } from '@bizapp-frontend/management/globals';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: 'calc(100vh - 72px - 72px)',
    padding: 0,
    width: '100%',
  },
}));

export interface PageTemplateProps {
  className?: string;
}

export const PageTemplate: React.FC<PageTemplateProps> = ({
  className,
  children,
}) => {
  const classes = useStyles();
  const [width, setWidth] = React.useState(window.innerWidth);
  const baseUrl = 'https://bizapp.worksap.co.jp';
  const { state } = React.useContext(GlobalsContext);
  const { logout, isAuthenticated } = useAuth0();
  const headerLinks = [{ url: '/customers', label: '顧客台帳' }];
  const footerLinks = [
    { href: `${baseUrl}/terms`, label: '利用規約', target: '_blank' },
    {
      href: `${baseUrl}/privacy`,
      label: '個人情報の取扱いについて',
      target: '_blank',
    },
    {
      href: `${baseUrl}/law`,
      label: '特定商取引法に基づく表記',
      target: '_blank',
    },
    {
      href: 'https://www.worksap.co.jp/about/',
      label: '会社概要',
      target: '_blank',
    },
  ];

  React.useEffect(() => {
    const updateSize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      <Header>
        {isAuthenticated &&
          (width >= 768 ? (
            <NavigationList
              links={headerLinks}
              statusLabel={state?.userId}
              onClick={() => logout({ returnTo: window.location.origin })}
            />
          ) : (
            <NavigationDrawer
              links={headerLinks}
              statusLabel={state?.userId}
              onClick={() => logout({ returnTo: window.location.origin })}
            />
          ))}
      </Header>
      <Container
        className={`${classes.root} ${className ?? ''}`}
        maxWidth={false}
      >
        <>{children}</>
      </Container>
      <Footer links={footerLinks} />
    </>
  );
};
