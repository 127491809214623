import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import Meta from '@bizapp-frontend/management/organisms/Meta';
import { Customer } from '@bizapp-frontend/management/organisms/CustomerList';
import { CustomerListTemplate } from '@bizapp-frontend/management/templates/CustomerListTemplate';

export interface CustomerListPageProps {
  applicationControllerBaseUrl: string;
}

export const ServiceCustomerList: React.FC<CustomerListPageProps> = ({
  applicationControllerBaseUrl,
}) => {
  const [customers, setCustomers] = React.useState<Customer[]>([]);
  const [fetchedCustomersExt, setFetchedCustomersExt] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const { getAccessTokenSilently } = useAuth0();
  const { serviceId } = useParams();
  const location = useLocation();

  const handleOnCustomerClick = (index: number) => {
    window.open(
      `/customers/${customers[index].customerId}/info?backUrl=${btoa(
        location.pathname,
      )}`,
      '_blank',
    );
  };

  const handleOnScrollEnd = () => {
    // dummy
  };

  React.useEffect(() => {
    if (!fetchedCustomersExt) {
      const f = async () => {
        const accessToken = await getAccessTokenSilently();
        const url = `${applicationControllerBaseUrl}/api/application-controller/services/${serviceId}/customers?limit=2000`;
        const resp = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setFetchedCustomersExt(true);
        if (resp.status === 200) {
          const res = await resp.json();
          const newCustomers = res.customers
            .sort((a: any, b: any) => b.timestamp - a.timestamp)
            .map((customer: any) => {
              return {
                companyName: customer.companyName,
                customerId: customer.customerId,
                email: customer.users[0].email,
                endDate:
                  customer.contract.usageKind === 'trial'
                    ? new Date(
                        customer.contract.endDate / 1000000,
                      ).toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })
                    : '---',
                jobTitle: customer.users[0].jobTitle,
                timestamp: new Date(
                  customer.timestamp / 1000000,
                ).toLocaleString('ja-JP', {
                  timeZone: 'Asia/Tokyo',
                }),
                userName: customer.users[0].userName,
                userId: customer.users[0].userId,
              };
            });
          setCustomers([...customers, ...newCustomers]);
        }
      };
      f();
    }
  }, [
    applicationControllerBaseUrl,
    customers,
    getAccessTokenSilently,
    fetchedCustomersExt,
    serviceId,
  ]);

  React.useEffect(() => {
    const title = `利用サービス (${serviceId?.toUpperCase()}) - HUE Works Suite マネジメントサイト`;
    setTitle(title);
  }, [serviceId]);

  return (
    <>
      <Meta>
        <title>{title}</title>
        <meta property="og:title" content={title} />
      </Meta>
      <CustomerListTemplate
        customers={customers}
        onCustomerClick={handleOnCustomerClick}
        onScrollEnd={handleOnScrollEnd}
      />
    </>
  );
};
