import React from 'react';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  PriceTableInfo,
  PriceTable,
  FirstMonthType,
} from '@bizapp-frontend/management/templates/form/PriceTable';
import {
  FormComponentProps,
  FormGenerator,
  FormGeneratorProps,
} from '@bizapp-frontend/management/organisms/form/FormGenerator';
import {
  DiscountInfo,
  Plan,
} from '@bizapp-frontend/management/templates/form/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    title: {
      display: 'block',
      marginBottom: theme.spacing(3.75),
      color: '#333333',
      fontSize: 17,
      fontWeight: 'bold',
    },
    form: {
      paddingTop: theme.spacing(1.75),
      width: theme.spacing(50),
    },
    firstMonthPriceTable: {
      width: theme.spacing(50),
      marginBottom: theme.spacing(1.25),
      marginLeft: theme.spacing(3),
    },
    volumeRow: {
      marginTop: theme.spacing(3),
    },
    noteRow: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  }),
);

export interface ContractFormProps
  extends Omit<FormGeneratorProps, 'formComponents'> {
  className?: string;
  priceComponents: FormComponentProps[][];
  volumeComponents: FormComponentProps[][];
  noteComponents: FormComponentProps[][];
  priceInfo?: PriceTableInfo;
  discountInfoCurrent: DiscountInfo;
  discountInfoUpdated: DiscountInfo;
  plan?: Plan;
  hideFirstMonthPriceTable?: boolean;
  firstMonthType?: FirstMonthType;
}

export const ContractForm: React.FC<ContractFormProps> = ({
  className,
  priceComponents,
  volumeComponents,
  noteComponents,
  formData,
  setFormData,
  priceInfo,
  discountInfoCurrent,
  discountInfoUpdated,
  plan,
  hideFirstMonthPriceTable = false,
  firstMonthType = 'normal',
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={`${classes.root} ${className}`}>
        <div className={classes.form}>
          <Typography component="span" className={classes.title}>
            {plan?.billKind === 'monthly'
              ? '請求詳細（月額）'
              : '請求詳細（単発）'}
          </Typography>
          <FormGenerator
            formData={formData}
            setFormData={setFormData}
            formComponents={priceComponents}
            {...props}
          />
          <FormGenerator
            className={classes.volumeRow}
            formData={formData}
            setFormData={setFormData}
            formComponents={volumeComponents}
            {...props}
          />
        </div>
        <div>
          {
            <PriceTable
              className={classes.firstMonthPriceTable}
              priceInfo={priceInfo}
              discountInfoCurrent={discountInfoCurrent}
              discountInfoUpdated={discountInfoUpdated}
              plan={plan}
              firstMonthType={firstMonthType}
              hideFirstMonthPriceTable={hideFirstMonthPriceTable}
            />
          }
        </div>
      </div>
      <FormGenerator
        className={classes.noteRow}
        formData={formData}
        setFormData={setFormData}
        formComponents={noteComponents}
        {...props}
      />
    </>
  );
};
