import React from 'react';
import {
  IconButton,
  Link,
  ListItemText,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Theme,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useAuth0 } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';

import { Menu, MenuLink } from '@bizapp-frontend/management/organisms/Menu';
import { GlobalsContext } from '@bizapp-frontend/management/globals';

const drawerWidth = 200;

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      backgroundColor: '#FFFFFF',
      minHeight: '100vh',
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      boxShadow: 'none',
      backgroundColor: '#ffffff',
    },
    toolbar: {
      height: theme.spacing(5),
      display: 'flex',
      alignItems: 'center',
    },
    menuButton: {
      position: 'absolute',
      top: theme.spacing(1.25),
      left: theme.spacing(1.25),
    },
    menuIcon: {
      color: '#333333',
      fontSize: 18,
    },
    logo: {
      width: theme.spacing(11),
      height: theme.spacing(4),
      marginLeft: theme.spacing(5),
    },
    nav: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: drawerWidth,
      backgroundColor: '#FFFFFF',
      height: '100vh',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      overflow: 'hidden',
    },
    navHeader: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(2),
      paddingLeft: theme.spacing(0.5),
    },
    hide: {
      display: 'none',
    },
    pageName: {
      display: 'block',
      color: '#333333',
      fontSize: 20,
      fontWeight: 'bold',
      marginLeft: theme.spacing(5),
    },
    content: {
      boxSizing: 'border-box',
      width: '100%',
      height: '100vh',
      overflow: 'auto',
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    paper: {
      boxShadow: '0px 5px 10px #00000014',
    },
    status: {
      position: 'absolute',
      bottom: theme.spacing(2.5),
      boxSizing: 'border-box',
      width: theme.spacing(21),
      height: theme.spacing(3),
      border: '1px solid #DCE0E7',
      borderRadius: 16,
      fontSize: 12,
      color: '#7F7F7F',
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 1.5),
      cursor: 'pointer',
      '& span': {
        display: 'block',
        width: theme.spacing(16.25),
        minWidth: theme.spacing(16.25),
        overflow: 'hidden',
        fontSize: '12px',
      },
      '& svg': {
        display: 'block',
        fontSize: '20px',
      },
    },
  }),
);

export interface ManagementDashboardTemplateProps {
  links: MenuLink[];
}

export const ManagementDashboardTemplate: React.FC<ManagementDashboardTemplateProps> = ({
  links,
}) => {
  const classes = useStyle();
  const { state } = React.useContext(GlobalsContext);
  const { logout, isAuthenticated } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [hideMenu, setHideMenu] = React.useState(false);

  const handleStatusClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMuiMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={() => setHideMenu(!hideMenu)}
        className={classes.menuButton}
      >
        <MenuIcon className={classes.menuIcon} />
      </IconButton>
      <div className={classes.root}>
        <nav className={`${classes.nav} ${hideMenu ? classes.hide : ''}`}>
          <div className={classes.navHeader}>
            <Link href="/">
              <img
                className={classes.logo}
                src={`${process.env.PUBLIC_URL}/images/WAP-logo_white.png`}
                alt={'WAP-logo'}
              />
            </Link>
          </div>
          <Menu links={links} />
          {isAuthenticated && (
            <div className={classes.status} onClick={handleStatusClick}>
              <span>{state?.userId}</span>
              <ArrowDropDownIcon />
            </div>
          )}
          <MuiMenu
            id="status-label"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMuiMenuClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 60,
              horizontal: 'right',
            }}
            elevation={0}
            getContentAnchorEl={null}
            classes={{
              paper: classes.paper,
            }}
          >
            <MuiMenuItem>
              <ListItemText
                onClick={() => logout({ returnTo: window.location.origin })}
              >
                ログアウト
              </ListItemText>
            </MuiMenuItem>
          </MuiMenu>
        </nav>
        <main className={classes.content}>
          <Outlet />
        </main>
      </div>
    </>
  );
};
