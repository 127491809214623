import React from 'react';
import { Modal, ModalProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  dialog: {
    margin: '32px 16px',
  },
  backdrop: {
    width: '100%',
    height: '100%',
    backgroundColor: '#333333',
    opacity: 0.2,
  },
});

export interface StyledModalProps extends ModalProps {}

export const StyledModal: React.FC<StyledModalProps> = ({
  open,
  children,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Modal
      className={classes.dialog}
      open={open}
      BackdropProps={{
        timeout: 500,
        className: classes.backdrop,
      }}
      {...props}
    >
      {children}
    </Modal>
  );
};
