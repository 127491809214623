import React from 'react';

import { CustomerDetailContext } from '@bizapp-frontend/management/pages/CustomerDetailPage';
import { CustomerDetailInfoTemplate } from '@bizapp-frontend/management/templates/CustomerDetailInfoTemplate';

export interface CustomerDetailInfoPageProps {}

export const CustomerDetailInfoPage: React.FC<CustomerDetailInfoPageProps> = () => {
  const customerDetail = React.useContext(CustomerDetailContext);
  const { company, user } = customerDetail.customerDetailState;

  return <CustomerDetailInfoTemplate company={company} user={user} />;
};
