import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';

const NANO_SECONDS_FACTOR = 1000000;

const timestamp2Date = (timestamp: number) => {
  if (!timestamp) {
    return '';
  }
  return new Date(timestamp / NANO_SECONDS_FACTOR)
    .toLocaleString('ja-JP', {
      timeZone: 'Asia/Tokyo',
    })
    .split(' ')[0];
};

const TableHeaders = [
  'サービス名',
  'プラン名',
  'ステータス',
  'ライセンス数',
  '申込日',
  '有効期限',
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      paddingTop: theme.spacing(5),
    },
    title: {
      display: 'block',
      fontSize: 14,
      fontWeight: 'bold',
      color: '#4A5560',
      marginLeft: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    table: {
      maxWidth: theme.spacing(114.75),

      '& th': {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.25),
        paddingBottom: theme.spacing(1.25),
        color: '#A4AAB0',
        fontWeight: 'bold',
        fontSize: 14,
        borderTop: '1px solid #A4AAB0',
        borderBottom: '1px solid #A4AAB0',
      },
      '& th:first-child': {
        paddingLeft: theme.spacing(4),
      },
      '& td': {
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1.25),
        paddingBottom: theme.spacing(1.25),
        color: '#333333',
        fontSize: 14,
        cursor: 'pointer',
      },
      '& td:first-child': {
        paddingLeft: theme.spacing(4),
      },
      '& tr:last-child td': {
        borderBottom: '1px solid #A4AAB0',
      },
    },
  }),
);

const StyledPaper = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
}))(Paper);
export interface Service {
  id: string;
  serviceId: string;
  serviceName: string;
  planName: string;
  status: string;
  licenseNumber?: number;
  applicationDate: number;
  endDate: number;
}

export interface CustomerDetailTenantListTemplateProps {
  services: Service[];
  onServiceClick: Function;
}

export const CustomerDetailTenantListTemplate: React.FC<CustomerDetailTenantListTemplateProps> = ({
  services,
  onServiceClick,
}) => {
  const classes = useStyles();

  const handleClick = (serviceId: string) => {
    if (!onServiceClick) {
      return;
    }
    onServiceClick(serviceId);
  };

  return (
    <div className={classes.root}>
      <Typography component="span" className={classes.title}>
        利用サービス
      </Typography>
      <TableContainer component={StyledPaper} className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              {TableHeaders.map((header, i) => (
                <TableCell key={i}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {services.map((service, i) => (
              <TableRow
                key={i}
                hover
                onClick={() => handleClick(service.serviceId)}
              >
                <TableCell width="13%">{service.serviceName}</TableCell>
                <TableCell width="14%">{service.planName}</TableCell>
                <TableCell width="11%">{service.status}</TableCell>
                <TableCell width="11%" align="right">
                  {service?.licenseNumber && service.licenseNumber !== -1
                    ? service.licenseNumber
                    : '---'}
                </TableCell>
                <TableCell width="10%">
                  {timestamp2Date(Number(service.applicationDate))}
                </TableCell>
                <TableCell width="10%">
                  {timestamp2Date(Number(service.endDate))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
