import React from 'react';
import * as _ from 'lodash';
import {
  FormControl,
  MenuItem,
  Select,
  SelectProps,
  Theme,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  StyledInputLabel,
  StyledOutlinedInput,
  StyledHelperText,
  StyledDescription,
} from '@bizapp-frontend/management/molecules/form/FormComponent';

export interface FormSelectProps extends SelectProps {
  options?: Map<string, React.ReactNode>;
  helperText?: React.ReactNode;
  description?: React.ReactNode;
  setValue?: (value: string | ((prevVar: string) => string)) => void;
  setIsValid?: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  forceValidation?: boolean;
  setForceValidation?: (
    value: boolean | ((prevVar: boolean) => boolean),
  ) => void;
  postChange?: (value: string) => void;
  onClickSelect?: (value: boolean) => void;
  postBlur?: (value: string) => void;
  forceOpen?: boolean;
  updated?: boolean;
}

export const FormSelect: React.FC<FormSelectProps> = ({
  className = '',
  id,
  label,
  required = false,
  disabled = false,
  hidden = false,
  options,
  helperText,
  description,
  defaultValue,
  setValue,
  setIsValid,
  forceValidation,
  setForceValidation,
  onChange,
  postChange,
  onBlur,
  postBlur,
  readOnly,
  onClickSelect,
  forceOpen,
  updated,
  ...props
}) => {
  const [checkedDefaultValue, setCheckedDefaultValue] = React.useState(false);
  const [error, setError] = React.useState(props.error ?? false);
  const [helper, setHelper] = React.useState(helperText ?? '');
  const optionsNameKeyMap = new Map(
    options ? Array.from(options.entries()).map((v) => [v[1], v[0]]) : [],
  );

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    if (forceOpen) {
      setOpen(true);
    }
  }, [forceOpen]);

  const classes = makeStyles((theme: Theme) =>
    createStyles({
      select: {
        '& .MuiSelect-selectMenu': {
          paddingTop: theme.spacing(1),
          paddingBottom: theme.spacing(1),
        },
        '& .Mui-disabled': {
          backgroundColor: '#EDEFF3',
        },
        '&.CustomMui-updated div': {
          color: '#4285F4',
        },
        '&.CustomMui-not-updated div': {
          color: '#333333',
        },
      },
      item: {
        height: '32px',
        minHeight: 'initial',
      },
    }),
  )();

  const handleValidate = React.useCallback(
    (value: string) => {
      if (required) {
        if (!_.isEmpty(value)) {
          setError(false);
          setIsValid && setIsValid(true);
        } else {
          setError(true);
          setIsValid && setIsValid(false);
        }
      }
    },
    [required, setIsValid],
  );

  const handleChange = (
    ev: React.ChangeEvent<{ name?: string; value: unknown }>,
    child: React.ReactNode,
  ) => {
    onChange && onChange(ev, child);
    options &&
      setValue &&
      setValue((options.get(ev.target.value as string) as string) || '');
    postChange && postChange(ev.target.value as string);
  };

  const handleBlur = (
    ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onBlur && onBlur(ev);
    handleValidate(props.value as string);
    postBlur && postBlur(props.value as string);
  };

  React.useEffect(() => {
    if (helperText) {
      setHelper(helperText);
    } else if (required) {
      setHelper('入力必須項目です。');
    }
  }, [helperText, required]);

  React.useEffect(() => {
    if (forceValidation) {
      handleValidate((props.value as string) ?? '');
      setForceValidation && setForceValidation(false);
    }
  }, [props.value, forceValidation, handleValidate, setForceValidation]);

  React.useEffect(() => {
    if (!checkedDefaultValue) {
      setCheckedDefaultValue(true);
      defaultValue && setValue && setValue(defaultValue as string);
      if (!_.isEmpty(defaultValue)) {
        handleValidate(defaultValue as string);
      }
    }
  }, [defaultValue, setValue, handleValidate, checkedDefaultValue]);

  return (
    <>
      {hidden || (
        <FormControl
          required={required}
          error={props.error || error}
          disabled={disabled}
          className={className}
        >
          <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>
          <Select
            {...props}
            readOnly={readOnly}
            id={id}
            input={<StyledOutlinedInput />}
            className={`${classes.select} ${
              updated && !disabled
                ? 'CustomMui-updated'
                : 'CustomMui-not-updated'
            }`}
            value={optionsNameKeyMap.get(props.value as React.ReactNode) ?? ''}
            error={props.error || error}
            onChange={handleChange}
            onBlur={handleBlur}
            onClick={() => onClickSelect && onClickSelect(!!readOnly)}
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
          >
            {options &&
              Array.from(options.entries()).map((kv, i) => {
                const [k, v] = kv;
                return (
                  <MenuItem
                    value={k}
                    key={`${id}-${i}`}
                    className={classes.item}
                  >
                    {v || ''}
                  </MenuItem>
                );
              })}
          </Select>
          {helper && (props.error || error) && (
            <StyledHelperText children={helper} />
          )}
          {description && <StyledDescription children={description} />}
        </FormControl>
      )}
    </>
  );
};
