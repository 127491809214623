import React from 'react';
import {
  Container,
  Link,
  List,
  ListItem,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import Meta from '@bizapp-frontend/management/organisms/Meta';

import { GlobalsContext } from '@bizapp-frontend/management/globals';
import { PageListTemplate } from '@bizapp-frontend/management/templates/PageListTemplate';
import {
  dateTimeStrToJstMs,
  jstMsToLocalDateTimeStr,
} from '@bizapp-frontend/management/templates/form/utils';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiListItem-root': {
        display: 'block',
        textAlign: 'center',
      },
    },
  }),
);

const PageList: React.FC = () => {
  const classes = useStyle();
  const { state, dispatch } = React.useContext(GlobalsContext);
  const developmentCurrentTime = React.useMemo(() => {
    const ms = state.developmentMeta.currentTimeMs;
    if (ms) {
      return jstMsToLocalDateTimeStr(ms);
    } else {
      return '';
    }
  }, [state.developmentMeta.currentTimeMs]);
  const handleDevCurrTimeChange = (timeStr: string) => {
    dispatch({
      type: 'SET_DEVELOPMENT_META',
      developmentMeta: {
        currentTimeMs: timeStr ? dateTimeStrToJstMs(timeStr) : undefined,
      },
    });
  };

  const [customerId, setCustomerId] = React.useState<string | undefined>(
    undefined,
  );
  const handleChangeCustomerId = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCustomerId(event.target.value);
  };
  return (
    <>
      <Meta>
        <title>ユーザサイト 社内向けページリスト</title>
      </Meta>
      <PageListTemplate
        heading1="ユーザサイト 社内向けページリスト"
        heading2="各ページ確認用"
      >
        <Container className={classes.root}>
          <Typography variant="h3">開発社モード</Typography>
          <List>
            <ListItem>モード</ListItem>
            <ListItem>{state.development ? 'オン(ON)' : 'オフ(OFF)'}</ListItem>
            <ListItem>
              <Link
                component="button"
                onClick={() =>
                  dispatch({
                    type: 'SET_DEVELOPMENT',
                    development: state.development ? false : true,
                  })
                }
              >
                {`${state.development ? 'オフ' : 'オン'}にする`}
              </Link>
            </ListItem>
            {state.development && (
              <>
                <ListItem>
                  <div>
                    {state.developmentMeta.currentTimeMs &&
                      'Dev Local Ms: ' + state.developmentMeta.currentTimeMs}
                  </div>
                  <div>
                    {state.developmentMeta.currentTimeMs &&
                      'Dev Local Time: ' +
                        new Date(
                          state.developmentMeta.currentTimeMs,
                        ).toLocaleString()}
                  </div>
                  <TextField
                    type="datetime-local"
                    value={developmentCurrentTime}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        height: '32px',
                        border: '1px solid #C2CAD8',
                        borderRadius: '3px',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                        fontSize: '14px',
                      },
                    }}
                    onChange={(e) =>
                      handleDevCurrTimeChange(e.target.value as string)
                    }
                  />
                </ListItem>
                <ListItem>
                  <TextField
                    label="Customer Id"
                    value={customerId}
                    onChange={handleChangeCustomerId}
                  />
                </ListItem>

                {customerId && (
                  <>
                    <ListItem>
                      <Link
                        component={RouterLink}
                        to={`/customers/${customerId}/info`}
                      >
                        Customer Information
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        component={RouterLink}
                        to={`/customers/${customerId}/services`}
                      >
                        Customer Service List
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        component={RouterLink}
                        to={`/customers/${customerId}/billing-information`}
                      >
                        Customer Billing Information
                      </Link>
                    </ListItem>
                  </>
                )}
              </>
            )}
          </List>
          <Typography variant="h3">ページ</Typography>
          <List>
            <ListItem>
              <Link component={RouterLink} to={`/internal/old/customers`}>
                旧顧客一覧
              </Link>
            </ListItem>
            <ListItem>
              <Link component={RouterLink} to={`/internal/removal-tool`}>
                削除ツール(本番環境では無効)
              </Link>
            </ListItem>
          </List>
        </Container>
      </PageListTemplate>
    </>
  );
};

export default PageList;
