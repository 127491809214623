import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  Theme,
  ListItemText,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: '100%',
    },
    drawerPaper: {
      width: '100%',
      border: 'none',
      position: 'static',
      backgroundColor: 'transparent',
    },
    divider: {
      height: theme.spacing(0.125),
      backgroundColor: '#DCE0E7',
    },
    subListMarginBottom: {
      marginBottom: '8px !important',
    },
    openInNewIcon: {
      fontSize: 14,
      color: '#7F7F7F',
      marginRight: theme.spacing(2),
    },
    navList: {
      width: '100%',
      margin: 0,
      padding: 0,
      '& .Mui-selected': {
        backgroundColor: '#ECF2FD',
        borderRadius: theme.spacing(0, 2.5, 2.5, 0),
      },
      '& .MuiListItem-root': {
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(0.5),
        '&:first-child': { marginTop: 0 },
      },
      '& .MuiListItem-gutters': {
        padding: 0,
      },
      '& .MuiListItemText-root': {
        margin: 0,
        paddingTop: 5.5,
        paddingBottom: 5.5,
        paddingLeft: theme.spacing(2),
      },
      '& a.MuiButtonBase-root': {
        '&:hover': {
          backgroundColor: '#ECF2FD',
          borderRadius: theme.spacing(0, 2.5, 2.5, 0),
        },
      },
      '& a.MuiListItem-button': {
        transition: 'none',
      },
      '& ul span ': {
        paddingLeft: theme.spacing(2),
      },
      '& .MuiList-padding': {
        padding: 0,
      },
    },
  }),
);

export interface MenuLink {
  id: string;
  label?: string;
  externalLink?: boolean;
  href?: string;
  subLinks?: MenuLink[];
  state?: object;
  divider?: boolean;
}

export interface MenuProps {
  links: MenuLink[];
  children?: React.ReactNode;
}

const MenuComponent: React.FC<MenuProps> = ({ links = [] }) => {
  const classes = useStyle();
  const { pathname } = useLocation();

  const MenuLinks: React.FC<{ links: MenuLink[] }> = ({ links }) => {
    const MenuLinkGenerator: React.FC<{ link: MenuLink; divider: boolean }> = ({
      link,
      divider,
    }) => {
      const isButton: any = !!link.href;
      return (
        <>
          <ListItem
            selected={pathname === link.href}
            button={isButton}
            to={link.href || ''}
            state={link.state}
            component={!!link.href ? Link : 'li'}
            className={link.subLinks ? classes.subListMarginBottom : ''}
            disableRipple
          >
            <ListItemText key={`${link.id}-label`}>{link.label}</ListItemText>
            {link.externalLink && (
              <OpenInNewIcon className={classes.openInNewIcon} />
            )}
          </ListItem>
          {link.subLinks && <MenuLinks links={link.subLinks} />}
          {divider && (
            <Divider className={classes.divider} key={`${link.id}-divider`} />
          )}
        </>
      );
    };

    return (
      <List classes={{ root: classes.navList }}>
        {links.map((link, i) => (
          <MenuLinkGenerator
            key={`${link.id}`}
            link={link}
            divider={!!link.divider && i !== links.length - 1}
          />
        ))}
      </List>
    );
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <MenuLinks links={links} />
    </Drawer>
  );
};

export const Menu = React.memo(MenuComponent);
