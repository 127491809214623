import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  DiscountInfo,
  Plan,
} from '@bizapp-frontend/management/templates/form/utils';

export interface PriceTableProps {
  className?: string;
  priceInfo?: PriceTableInfo;
  discountInfoCurrent: DiscountInfo;
  discountInfoUpdated: DiscountInfo;
  plan?: Plan;
  firstMonthType?: FirstMonthType;
  hideFirstMonthPriceTable?: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    priceTable: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#DADDE0',
      borderRadius: '3px',
      borderCollapse: 'separate',
      padding: theme.spacing(1.5, 2, 3),
      '& .MuiTableCell-root': {
        border: 'none',
        padding: 0,
      },
    },
    groupTitle: {
      lineHeight: '32px',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#777777',
      paddingTop: '12px !important',
    },
    groupPrice: {
      lineHeight: '32px',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#333333',
      textAlign: 'right',
    },
    totalTitle: {
      lineHeight: '32px',
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#777777',
    },
    totalPrice: {
      lineHeight: '32px',
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#333333',
      textAlign: 'right',
    },
    itemTitle: {
      lineHeight: '32px',
      fontSize: '14px',
      color: '#777777',
      paddingLeft: '32px !important',
      '&:last-child': {
        color: '#ff7777',
      },
    },
    itemTitleLarge: {
      lineHeight: '32px',
      fontSize: '18px',
      color: '#777777',
      paddingLeft: '32px !important',
      '&:last-child': {
        color: '#ff7777',
      },
    },
    strikethrough: {
      textDecoration: 'line-through',
      opacity: 0.5,
    },
    itemPrice: {
      lineHeight: '32px',
      fontSize: '14px',
      color: '#333333',
      textAlign: 'right',
    },
    itemPriceLarge: {
      lineHeight: '32px',
      fontSize: '18px',
      color: '#333333',
      textAlign: 'right',
    },
    singlePriceRow: {
      '& th,td': {
        paddingTop: '12px !important',
        borderTop: '1px solid #DADDE0 !important',
      },
    },
    rowWithTopLine: {
      '& th,td': {
        borderTop: '1px solid #DADDE0 !important',
      },
    },
    licenseNumberRow: {
      '& th,td': {
        borderTop: '1px solid #DADDE0 !important',
        paddingTop: '12px !important',
        paddingBottom: '12px !important',
      },
    },
    totalPriceRow: {
      '& th,td': {
        paddingTop: '12px !important',
        borderTop: '4px double #DADDE0 !important',
      },
    },
    rowPadding: {
      '& th,td': {
        paddingBottom: '12px !important',
      },
    },
    description: {
      marginTop: theme.spacing(1),
      color: '#7F7F7F',
      fontSize: '12px',
      lineHeight: '18px',
    },
  }),
);

export interface PriceTableInfo {
  secondMonth: MonthPrice;
  firstMonth?: MonthPrice;
  firstMonthCurrent?: MonthPrice;
  firstMonthUpdated?: MonthPrice;
}

export interface MonthPrice {
  priceIncludingTax: number;
  priceExcludingTax: number;
  licenseNumber: number;
  basePriceExcludingTax: number;
  basePriceIncludingTax: number;
  unitPriceExcludingTax: number;
  unitPriceIncludingTax: number;
  discount: {
    remaining: Discount;
    coupon: Discount;
  };
}

export interface Discount {
  amountExcludingTax: number;
  amountIncludingTax: number;
  target: DiscountRange;
  usage: DiscountRange;
}

export interface DiscountRange {
  days: number;
  startDate: number;
  endDate: number;
}

export type FirstMonthType = 'normal' | 'volume-diff' | 'price-diff';

export const PriceTable: React.FC<PriceTableProps> = ({
  className,
  priceInfo,
  discountInfoCurrent,
  discountInfoUpdated,
  plan,
  hideFirstMonthPriceTable = false,
  firstMonthType = 'normal',
}) => {
  const classes = useStyle();

  // const [remainingDateText, setRemainingDateText] = React.useState('（---）');

  const initData = React.useMemo(() => {
    return {
      priceExcludingTax: 0,
      priceIncludingTax: 0,
      licenseNumber: 0,
      basePriceExcludingTax: 0,
      basePriceIncludingTax: 0,
      unitPriceExcludingTax: 0,
      unitPriceIncludingTax: 0,
      discount: {
        remaining: {
          amountIncludingTax: 0,
          amountExcludingTax: 0,
          target: {
            days: 0,
            startDate: 0,
            endDate: 0,
          },
          usage: {
            days: 0,
            startDate: 0,
            endDate: 0,
          },
        },
        coupon: {
          amountIncludingTax: 0,
          amountExcludingTax: 0,
          target: {
            days: 0,
            startDate: 0,
            endDate: 0,
          },
          usage: {
            days: 0,
            startDate: 0,
            endDate: 0,
          },
        },
      },
    };
  }, []);
  const [secondMonth, setSecondMonth] = React.useState<MonthPrice>(initData);
  const [firstMonth, setFirstMonth] = React.useState<MonthPrice>(initData);
  const [firstMonthCurrent, setFirstMonthCurrent] = React.useState<MonthPrice>(
    initData,
  );
  const [firstMonthUpdated, setFirstMonthUpdated] = React.useState<
    MonthPrice | undefined
  >();

  React.useEffect(() => {
    if (priceInfo == null || plan == null) {
      setFirstMonth(initData);
      setSecondMonth(initData);
      setFirstMonthCurrent(initData);
    } else if (priceInfo?.secondMonth) {
      setSecondMonth(priceInfo.secondMonth);
      setFirstMonth(priceInfo.firstMonth ?? initData);
      setFirstMonthCurrent(priceInfo.firstMonthCurrent ?? initData);
      setFirstMonthUpdated(priceInfo.firstMonthUpdated);
      // const target = priceInfo.firstMonth.discount.remaining.target;
      // setRemainingDateText(`（${target.days}日分）`);
    }
  }, [initData, plan, priceInfo]);

  const formatPrice = (price: number, showDigits: boolean = false) => {
    return Number(price).toLocaleString('ja-JP', {
      minimumFractionDigits: showDigits ? 2 : 0,
      maximumFractionDigits: showDigits ? 2 : 0,
    });
  };

  const monthlyPartOf = (
    discount: DiscountInfo,
    title: string,
    topDivider: boolean = false,
    monthPrice: MonthPrice,
  ) => {
    return (
      <>
        <TableRow className={topDivider ? classes.rowWithTopLine : ''}>
          <TableCell component="th" scope="row" className={classes.groupTitle}>
            {plan?.billKind === 'monthly' ? title : '請求単価'}
          </TableCell>
          <TableCell className={classes.groupPrice}>
            {formatPrice(
              monthPrice.unitPriceIncludingTax,
              discount.kind === 'rate',
            )}{' '}
            円
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell
            component="th"
            scope="row"
            className={`${classes.itemTitle} ${
              discount.kind === 'fixed' ? classes.strikethrough : ''
            }`}
          >
            標準単価（税抜）
          </TableCell>
          <TableCell
            className={`${classes.itemPrice} ${
              discount.kind === 'fixed' ? classes.strikethrough : ''
            }`}
          >
            {formatPrice(
              monthPrice.basePriceExcludingTax ?? 0,
              discount.kind === 'rate',
            )}{' '}
            円
          </TableCell>
        </TableRow>

        {discount.kind === 'fixed' && (
          <TableRow>
            <TableCell component="th" scope="row" className={classes.itemTitle}>
              特別単価（税抜）
            </TableCell>
            <TableCell className={classes.itemPrice}>
              {formatPrice(
                monthPrice.basePriceExcludingTax +
                  monthPrice.discount.coupon.amountExcludingTax,
              )}{' '}
              円
            </TableCell>
          </TableRow>
        )}

        {discount.kind === 'rate' && (
          <>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={classes.itemTitle}
              >
                {`割引額（${discount.rate / 10000}％・税抜）`}
              </TableCell>
              <TableCell className={classes.itemPrice}>
                {formatPrice(
                  -monthPrice.discount.coupon.amountExcludingTax,
                  discount.kind === 'rate',
                )}{' '}
                円
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={classes.itemTitle}
              >
                割引後単価（税抜）
              </TableCell>
              <TableCell className={classes.itemPrice}>
                {formatPrice(
                  monthPrice.unitPriceExcludingTax ?? 0,
                  discount.kind === 'rate',
                )}{' '}
                円
              </TableCell>
            </TableRow>
          </>
        )}

        <TableRow>
          <TableCell component="th" scope="row" className={classes.itemTitle}>
            税額
          </TableCell>
          <TableCell className={classes.itemPrice}>
            {formatPrice(
              (monthPrice.unitPriceIncludingTax ?? 0) -
                (monthPrice.unitPriceExcludingTax ?? 0),
              discount.kind === 'rate',
            )}{' '}
            円
          </TableCell>
        </TableRow>

        {plan?.contractKind !== 'constant' &&
          plan?.contractKind !== 'specified' && (
            <TableRow className={classes.licenseNumberRow}>
              <TableCell
                component="th"
                scope="row"
                className={classes.itemTitle}
              >
                {plan?.volumeLabel}
              </TableCell>
              <TableCell className={classes.itemPrice}>
                {monthPrice?.licenseNumber ?? 0} 件
              </TableCell>
            </TableRow>
          )}
      </>
    );
  };

  const dailyBasisPartOf = (
    discount: DiscountInfo,
    title: string,
    topDivider: boolean = false,
    monthPrice?: MonthPrice,
  ) => {
    return (
      <>
        <TableRow className={topDivider ? classes.rowWithTopLine : ''}>
          <TableCell component="th" scope="row" className={classes.groupTitle}>
            {title}
          </TableCell>
          <TableCell className={classes.groupPrice}>
            {monthPrice
              ? formatPrice(monthPrice.unitPriceIncludingTax, true)
              : '---'}{' '}
            円
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            component="th"
            scope="row"
            className={`${classes.itemTitle} ${
              discount.kind === 'fixed' ? classes.strikethrough : ''
            }`}
          >
            標準単価（税抜）
          </TableCell>
          <TableCell
            className={`${classes.itemPrice} ${
              discount.kind === 'fixed' ? classes.strikethrough : ''
            }`}
          >
            {monthPrice
              ? formatPrice(monthPrice.basePriceExcludingTax ?? 0, true)
              : '---'}{' '}
            円
          </TableCell>
        </TableRow>

        {discount.kind === 'fixed' && (
          <TableRow>
            <TableCell component="th" scope="row" className={classes.itemTitle}>
              特別単価（税抜）
            </TableCell>
            <TableCell className={classes.itemPrice}>
              {monthPrice
                ? formatPrice(
                    monthPrice.basePriceExcludingTax +
                      monthPrice.discount.coupon.amountExcludingTax,
                    true,
                  )
                : '---'}{' '}
              円
            </TableCell>
          </TableRow>
        )}

        {discount.kind === 'rate' && (
          <>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={classes.itemTitle}
              >
                {`割引額（${discount.rate / 10000}％・税抜）`}
              </TableCell>
              <TableCell className={classes.itemPrice}>
                {monthPrice
                  ? formatPrice(
                      -monthPrice.discount.coupon.amountExcludingTax,
                      true,
                    )
                  : '---'}{' '}
                円
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={classes.itemTitle}
              >
                割引後単価（税抜）
              </TableCell>
              <TableCell className={classes.itemPrice}>
                {monthPrice
                  ? formatPrice(
                      monthPrice.basePriceExcludingTax +
                        monthPrice.discount.coupon.amountExcludingTax,
                      true,
                    )
                  : '---'}{' '}
                円
              </TableCell>
            </TableRow>
          </>
        )}

        {monthPrice && (
          <>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={classes.itemTitle}
              >
                {`初月利用日数割引（${monthPrice.discount.remaining.target.days}日分）`}
              </TableCell>
              <TableCell className={classes.itemPrice}>
                {`${formatPrice(
                  monthPrice.discount?.remaining?.amountExcludingTax ?? 0,
                  true,
                )} 円`}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={classes.itemTitle}
              >
                割引後単価（税抜）
              </TableCell>
              <TableCell className={classes.itemPrice}>
                {formatPrice(monthPrice.unitPriceExcludingTax ?? 0, true)} 円
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                component="th"
                scope="row"
                className={classes.itemTitle}
              >
                税額
              </TableCell>
              <TableCell className={classes.itemPrice}>
                {formatPrice(
                  (monthPrice.unitPriceIncludingTax ?? 0) -
                    (monthPrice.unitPriceExcludingTax ?? 0),
                  true,
                )}{' '}
                円
              </TableCell>
            </TableRow>
          </>
        )}

        {plan?.contractKind !== 'constant' &&
          plan?.contractKind !== 'specified' && (
            <TableRow className={classes.licenseNumberRow}>
              <TableCell
                component="th"
                scope="row"
                className={classes.itemTitle}
              >
                {plan?.volumeLabel}
              </TableCell>
              <TableCell className={classes.itemPrice}>
                {monthPrice ? monthPrice.licenseNumber ?? 0 : '---'} 件
              </TableCell>
            </TableRow>
          )}
      </>
    );
  };

  return (
    <>
      <Table className={`${classes.priceTable} ${className || ''}`}>
        <TableBody>
          {monthlyPartOf(
            discountInfoUpdated,
            '月次請求単価',
            false,
            secondMonth,
          )}
          <TableRow className={classes.totalPriceRow}>
            <TableCell
              component="th"
              scope="row"
              className={classes.totalTitle}
            >
              {plan?.billKind === 'monthly' ? '月次請求総額' : '請求総額'}
            </TableCell>
            <TableCell className={classes.totalPrice}>
              {formatPrice(secondMonth.priceIncludingTax)} 円
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      {!hideFirstMonthPriceTable &&
        plan?.billingBehavior === 'daily-basis' &&
        // simple daily-basis for view, create and modify
        (firstMonthType === 'normal' ? (
          <Table className={`${classes.priceTable} ${className || ''}`}>
            <TableBody>
              {dailyBasisPartOf(
                discountInfoUpdated,
                '初月請求単価',
                false,
                firstMonth,
              )}
              <TableRow className={classes.totalPriceRow}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.totalTitle}
                >
                  初月請求総額
                </TableCell>
                <TableCell className={classes.totalPrice}>
                  {formatPrice(firstMonth.priceIncludingTax)} 円
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ) : (
          // daily-basis with difference support
          <Table className={`${classes.priceTable} ${className || ''}`}>
            <TableBody>
              {/* Updated Month Part */}
              {dailyBasisPartOf(
                discountInfoUpdated,
                '初月請求単価（更改分）',
                false,
                firstMonthUpdated,
              )}

              {/* Current Month Part */}
              {firstMonthType === 'volume-diff'
                ? monthlyPartOf(
                    discountInfoCurrent,
                    '月次請求単価（既存分）',
                    true,
                    firstMonthCurrent,
                  )
                : dailyBasisPartOf(
                    discountInfoCurrent,
                    '月次請求単価（既存分）',
                    true,
                    firstMonthCurrent,
                  )}
              {/* Total Part */}

              <TableRow className={classes.totalPriceRow}>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.totalTitle}
                >
                  初回請求額
                </TableCell>
                <TableCell className={classes.totalPrice}>
                  {/*{formatPrice(firstMonthUpdated.priceIncludingTax)} 円*/}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.itemTitle}
                >
                  更改分請求額（税込）
                </TableCell>
                <TableCell className={classes.itemPrice}>
                  {firstMonthUpdated
                    ? formatPrice(firstMonthUpdated.priceIncludingTax)
                    : '---'}{' '}
                  円
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.itemTitle}
                >
                  既存分請求額（税込）
                </TableCell>
                <TableCell className={classes.itemPrice}>
                  {formatPrice(firstMonthCurrent.priceIncludingTax)} 円
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  className={classes.itemTitleLarge}
                >
                  総請求額（税込）
                </TableCell>
                <TableCell className={classes.itemPriceLarge}>
                  {formatPrice(
                    (firstMonthUpdated?.priceIncludingTax ?? 0) +
                      firstMonthCurrent.priceIncludingTax,
                  )}{' '}
                  円
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ))}
    </>
  );
};
